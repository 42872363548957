import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ContentLoader from 'react-content-loader'

import { ErrorMessage, Formik } from "formik";
import { useLocation, useNavigate, useParams, NavLink } from "react-router-dom";
import moment from "moment";
import FormGenrateModal from "../../components/FormGenrateModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import loaderGif from "../../assets/img/loading.gif";

const SendResponse = () => {
  const [formData, setformData] = useState([]);
  const [message ,setMessgae]= useState()
  const [customerInquiry, setCustomerInquiry] = useState();
  const [bookingUrl, setBookingUrl] = useState();
  const [formGenrateModal, setFormGenrateModal] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [customerInquiryResponse, setCustomerInquiryResponse] = useState();
  const { id } = useParams();
  const { state } = useLocation();
  const userId = localStorage.getItem("userId");

  const navigate = useNavigate();

  const getInquiryDetail = async () => {
    try {
      let res = await UserService.getInquiry(id);
      if (res?.data?.success === true) {
        //toast.success(res?.data?.msg, { autoClose: 2000 });
        setCustomerInquiry(res?.data?.result?.data);
        setCustomerInquiryResponse(res?.data?.result?.equiryResponse);
        setLoader(false)
      } else {
        toast.error(res?.data?.msg, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    console.log(state, 455)
    getInquiryDetail();
    setLoader(true)
    getCutomerForm();
    state?.message ?
    setMessgae(`${state?.message}`) : setMessgae("")
  }, []);
  const handleSelectChange = async (e) => {
    if(e.target.value != ""){

      setMessgae(`${message} {${e.target.value}}`)
    }
   
}

const getCutomerForm = async () => {
  try {
    const res = await UserService.getCustomerForm(userId);
    if (res?.data?.status === 200) {
      // setCustomerForm(res?.data?.data?.list);
      let formArrr = [];
      for (let formData of res?.data?.data?.list) {
        let key = formData._id;
        let label = formData.label;
        formArrr.push({ id: key, value: label });
      }
      setformData(formArrr);
    } else {
      toast.error("Something went wrong", { autoClose: 2000 });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: 2000 });
    console.log("error", error);
  }
};
  const getDetail = (res, key) => {
    const foundObject = res?.find((item) => item?.label === key);
    if (foundObject) {
      return foundObject?.value;
    } else {
      return "-";
    }
  };
  function createMarkup(value) {
    return {__html: value};
  }
  const initialValues = {
    subject: state?.subject || "",
    message: state?.message || "",
    bookingLink: state?.booking_link ? state?.booking_link : false,
  };
  const validationSchema = Yup.object({
    // subject: Yup.string().required("Template name is required"),
    // message: Yup.string().required("Message is required"),
  });
  const onSubmit = async (values) => {
    console.log(message,"message++++++++=", 61)
    setSaveButton(true);
    const data = {
      subject: values?.subject || "",
      message: message,
      booking_link: values?.bookingLink ? values?.bookingLink : false,
    };
    try {
      let res = await UserService.updateInquiryStatus(id, data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setBookingUrl(res?.data?.data?.bookingUrl);
        setFormGenrateModal(true);
      } else {
        setSaveButton(false);
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      setSaveButton(false);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const openModal = () => {
    setFormGenrateModal(true);
  };

  const closeModal = () => {
    setFormGenrateModal(false);
    setTimeout(() => {
      navigate("/inquiries");
    }, 1000);
  };

  return (
    <>
      {formGenrateModal && (
        <FormGenrateModal
          open={openModal}
          close={closeModal}
          enquiryUrl={bookingUrl}
        />
      )}
       { loader ? <ContentLoader viewBox="0 0 380 70">
    {/* Only SVG shapes */}    
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  </ContentLoader>:
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <div className="space-sec mt-0">
              <div className="inner-title">
                <div className="back-wrap">
                  <NavLink to="/inquiries">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                        stroke="#2E1885"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Inquiries
                  </NavLink>
                </div>
              </div>
              <h3 className="breadcurm-title my-2">
                {getDetail(customerInquiry, "Full Name")}
              </h3>
              {customerInquiry?.map((item, index) =>
                item.label == "created_at" ? (
                  <>
                    <div className="client-info-main">
                      <div className="client-info">
                        {customerInquiryResponse?.length > 0 ? (
                          <>
                            <p>
                              Last replied{" "}
                              {moment(
                                customerInquiryResponse[
                                  customerInquiryResponse?.length - 1
                                ]?.created_at
                              ).format("MMMM DD, YYYY")}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              Received on{" "}
                              {moment(item?.value).format("MMMM DD, YYYY")}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )
              )}
              <div className="booking-dtl-cls space-sec">
                <div className="client-dtl">
                  <h4>Inquiry Response </h4>
                  <div className="card">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          {/* {state?.subject ? " ": <div className="form-group">
                            <label>Template Name</label>
                            <input
                              type="text"
                              name="subject"
                              onChange={handleChange}
                              value={values?.subject}
                              className="form-control"
                              autoComplete="off"
                            />
                            <p className="error-msg mt-2">
                              <ErrorMessage
                                className="error-msg"
                                name="subject"
                              />
                            </p>
                          </div>} */}
                          <div className="form-group">
                            <div className="message-label d-flex  justify-content-between">
                              <label>Message</label>
                              <select defaultValue="Insert Placeholder" style={{ width: 200 }} onChange={(e) => handleSelectChange(e)}>
                              <option value = "">Insert Placeholder</option>
                               {formData.map((inquiry) => 
                               <option value={inquiry.value}>{inquiry.value}</option>
                               )}
                              
                             </select> 
                              {/* <NavLink to="#">Insert Placeholder</NavLink> */}
                            </div>
                            <ReactQuill
                              name="message"
                              value={message}
                              onChange={(e) => setMessgae(e)}
                              style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            />{" "}
                            <p className="error-msg mt-2">
                              <ErrorMessage
                                className="error-msg"
                                name="message"
                              />
                            </p>
                          </div>
                          <div className="booking-link-main d-flex justify-content-between mt-50 mb-4">
                            <div className="form-check form-switch form-switch-sm">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="bookingLink"
                                id="flexSwitchCheckChecked1"
                                value={values.bookingLink}
                                checked={values.bookingLink === true}
                                onChange={handleChange}
                              />
                              <div className="booking-link px-4">
                                <h5>Include Booking Link</h5>
                              </div>
                            </div>
                          </div>

                          <div className="add-new">
                            <button
                              type="submit"
                              disabled={saveButton}
                              className="btn btn_primary"
                            >
                              Send Response{" "}
                              {saveButton ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="client-dtl mt-4">
                  <h4>Inquiry Details</h4>
                  <div className="card">
                    {customerInquiry?.map((item, index) =>
                      item.label == "created_at" ? (
                        <>
                          <div className="client-info-main">
                            <div className="client-info">
                              <h5>Created At</h5>
                              <p>
                                {moment(item?.value).format("MMMM DD, YYYY")}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : item.label == "Is Completed?" ? (
                        <></>
                      ) : (
                        <>
                          <div className="client-info-main">
                            <div className="client-info">
                              <h5>{item.label}</h5>
                              <p dangerouslySetInnerHTML={createMarkup(item.value)}/>
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default SendResponse;
