import React, { useEffect, useState } from "react";
import UserService from "../services/UserService";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";

const AddContractModal = ({ open, close }) => {
  const [image, setImage] = useState();

  const initialValues = {
    title: "",
    message: "",
    signature: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Template name is required"),
    message: Yup.string().required("Message is required"),
    signature: Yup.string().required("Signature is required"),
  });

  const handleImage = async (img) => {
    let formData = new FormData();
    formData.append("image", img);
    try {
      let res = await UserService.imageUpload(formData);
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.message, { autoClose: 2000 });
        setImage(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const onSubmit = async (values) => {
    const data = {
      title: values?.title,
      message: values?.message,
      signature: image,
    };
    try {
      let res = await UserService.addContract(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        close();
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
      <ToastContainer />

      <Modal
        show={open}
        onHide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 13L1 1M13 1L1 13"
                  stroke="#121212"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="contract_modal">
              <div className="booking-dtl-cls space-sec">
                <div className="client-dtl">
                  <h4>Add Contract Template</h4>
                  <div className="card">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Template Name</label>
                            <input
                              type="text"
                              name="title"
                              onChange={handleChange}
                              value={values?.title}
                              className="form-control"
                              autoComplete="off"
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="title"
                              />
                            </p>
                          </div>
                          <div className="form-group">
                            <div className="message-label d-flex  justify-content-between">
                              <label>Message</label>
                              {/* <NavLink to="#">Insert Placeholder</NavLink> */}
                            </div>
                            <ReactQuill
                              name="message"
                              value={values?.message}
                              onChange={(e) => setFieldValue("message", e)}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="message"
                              />
                            </p>
                          </div>
                          <div className="form-group">
                            <label>Signature</label>
                            <input
                              type="file"
                              id="signature"
                              name="signature"
                              onChange={(e) => {
                                handleChange(e);
                                let imgFile = e.target.files[0];
                                const fileRegex =
                                  /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
                                if (fileRegex?.test(imgFile?.name)) {
                                  setFieldValue(
                                    "signature",
                                    URL.createObjectURL(imgFile)
                                  );
                                  handleImage(imgFile);
                                } else {
                                  toast.error("Formate not supported!", {
                                    autoClose: 2000,
                                  });
                                }
                              }}
                              className="form-control"
                              placeholder="General with Booking Link"
                            />
                            <p className="error-msg mt-2">
                              <ErrorMessage
                                className="error-msg"
                                name="signature"
                              />
                            </p>
                          </div>
                          <div className="add-new">
                            <button
                              type="submit"
                              className="btn btn_primary w-100"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddContractModal;
