import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import ContentLoader from 'react-content-loader';
import { NavLink } from "react-router-dom";
import userIcon from "../../assets/img/user-ic.svg";
import dotIcon from "../../assets/img/dot.svg";
import jobNotFound from "../../assets/img/jobs_not_found.svg";

import { Button, Dropdown } from "react-bootstrap";
import AddTeam from "./AddTeam";
import EditTeam from "./EditTeam";

const Teams = () => {
  const [teamList, seTeamList] = useState();
  const [loader, setLoader] = useState(false);
  const [addTeam, setAddTeam] = useState(false);
  const [editTeam, setEditTeam] = useState(false);
  const [teamId, setTeamId] = useState();
  const [roles, setRoles] = useState([]);
  const [teamData, setTeamData] = useState();
  const userId = localStorage.getItem("userId");

  const getTeamList = async () => {
    try {
      let res = await UserService.getTeamList(userId);
      if (res?.data?.status === 200) {
        seTeamList(res?.data?.data?.list?.docs);
        setLoader(false)
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const getActiveRoles = async () => {
    try {
      let res = await UserService.getRoles();
      if (res?.data?.success === true) {
        setRoles(res?.data?.result);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const handelDeleteTeam = async (id) => {
    try {
      let res = await UserService.updateTeamStatus(id, "is_active");
      if (res?.data?.status === 200) {
        seTeamList(res?.data?.data?.list?.docs);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const openDeleteTeam = async (index, id) => {
    const confirmed = window.confirm("Do you want to delete?");
    if (confirmed) {
      try {
        let res = await UserService.deleteTeam(id);
        // list.splice(index, 1);
        if (res?.data?.status === 200) {
          getTeamList();
          toast.success(res?.data?.message, { autoClose: 2000 });
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };

  useEffect(() => {
    getTeamList();
    getActiveRoles();
    setLoader(true)
  }, [addTeam, editTeam]);

  const openAddTeam = () => {
    setAddTeam(true);
  };

  const closeAddTeam = () => {
    setAddTeam(false);
  };

  const openEditTeam = (id, list) => {
    setTeamId(id);
    setTeamData(list);
    setEditTeam(true);
  };

  const closeEditTeam = () => {
    setEditTeam(false);
  };

  return (
    <>
      {addTeam && (
        <AddTeam roles={roles} open={openAddTeam} close={closeAddTeam} />
      )}
      {editTeam && (
        <EditTeam
          open={openEditTeam}
          close={closeEditTeam}
          roles={roles}
          teamData={teamData}
        />
      )}
      {loader ? <ContentLoader/>:<div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Team</h3>
            <div className="space-sec mt-0">
              <div className="inner-title">
                <h4>Team Member</h4>
                <div className="add-new-wrap">
                  <button
                    className="btn btn_primary"
                    onClick={() => openAddTeam()}
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5001 4.44446C12.7064 4.44446 12.9042 4.5264 13.0501 4.67226C13.196 4.81813 13.2779 5.01596 13.2779 5.22224V12.2222H20.2779C20.4842 12.2222 20.682 12.3042 20.8279 12.45C20.9737 12.5959 21.0557 12.7937 21.0557 13C21.0557 13.2063 20.9737 13.4041 20.8279 13.55C20.682 13.6958 20.4842 13.7778 20.2779 13.7778H13.2779V20.7778C13.2779 20.9841 13.196 21.1819 13.0501 21.3278C12.9042 21.4736 12.7064 21.5556 12.5001 21.5556C12.2939 21.5556 12.096 21.4736 11.9502 21.3278C11.8043 21.1819 11.7224 20.9841 11.7224 20.7778V13.7778H4.72236C4.51608 13.7778 4.31825 13.6958 4.17239 13.55C4.02652 13.4041 3.94458 13.2063 3.94458 13C3.94458 12.7937 4.02652 12.5959 4.17239 12.45C4.31825 12.3042 4.51608 12.2222 4.72236 12.2222H11.7224V5.22224C11.7224 5.01596 11.8043 4.81813 11.9502 4.67226C12.096 4.5264 12.2939 4.44446 12.5001 4.44446Z"
                        fill="white"
                      />
                    </svg>{" "}
                    Add Team Member
                  </button>
                </div>
              </div>
              <div className="tab-inner-cont">
                <div className="team-wrap">
                  <div className="row">
                    {teamList?.length > 0
                      ? teamList?.map((list, index) => (
                          <div
                            className="col-md-4 col-lg-4 col-xxl-3"
                            key={index}
                          >
                            <div className="pro-card card team-card">
                              <NavLink to={"/teams/team-details/" + list?._id}>
                                <div className="user-img-wrap">
                                  <div className="user-img-bg">
                                    <img
                                      src={
                                        (list?.image !== null ||
                                          list?.image !== undefined ||
                                          list?.image !== " ") &&
                                        list?.image?.length > 3
                                          ? list?.image
                                          : userIcon
                                      }
                                      alt="User Image"
                                    />
                                  </div>
                                </div>
                                <div className="team-user-dtl">
                                  <div className="pro-tit">
                                    <h5>
                                      {list?.firstName} {list?.lastName}{" "}
                                    </h5>
                                  </div>
                                  <div className="pro-address">
                                    <p>{list?.email}</p>
                                  </div>
                                  <div className="pro-address">
                                    <p>Hourly Rate : ${list?.hourly_rate}</p>
                                  </div>
                                </div>
                              </NavLink>
                              <div className="user-dot">
                                <div className="notifi-dot">
                                  <Dropdown className="bookingDrop">
                                    <Dropdown.Toggle className="p-0 shadow-none">
                                      <img src={dotIcon} alt="Image" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        as={Button}
                                        onClick={() =>
                                          openEditTeam(list?._id, list)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as={Button}
                                        onClick={() =>
                                          openDeleteTeam(list, list?._id)
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : <>
                      <div className="not-found-img">
                        <img src={jobNotFound} />
                      </div>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Teams;
