import * as Yup from "yup";
import { toast } from "react-toastify";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import eyeOpen from "../../../assets/img/eye.svg";
import React, { useEffect, useState } from "react";
import { Modal, Button, Dropdown, Row, Col } from "react-bootstrap";

import UserService from "../../../services/UserService";
import eyeClose from "../../../assets/img/eye-slash.svg";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const ManageAccount = ({ user }) => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [isChangePassword, setisChangePassword] = useState(false);
  const [profileImage, setProfileImage] = useState(user?.companyLogo);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [subscriptions, setSubscription] = useState([]);
  const [psubscriptions, setpSubscription] = useState();
  const [subsModal, setSubsModal] = useState(false);
  const [selectedSubs, setSubs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [subsItem, setsubsItem] = useState();
  const userId = localStorage.getItem("userId");
  const [countryName, setCountryName] = useState(
    localStorage.getItem("country") ? localStorage.getItem("country") : ""
  );
  const [mobileNumber, setMobileNumber] = useState({
    mobileNo: '',
    country_code: '',
  });

  const getSubscription = async () => {
    try {
      const res = await UserService.getSubscription();
      if (res?.data?.status === 200) {
        console.log(res?.data?.data?.list);
        setSubscription(res?.data?.data?.list);
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const getPhotographerSubscription = async () => {
    try {
      const res = await UserService.getPhotographerSubscription();
      if (res?.data?.status === 201) {
        // console.log(res?.data?.data?.list);
        setpSubscription(res?.data?.data);
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const cancelPhotographerSubscription = async () => {
    try {
      console.log("djwhdjdhnadjad+++++++++++++++++");
      let data = {
        subscriptionId: psubscriptions?._id,
      };
      const res = await UserService.cancelPhotographerSubscription(data);
      if (res?.data?.status == 200) {
        // localStorage.removeItem("userToken");
        toast.error(res.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
 
  const updateSubscription = async (values) => {
    try {
      const data = {
        subscriptionId: psubscriptions?._id,
        subsId: values._id,
        amount: values.amount,
      };
      const res = await UserService.updateSubscription(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setSubsModal(false);
        setRefresh((prev) => !prev);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getSubscription();
    getPhotographerSubscription();
    setMobileNumber({ country_code: user?.countryCode, mobileNo: user?.mobileNo });

  }, [refresh]);

  const initialValues = {
    firstName: user ? user?.firstName : "",
    lastName: user ? user?.lastName : "",
    email: user ? user?.email : "",
    // phone: user ? user?.mobileNo : "",
    companyName: user ? user?.companyName : "",
    profilepic: user ? user?.companyLogo : "",
    link: user ? user?.companySite : "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required("First Name is required!"),
    lastName: Yup.string().max(255).required("Last Name is required!"),
    email: Yup.string().email().max(255).required("Email is required").trim(),
    // phone: Yup.string().required("Mobile No. is required!"),
    companyName: Yup.string().max(255).required("Company Name is required!"),
    profilepic: Yup.mixed()
      .test("fileFormat", "Unsupported Format", (value) => {
        if (typeof value === "string") {
          return true;
        } else {
          return !value || (value && SUPPORTED_FORMATS.includes(value.type));
        }
      })
      .test("fileSize", "File too large (max size 5MB)", (value) => {
        if (typeof value === "string" || typeof value === "undefined") {
          return true;
        } else {
          return value && value.size <= 5000000;
        }
      }),
    link: Yup.string().url("Invalid URL format"),
  });
  const initialValues_1 = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema_1 = Yup.object().shape({
    oldPassword: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Old password must contain at least 8 character. Only strong password allowed."
      )
      .required("Old password is required")
      .trim(),
    newPassword: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "New password must contain at least 8 character. Only strong password allowed."
      )
      .required("New password is required")
      .trim(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
      .required("Confirm password is required!"),
  });
  const handleImage = async (img) => {
    let formData = new FormData();
    formData.append("image", img);
    try {
      let res = await UserService.imageUpload(formData);
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.message, { autoClose: 2000 });
        setProfileImage(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const onSubmit = async (values) => {
    const data = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      mobileNo:   mobileNumber.mobileNo ,
      countryCode: mobileNumber.country_code,
      companyName: values?.companyName,
      companyLogo: profileImage,
      companySite: values?.link,
    };
    console.log(data);
    try {
      const res = await UserService.updatePhotographer(data);
      console.log("res", res?.data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  const onChangeSubmit = async (values, { resetForm }) => {
    const data = {
      old_password: values?.oldPassword,
      new_password: values?.newPassword,
    };
    try {
      const res = await UserService.updatePhotographerPassword(data);
      console.log("res", res?.data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        resetForm();
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  const handleNumber = (value, data, event, formattedValue) => {
    console.log(value,144, data, 133)
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobileNo: value.slice(data.dialCode.length) });
  }
  const confirmModal = async (value) => {
    setSubsModal(true);
    setSubs(value);
    // const sue = confirm("are you sure")
  };

  return (
    <>
      {subsModal && (
        <Modal className="subscription-conf" show={subsModal} onHide={() => setSubsModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Subscription Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to change the subscription package?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => updateSubscription(selectedSubs)}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="client-dtl setting-wrap">
            <h4>Manage Account</h4>
            <div className="card">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                          />
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="firstName"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                          />
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="lastName"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            type="text"
                            name="email"
                            placeholder="name@email.com"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            disabled={true}
                          />
                          <p className="error-msg">
                            <ErrorMessage className="error-msg" name="email" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <label>Mobile No.</label>
                          <ReactPhoneInput
                            className="form-control"
                            autoComplete={"off"}
                            enableSearch={true}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                            }}
                            isValid={(value, country) => {
                              if (value.match(/1234/)) {
                                return (
                                  "Invalid value: " +
                                  value +
                                  ", " +
                                  country.name
                                );
                              } else if (value.match(/1234/)) {
                                return (
                                  "Invalid value: " +
                                  value +
                                  ", " +
                                  country.name
                                );
                              } else {
                                return true;
                              }
                            }}
                            country={countryName ? countryName : "ca"}
                            value={mobileNumber ? ((mobileNumber.country_code ? mobileNumber.country_code : "+1") + (mobileNumber.mobileNo ? mobileNumber.mobileNo : '')) : ''}
                            onChange={handleNumber}
                            placeholder="Enter Mobile Number"
                          />
                          <p className="error-msg">
                            <ErrorMessage className="error-msg" name="phone" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyName}
                          />
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="companyName"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group uplaod-logo upload-setting-logo">
                          <label>Uplaod company logo</label>
                          <div className="file_input">
                            <input
                              type="file"
                              name="logo"
                              accept=".png, .jpg, .jpeg"
                              id="fileToUpload"
                              onChange={(e) => {
                                handleChange(e);
                                let imgFile = e.target.files[0];
                                const fileRegex =
                                  /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
                                if (fileRegex?.test(imgFile?.name)) {
                                  setFieldValue(
                                    "profilepic",
                                    URL.createObjectURL(imgFile)
                                  );
                                  handleImage(imgFile);
                                } else {
                                  toast.error("Formate not supported!", {
                                    autoClose: 2000,
                                  });
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.logo}
                            />
                            <span htmlFor="fileToUpload">Upload here </span>
                            <div className="user-img">
                              <img src={profileImage} />
                            </div>
                          </div>
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="profilepic"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="url">Company Website/Link</label>
                          <input
                            type="text"
                            name="link"
                            id="url"
                            placeholder="Company Website/Link"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.link}
                          />{" "}
                          <p className="error-msg">
                            <ErrorMessage className="error-msg" name="link" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="add-category">
                      <button
                        className="btn btn_primary"
                        type="submit"
                        // disabled={!isSubmitting}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="client-dtl setting-wrap">
            <h4>Change Password</h4>
            <div className="card">
              <Formik
                initialValues={initialValues_1}
                validationSchema={validationSchema_1}
                onSubmit={onChangeSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Old Password</label>
                          <input
                            type={showOldPass ? "text" : "password"}
                            name="oldPassword"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.oldPassword}
                            autoComplete="off"
                          />
                          <div className="pw-show">
                            <img
                              src={showOldPass ? eyeOpen : eyeClose}
                              onClick={() => setShowOldPass(!showOldPass)}
                            />
                          </div>
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="oldPassword"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>New Password</label>
                          <input
                            type={showNewPass ? "text" : "password"}
                            name="newPassword"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            autoComplete="off"
                          />
                          <div className="pw-show">
                            <img
                              src={showNewPass ? eyeOpen : eyeClose}
                              onClick={() => setShowNewPass(!showNewPass)}
                            />
                          </div>
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="newPassword"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Confirm Password</label>
                          <input
                            type={showPass ? "text" : "password"}
                            name="confirmPassword"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            autoComplete="off"
                          />
                          <div className="pw-show">
                            <img
                              src={showPass ? eyeOpen : eyeClose}
                              onClick={() => setShowPass(!showPass)}
                            />
                          </div>
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="confirmPassword"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="add-category">
                      <button
                        className="btn btn_primary"
                        type="submit"
                        disabled={isChangePassword}
                      >
                        Update Password
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="client-dtl setting-wrap">
            <h4>Manage Subscription</h4>
            <div className="card">
              <Row>
                <Col lg="3">
                  <div className="select-plan-opt">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Subscription Plan
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {subscriptions.map((subs) => (
                          <Dropdown.Item
                            key={subs._id}
                            onClick={() => setsubsItem(subs)}
                          >
                            {subs.subscriptionName}
                            <p>{subs.amount}</p>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="select-subs">
                      {subscriptions && subscriptions.map((subs) => {
                        if (subsItem) {
                          return subs._id === subsItem._id ? (
                            <div key={subs._id}>
                              <div className="subs-price d-flex">
                                <p>{subs.subscriptionName}</p>
                                <p>{subs.amount}</p>
                              </div>
                              <div className="plan-desc">
                                <p>{subs.description}</p>
                                </div>   
                            </div>
                          ) : null;
                        } else if (subs._id === psubscriptions?.subscription_id) {
                          return (
                            <div key={subs._id}>
                              <div className="subs-price d-flex">
                              <p>{subs.subscriptionName}</p>
                              <p>{subs.amount}</p>
                              </div>
                              <div className="plan-desc">
                                <p>{subs.description}</p>
                              </div>
                            </div>
                          );
                        }
                        return null; // Return null for items that should not be rendered
                      })}
                  </div>
                </Col>
                <Col lg="3">
                  <div className="plan-opt">
                    <Button className="btn_primary"  onClick={() =>confirmModal(subsItem)}>Update</Button>
                    <p
                      className="cancel-opt"
                      type="submit"
                      onClick={() => cancelPhotographerSubscription()}
                    >
                      Cancel Subscription
                    </p>
                  </div>
                </Col>
                </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageAccount;
