import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import loaderGif from "../../../assets/img/loading.gif";

const AddRole = ({ open, close }) => {
  const [saveButton, setSaveButton] = useState(false);

  const initialValues = {
    role_name: "",
  };

  const validationSchema = Yup.object().shape({
    role_name: Yup.string().max(255).required("Role is required!"),
  });

  const onSubmit = async (values) => {
    setSaveButton(true);
    const data = {
      role_name: values?.role_name,
    };
    console.log(data);
    try {
      const res = await UserService.addJobRole(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          close();
        }, 400);
      } else {
        setSaveButton(false);
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      setSaveButton(false);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
     
      <Modal
        show={open}
        hide={close}
        className="modal fade customModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close mt-1 me-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <div className="contract_list">
              <h4>Add Job Role</h4>
              <div className="checklist-wrap">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form
                      className="add-team-member-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="add-form">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Job Role</label>
                              <input
                                type="text"
                                name="role_name"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.role_name}
                                autoComplete="off"
                              />
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="role_name"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="add-new">
                            <button
                              type="submit"
                              className="btn btn_primary w-100"
                              disabled={saveButton}
                            >
                              Add{" "}
                              {saveButton ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddRole;
