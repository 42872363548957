import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/img/logo-white.png";
import ThankYou from "../../assets/img/launch.svg";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";

const PaymentThanks = () => {
  const { photographerId, subscriptionId } = useParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const type = searchParams.get('type');
  const navigate = useNavigate();

  const updatePaymentStatus = async (values) => {
    const data = {
      subscriptionId: subscriptionId,
    };
    try {
      const res = await UserService.updatePhotographerSubscription(data);
      if (res?.data?.status === 201) {
        if(type =="web"){
          setTimeout(() => {
            navigate("/login")
          }, 5000);
        }
     
        //toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  useEffect(() => {
    updatePaymentStatus();
    // editSubscription();
  }, []);
  return (
    <>
      <ToastContainer position="top-right" />
      <div className="login-sec customer-form-main">
        <div className="container p-0">
          <div className="booking-form-inner">
            <div className="logo-wrap">
              <img src={LogoImg} alt="flomatic-logo" />
            </div>
            <div className="booking-form-card">
              <div className="thank-you">
                <div className="thankyou-img">
                  <img src={ThankYou} alt="" />
                </div>
                <div className="thankyou-cont">
                  <h3>Your registration successfully done !</h3>
                  {/* <NavLink to={"/login"} className="btn_primary">
                    Login
                  </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentThanks;
