import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { ErrorMessage, Formik } from "formik";
import { Button, FormControl, Modal, ToastContainer } from "react-bootstrap";
import UserService from "../../../services/UserService";
import loaderGif from "../../../assets/img/loading.gif";

const EditPackage = ({
  open,
  close,
  bookingId,
  packageData,
  addOns,
  pkgBkgDtl,
}) => {
  let additional_price = 0;
  const [addOnData, setAddOnData] = useState();
  const [addOnArr, setAddOnArr] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [hour, setHour] = useState(parseInt(pkgBkgDtl?.add_hours));
  const [addOnPrice, setAddOnPrice] = useState([]);
  const [selectPackage, setSelectPackage] = useState(
    addOns?.map((item, index) => {
      additional_price += parseFloat(item.item_price);
      const updatedArray = [...addOnArr, parseFloat(item.item_price)];
    })
  );

  const [total, setTotal] = useState({
    package_price: packageData ? packageData?.package_price : 0,
    additional_price: additional_price,
    extra_hour: 0,
    final_total: 0,
  });

  const userId = localStorage.getItem("userId");

  let initialValues = {
    packageId: packageData ? packageData?._id : "",
    package_price: packageData ? packageData?.package_price : "",
    selectedItems: addOns ? addOns : [],
    hours: pkgBkgDtl ? pkgBkgDtl?.add_hours : 0,
    rate: pkgBkgDtl ? pkgBkgDtl?.hour_rate : 0,
  };

  const validationSchema = Yup.object().shape({
    packageId: Yup.string().required("Please select package!"),
  });

  const getPackage = async () => {
    try {
      let res = await UserService.getPackageList(userId);
      if (res?.data?.success === true) {
        setSelectPackage(res?.data?.result);
      } else {
        toast?.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const getAddOnList = async () => {
    try {
      let res = await UserService.getAddOnList(userId);
      if (res?.data?.success === true) {
        setAddOnData(res?.data?.result);
      } else {
        toast?.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const onSubmit = async (values) => {
    setButtonDisabled(true)
    console.log(values, 78888);
    let arr = [];
    let charges =
      parseInt(total?.package_price) +
      parseInt(values?.rate * hour) +
      parseInt(total?.additional_price);
    values.selectedItems.map((v) => {
      arr.push(v._id);
    });
    const data = {
      package_id: values.packageId,
      totalCharges: charges.toString(),
      item_id: arr,
      additionalHours: `${hour}`,
    };

    try {
      const res = await UserService.updateBooking(bookingId, data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          close();
          setButtonDisabled(false)

        }, 400);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)

      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
      setButtonDisabled(false)

    }
  };

  useEffect(() => {
    getPackage();
    getAddOnList();
    handleAddon();
  }, []);

  const handleChange_1 = async (e) => {
    const index = selectPackage.findIndex(
      (item) => item["_id"] === e.target.value
    );
    setTotal({ ...total, package_price: selectPackage[index].package_price });
  };

  let totalAddOns = addOnArr?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const handleAddon = async (checked) => {
    let totalPrice;
    if (checked) {
      totalPrice = addOnArr?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      console.log("totalAddOns ifif", totalAddOns);
    } else {
      totalPrice = addOns.reduce(
        (sum, item) => sum + parseInt(item.item_price),
        0
      );
      console.log("totalAddOns elseelse", totalAddOns);
    }
    setAddOnPrice(totalPrice);
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Edit Package Details</h4>
            <div className="checklist-wrap">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form
                    className="add-team-member-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="add-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Select Package</label>
                            <select
                              className="form-control"
                              name="packageId"
                              id="packageId"
                              onChange={(e) => {
                                handleChange_1(e);
                                handleChange(e);
                              }}
                              value={values?.packageId}
                              onBlur={handleBlur}
                            >
                              <option value="">Select an option</option>
                              {selectPackage?.map((item, idx) => (
                                <option value={item?._id} key={idx}>
                                  {item?.package_name}
                                </option>
                              ))}
                            </select>
                            <p className="error-msg">
                              <ErrorMessage
                                name="option"
                                className="error-msg"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group text-end">
                            <label>Package Price</label>
                            <span className="price-bold">
                              ${total.package_price}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <h4 className="mb-2 text-xxl-start">
                              Select Add Ons
                            </h4>
                            {addOnData?.map((addon, index) => (
                              <div key={index}>
                                <label htmlFor={`item${index}`}>
                                  {addon.item_name}
                                </label>
                                <div className="d-flex align-items-center gap-2 mb-2">
                                  <input
                                    type="checkbox"
                                    id={`item${index}`}
                                    name="selectedItems"
                                    value={addon.item_name}
                                    checked={values?.selectedItems.some(
                                      (item) =>
                                        item.item_price === addon.item_price
                                    )}
                                    onChange={(e) => {
                                      handleChange(e);
                                      const isChecked = e.target.checked;
                                      const { selectedItems } = values;
                                      if (isChecked) {
                                        setFieldValue("selectedItems", [
                                          ...selectedItems,
                                          addon,
                                        ]);
                                        let priceTotal = parseFloat(
                                          addon?.item_price
                                        );
                                        if (selectedItems?.length > 0) {
                                          for (let sumAdd of selectedItems) {
                                            priceTotal += parseFloat(
                                              sumAdd.item_price
                                            );
                                          }
                                        }
                                        setTotal({
                                          ...total,
                                          additional_price: priceTotal,
                                        });
                                      } else {
                                        const updatedItems =
                                          selectedItems.filter(
                                            (item) =>
                                              item.item_name !== addon.item_name
                                          );
                                        let priceTotal = 0;
                                        if (updatedItems?.length > 0) {
                                          for (let sumAdd of updatedItems) {
                                            priceTotal += parseFloat(
                                              sumAdd.item_price
                                            );
                                          }
                                        } else {
                                          priceTotal = 0;
                                        }
                                        setTotal({
                                          ...total,
                                          additional_price: priceTotal,
                                        });
                                        setFieldValue(
                                          "selectedItems",
                                          updatedItems
                                        );
                                      }
                                    }}
                                  />
                                  <h4 className="m-0">${addon?.item_price}</h4>
                                </div>
                              </div>
                            ))}
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="add_on"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group text-end">
                            <label>Addon's Price</label>
                            <span className="price-bold">
                              ${total?.additional_price}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-1">
                            <label className="mb-2">
                              Add Hours <span>(${values?.rate}/ hour)</span>
                            </label>
                            <div className="d-flex align-items-center justify-content-start gap-4">
                              <div className="add-hours-main">
                                <div className="minus">
                                  <Button
                                    onClick={() =>
                                      setHour(hour > 1 ? hour - 1 : 0)
                                    }
                                  >
                                    -
                                  </Button>
                                </div>
                                <div className="input-form">
                                  <FormControl
                                    className="incDecInp px-0 text-center h-100"
                                    type="number"
                                    min={0}
                                    max={15}
                                    placeholder="1"
                                    value={hour}
                                  ></FormControl>
                                </div>
                                <div className="plus">
                                  <Button onClick={() => setHour(hour + 1)}>
                                    +
                                  </Button>
                                </div>
                              </div>
                              <h4 className="m-0">${values?.rate * hour}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group total-price">
                            <label>Total Charage</label>
                            <span>
                              $
                              {parseInt(total?.package_price) +
                                parseInt(total?.additional_price) +
                                parseInt(values?.rate * hour)}
                            </span>
                          </div>
                        </div>
                        <div className="add-new">
                          <button
                            type="submit"
                            disabled={isButtonDisabled}
                            className="btn btn_primary w-100"
                          >
                            Submit{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPackage;
