import React, {useState} from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../../services/UserService";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import loaderGif from "../../../assets/img/loading.gif";


const EditAddOn = ({ open, close, addOnData }) => {
  const initialValues = {
    item_name: addOnData ? addOnData?.item_name : "",
    item_price: addOnData ? addOnData?.item_price : "",
  };
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const validationSchema = Yup.object().shape({
    item_name: Yup.string().required("Item Name is required"),
    item_price: Yup.number().required("Item Price is required"),
  });

  const onSubmit = async (values) => {
    setButtonDisabled(true)

    const data = {
      item_name: values?.item_name,
      item_price: values?.item_price.toString(),
    };
    try {
      let res = await UserService.editAddOn(addOnData?._id, data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        close();
        setButtonDisabled(false)

      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)

      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      setButtonDisabled(false)

    }
  };

  console.log("addOnData", addOnData);

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal CustomModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Edit AddOns</h4>
            <div className="checklist-wrap">
              <div className="add-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <form
                      className="add-team-member-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Item Name</label>
                            <input
                              type="text"
                              name="item_name"
                              className="form-control"
                              value={values?.item_name}
                              onChange={handleChange}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error_msg"
                                name="item_name"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Item Price</label>
                            <input
                              type="number"
                              name="item_price"
                              className="form-control"
                              value={values?.item_price}
                              onChange={handleChange}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error_msg"
                                name="item_price"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="add-new">
                            <button
                              type="submit"
                              disabled={isButtonDisabled}
                              className="btn btn_primary w-100"
                            >
                              Submit{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditAddOn;
