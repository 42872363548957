import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddPackage from "./AddPackage";
import AddAddOn from "./AddAddOn";
import { Button, Dropdown } from "react-bootstrap";
import EditPackage from "./EditPackage";
import EditAddOn from "./EditAddOn";

const View = ({ active }) => {
  const [packageList, sePackagetList] = useState();
  const [packageId, setPackageId] = useState();
  const [addOnData, setAddOnData] = useState();
  const [packageData, setPackageData] = useState();
  const [addOnList, seAddOnList] = useState();
  const [openPackage, setOpenPackage] = useState(false);
  const [editPackage, setEditPackage] = useState(false);
  const [openAddOn, setOpenAddon] = useState(false);
  const [editAddOn, setEditAddOn] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const userId = localStorage.getItem("userId");

  const getPackageList = async () => {
    try {
      let res = await UserService.getPackageList(userId);
      if (res?.data?.success === true) {
        sePackagetList(res?.data?.result);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const getAddOnList = async () => {
    try {
      let res = await UserService.getAddOnList(userId);
      if (res?.data?.success === true) {
        seAddOnList(res?.data?.result);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const handleAddOnStatus = async (addOnId) => {
    try {
      let res = await UserService.changeAddOnStatus(addOnId, "is_active");
      if (res?.data?.status === 200) {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setIsActive(!isActive);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getPackageList();
    getAddOnList();
  }, [editPackage, openPackage, openAddOn, editAddOn]);

  //   console.log("list", packageList);
  console.log("list", addOnList);

  const showAddOn = () => {
    setOpenAddon(true);
  };

  const hideAddOn = () => {
    setOpenAddon(false);
  };

  const showEditAddOn = (addOn) => {
    setAddOnData(addOn);
    setEditAddOn(true);
  };

  const hideEditAddOn = () => {
    setEditAddOn(false);
    setTimeout(() => {
      getAddOnList();
    }, 100);
  };

  const showPackage = () => {
    setOpenPackage(true);
  };

  const hidePackage = () => {
    setOpenPackage(false);
  };

  const showEditPackage = (list) => {
    setPackageData(list);
    setEditPackage(true);
  };

  const hideEditPackage = () => {
    setEditPackage(false);
  };

  console.log("isActive", active);

  return (
    <>
 

      {openPackage && <AddPackage open={showPackage} close={hidePackage} />}
      {editPackage && (
        <EditPackage
          open={showEditPackage}
          close={hideEditPackage}
          packageData={packageData}
        />
      )}
      {openAddOn && <AddAddOn open={showAddOn} close={hideAddOn} />}
      {editAddOn && (
        <EditAddOn
          open={showEditAddOn}
          close={hideEditAddOn}
          addOnData={addOnData}
        />
      )}

      <div className="booking-dtl-cls space-sec">
        <div className="inner-title">
          <div className="add-new-wrap generate-contract">
            <button
              className="btn btn_primary"
              onClick={() => (active === true ? showPackage() : showAddOn())}
            >
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5001 4.44446C12.7064 4.44446 12.9042 4.5264 13.0501 4.67226C13.196 4.81813 13.2779 5.01596 13.2779 5.22224V12.2222H20.2779C20.4842 12.2222 20.682 12.3042 20.8279 12.45C20.9737 12.5959 21.0557 12.7937 21.0557 13C21.0557 13.2063 20.9737 13.4041 20.8279 13.55C20.682 13.6958 20.4842 13.7778 20.2779 13.7778H13.2779V20.7778C13.2779 20.9841 13.196 21.1819 13.0501 21.3278C12.9042 21.4736 12.7064 21.5556 12.5001 21.5556C12.2939 21.5556 12.096 21.4736 11.9502 21.3278C11.8043 21.1819 11.7224 20.9841 11.7224 20.7778V13.7778H4.72236C4.51608 13.7778 4.31825 13.6958 4.17239 13.55C4.02652 13.4041 3.94458 13.2063 3.94458 13C3.94458 12.7937 4.02652 12.5959 4.17239 12.45C4.31825 12.3042 4.51608 12.2222 4.72236 12.2222H11.7224V5.22224C11.7224 5.01596 11.8043 4.81813 11.9502 4.67226C12.096 4.5264 12.2939 4.44446 12.5001 4.44446Z"
                  fill="white"
                />
              </svg>
              {active === true ? "Add Package" : "Add Addon"}
            </button>
          </div>
        </div>
      </div>

      <div className="tab-inner-cont space-sec">
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade active show"
            id="nav-packages"
            role="tabpanel"
            aria-labelledby="nav-packages-tab"
          >
            <div className="row">
              {packageList?.length > 0
                ? packageList?.map((list) => (
                    <div className="col-md-6 col-lg-4 col-xxl-4">
                      <div className="pro-card card inboxCustomCard px-0">
                        <div className="pro-tit px-12px">
                          <h5>{list?.package_name}</h5>
                          <div className="notifi-dot p-0">
                            <Dropdown className="bookingDrop">
                              <Dropdown.Toggle className="p-0 shadow-none">
                                <span>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                                      stroke="#0F172A"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                                      stroke="#0F172A"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                                      stroke="#0F172A"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  as={Button}
                                  onClick={() => showEditPackage(list)}
                                >
                                  Edit
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="card p-0 border-0 shadow-none m-0 inboxCustomCard px-12px">
                          <p
                            dangerouslySetInnerHTML={{ __html: list?.details }}
                          ></p>
                        </div>
                        <div className="event-tag px-12px">
                          <span>{list?.package_type?.category_name}</span>
                          <span>${list?.package_price}</span>
                          {list?.total_hours > 0 ? (
                            <>
                              <span>{list?.total_hours} hr</span>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-addons"
            role="tabpanel"
            aria-labelledby="nav-addons-tab"
          >
            <div className="row">
              {addOnList?.length > 0
                ? addOnList?.map((list) => (
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <div className="pro-card card">
                        <NavLink to="#">
                          <div className="pro-tit">
                            <h5>{list?.item_name}</h5>
                            <div className=" d-flex align-items-center gap-2">
                              <div className="notifi-dot p-0">
                                <Dropdown className="bookingDrop">
                                  <Dropdown.Toggle className="p-0 shadow-none">
                                    <span>
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                                          stroke="#0F172A"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                                          stroke="#0F172A"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                                          stroke="#0F172A"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      as={Button}
                                      onClick={() => showEditAddOn(list)}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div
                              className="form-check form-switch form-switch-sm d-none"
                              htmlFor="autoResponder"
                            >
                              <input
                                id="autoResponder"
                                name="autoResponder"
                                className="form-check-input"
                                type="checkbox"
                                value={isActive}
                                checked={list?.is_status === true}
                                onChange={() => handleAddOnStatus(list?._id)}
                              />
                            </div>
                          </div>
                          <div className="event-tag">
                            <span>${list?.item_price}</span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
