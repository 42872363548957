import React, { useEffect, useState } from "react";
import Inquiries from "./Inquiries";
import BookingList from "./BookingList";
import ContentLoader from 'react-content-loader'
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Inbox = () => {
  const [bookingList, setBookingList] = useState();
  const { state } = useLocation();
  const [user, setUser] = useState();
      const [loader, setLoader] = useState(false);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const getBookingData = async () => {
    try {
      let res = await UserService.getBookingList();
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.message, { autoClose: 2000 });
        setBookingList(res?.data?.data?.list?.docs);
                        setLoader(false)

      } 
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const getPhotographer = async () => {
    try {
      const res = await UserService.getPhotographer(userId);
      if (res?.data?.success === true) {
        setUser(res?.data?.result);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      if (error?.response?.data?.status === 500) {
        toast.info("Logged Out", { autoClose: 1000 });
        localStorage.removeItem("userToken");
        setTimeout(() => {
          navigate("/login");
        }, 800);
      }
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  useEffect(() => {
    getBookingData();
    getPhotographer();
    setLoader(true)
  }, []);
  return (
    <>
     {loader ? <ContentLoader/> :<div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title mb-1">Bookings</h3>
            <div className="text-end mb-3">
              <NavLink className={"btn btn_primary"} to="/bookings/add-booking">
                Add Booking
              </NavLink>
            </div>
            <div className="space-sec mt-0">
              <div className="tab-inner-cont">
                {user?.booking_url ? (
                  <>
                    <p>
                      You can view your public booking form here:{" "}
                      <a href={user?.booking_url} target="_blank">
                        View Booking Form
                      </a>
                    </p>
                  </>
                ) : null}
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className={"tab-pane fade active show"}
                    id="nav-bookings"
                    role="tabpanel"
                    aria-labelledby="nav-bookings-tab"
                  >
                   {loader? <ContentLoader/> : <BookingList data={bookingList} />}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Inbox;
