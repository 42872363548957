import React from "react";
import { Modal } from "react-bootstrap";
import UserService from "../services/UserService";
import { toast } from "react-toastify";

const ConfirmModal = ({ open, close, id, type }) => {
  const deleteNotifications = async () => {
    if (type === "One") {
      try {
        let res = await UserService.deleteOneNotification(id);
        if (res?.data?.status === 200) {
          toast?.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            close();
          }, 400);
        } else {
          toast?.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        toast?.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    } else {
      try {
        let res = await UserService.deleteAllNotifications();
        if (res?.data?.status === 200) {
          toast?.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            close();
          }, 400);
        } else {
          toast?.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        toast?.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };

  return (
    <Modal
      show={open}
      close={close}
      className="modal notificationModal fade customModal"
      id="viewNotification"
      tabindex="-1"
      aria-labelledby="viewNotificationLabel"
      aria-hidden="true"
    >
      <div className="modal-header position-relative start-0 top-0 border-bottom p-3">
        <div className="modal-title" id="exampleModalLabel">
          Delete Notification
        </div>
        <button
          type="button"
          className="btn-close border-0 shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={close}
        ></button>
      </div>
      <div className="modal-body">
        <div className="notificationItem p-0">
          <div className="row mx-0">
            <div className="col px-0 ps-2">
              <div className="title">Are you Sure?</div>
            </div>
            <div className="col-12 px-0 mt-2 pt-2">
              <div className="d-flex align-items-center justify-content-between">
                <button className="btn btn_primary" onClick={close}>
                  Cancel
                </button>
                <button
                  className="btn btn_primary"
                  onClick={() => deleteNotifications()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
