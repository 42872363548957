import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const Public = ({ children }) => {
  const location = useLocation();
  const userToken = localStorage.getItem("userToken");

  if (!userToken) {
    return children;
  }

  return <Navigate to="/" state={{ from: location }} replace />;
};

export default Public;
