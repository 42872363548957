import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import LogoImg from "../../../assets/img/logo.png";
import EditIcon from "../../../assets/img/edit.svg";
import DeleteIcon from "../../../assets/img/deleteIcon.svg";
import UserImg from "../../../assets/img/team-img.png";
import ArrowUp from "../../../assets/img/arrow-up.svg";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../../services/UserService";
import DatePicker from "react-datepicker";
import loaderGif from "../../../assets/img/loading.gif";
import "react-datepicker/dist/react-datepicker.css";
import {
  Col,
  FormControl,
  Row,
  FormGroup,
  Form,
  FormLabel,
  Button,
  Card,
} from "react-bootstrap";
import moment from "moment";

function Step1({ onNext, formData, setFormData, errors }) {
  const {
    first_name,
    last_name,
    mobileNumber,
    email,
    address,
    postalCode,
    city,
    state,
    country,
  } = formData;

  const handleNext = () => {
    setFormData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    if (first_name.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          first_name: "Please enter your first name",
        },
      }));
    } else if (last_name.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          last_name: "Please enter your last name",
        },
      }));
    } else if (!/^[0-9]{8,13}$/.test(formData.mobileNumber)) {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          mobileNumber: "Please enter valid mobile number",
        },
      }));
    } else if (
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)
    ) {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          email: "Please enter valid email address",
        },
      }));
    } else if (address.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          address: "Please enter your address",
        },
      }));
    } else if (city.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          city: "Please enter your city",
        },
      }));
    } else if (postalCode.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          postalCode: "Please enter your postal Code",
        },
      }));
    } else if (state.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          state: "Please enter your state",
        },
      }));
    } else if (country.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          country: "Please enter your country",
        },
      }));
    } else {
      onNext();
    }
  };

  return (
    <>
      <div className="stepsOuter">
        <div className="stepsMain position-relative"></div>
        <Form className="booking-form">
          <Row>
            <Col md="6">
              <FormGroup className="form-group">
                <FormLabel>
                  First Name <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  placeholder=""
                  value={first_name}
                  onChange={(e) =>
                    setFormData({ ...formData, first_name: e.target.value })
                  }
                  name="first_name"
                ></FormControl>
                {errors.first_name && (
                  <p className="text-danger">{errors.first_name}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="form-group">
                <FormLabel>
                  Last Name <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  placeholder=""
                  value={last_name}
                  onChange={(e) =>
                    setFormData({ ...formData, last_name: e.target.value })
                  }
                  name="last_name"
                ></FormControl>
                {errors.last_name && (
                  <p className="text-danger">{errors.last_name}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Mobile No. <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  type="number"
                  value={mobileNumber}
                  name="mobileNumber"
                  onChange={(e) =>
                    setFormData({ ...formData, mobileNumber: e.target.value })
                  }
                  minLength={8}
                  max={9999999999999}
                ></FormControl>
                {errors.mobileNumber && (
                  <p className="text-danger">{errors.mobileNumber}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Email <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={email}
                  name="email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                ></FormControl>
                {errors.email && <p className="text-danger">{errors.email}</p>}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Address <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={address}
                  name="address"
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                ></FormControl>{" "}
                {errors.address && (
                  <p className="text-danger">{errors.address}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  City <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={city}
                  name="city"
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                ></FormControl>{" "}
                {errors.city && <p className="text-danger">{errors.city}</p>}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  State/Province <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={state}
                  name="state"
                  onChange={(e) =>
                    setFormData({ ...formData, state: e.target.value })
                  }
                ></FormControl>
                {errors.state && <p className="text-danger">{errors.state}</p>}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Country <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={country}
                  name="country"
                  onChange={(e) =>
                    setFormData({ ...formData, country: e.target.value })
                  }
                ></FormControl>
                {errors.country && (
                  <p className="text-danger">{errors.country}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Zip/Postal Code <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  type="text"
                  value={postalCode}
                  name="postalCode"
                  onChange={(e) =>
                    {const inputValue = e.target.value;
                    // if (/^\d+$/.test(inputValue)) {
                      // If it's a valid number, update the state
                      setFormData({ ...formData, postalCode: inputValue });
                    // }     
                      }         }
                ></FormControl>
                {errors.postalCode && (
                  <p className="text-danger">{errors.postalCode}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <div className="step-from-btn">
                <Button className="btn btnSubmit w-100" onClick={handleNext}>
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
function Step2({
  onPrevious,
  onNext,
  optionList,
  packageList,
  formData,
  setFormData,
  errors,
  categoryList,
  filteredPackages,
  setFilteredPackages,
}) {
  const {
    package_id,
    package_value,
    package_requested,
    additionalItems,
    enable_packages,
    extra_hours,
    hours_rate,
    event_type,
    totalCharges,
  } = formData;
  // Step 2 fields and validation logic
  const handleNext = () => {
    setFormData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    if (event_type.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_type: "Please select any service type",
        },
      }));
    } else if (formData.enable_packages == true && package_id.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          package_id: "Please select a package",
        },
      }));
    }else if (
      formData.enable_packages == false &&
      package_requested.trim() == ""
    ) {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          package_requested: "Please enter package details",
        },
      }));
    } else {
      onNext();
    }
  };
  const handlePrevious = () => {
    onPrevious();
  };
  const handleItemClick = (packageId, packagePrice) => {
    setFormData({
      ...formData,
      package_id: packageId,
      package_value: packagePrice,
    });
  };
  const getClassName = (packageId) => {
    if (packageId == formData.package_id) {
      return "packageItem  selected mb-3 rounded-4 bg-white d-flex flex-column flex-nowrap pb-sm-4 pb-3";
    } else {
      return "packageItem mb-3 rounded-4 bg-white d-flex flex-column flex-nowrap pb-sm-4 pb-3";
    }
  };
  const setAdditional = async (e, itemId, itemPrice) => {
    let additionalItemsArr = additionalItems;
    if (e.target.checked) {
      additionalItemsArr.push({ label: itemId, value: itemPrice });
    } else {
      const index = additionalItemsArr.findIndex(
        (item) => item.label === itemId
      );
      additionalItemsArr.splice(index, 1);
    }
    setFormData({ ...formData, additionalItems: additionalItemsArr });
    await calculateTotalCharges();
  };
  const setHours = (type) => {
    let newHours = extra_hours;
    if (type == "minus") {
      newHours = extra_hours - 1;
      if (newHours <= 0) {
        newHours = 0;
      }
    } else {
      newHours = extra_hours + 1;
      if (newHours >= 15) {
        newHours = 15;
      }
    }
    setFormData({ ...formData, extra_hours: newHours });
  };
  const calculateTotalCharges = () => {
    let total = 0;
    if (package_id) {
      total = parseFloat(package_value.replace("$", ""));
    }
    for (let addi of additionalItems) {
      total += parseFloat(addi.value.replace("$", ""));
    }
    if (extra_hours > 0) {
      total += parseFloat(hours_rate) * parseFloat(extra_hours);
    }
    setFormData({ ...formData, totalCharges: total });
  };

  const filterPackages = (ID) => {
    const filteredPackages = packageList?.filter((item) => {
      return item?.package_type?._id === ID;
    });
    setFilteredPackages(filteredPackages);
  };
  useEffect(() => {
    calculateTotalCharges();
  }, [package_id, extra_hours]);
  return (
    <>
      <div className="stepsOuter step_2">
        <div className="formSubTitle text-center">Select Package</div>
        <div className="stepsMain position-relative"></div>
        <Col md="12">
          <FormGroup className="form-group">
            <FormLabel>Service Type</FormLabel>
            <select
              value={event_type}
              name="event_type"
              onChange={(e) => {
                filterPackages(e.target.value);
                setFormData({ ...formData, event_type: e.target.value });
              }}
              className="form-control"
            >
              <option value={""}>Select Type</option>
              {categoryList.length > 0
                ? categoryList.map((item, index) => {
                    return (
                      <>
                        <option key={index} value={item._id}>
                          {item.category_name}
                        </option>
                      </>
                    );
                  })
                : null}
            </select>
            {errors.event_type && (
              <p className="text-danger">{errors.event_type}</p>
            )}
          </FormGroup>
        </Col>
        {formData.enable_packages == false ? (
          <>
          {console.log(formData.enable_packages, "wdjndjad")}
            <div className="additional-option">
              <Col md="12">
                <FormGroup className="form-group">
                  <FormLabel>
                    Package Requested <span className="text-danger">*</span>
                  </FormLabel>
                  <FormControl
                    as="textarea"
                    rows={3}
                    value={package_requested}
                    name="package_requested"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        package_requested: e.target.value,
                      })
                    }
                  ></FormControl>
                  {errors.package_requested && (
                    <p className="text-danger">{errors.package_requested}</p>
                  )}
                </FormGroup>
              </Col>
            </div>
          </>
        ) : (
        <div className="selectPackage">
          <label>Select Package</label>
          {console.log(formData.enable_packages, "package list++++++++=")}
          {filteredPackages.length > 0
            ? filteredPackages.map((item, index) => {
                return (
                  <>
                    <div
                      onClick={() =>
                        handleItemClick(item._id, item.package_price)
                      }
                      className={getClassName(item._id)}
                      key={index}
                    >
                      <div className="packageHeader p-sm-4 p-3 pb-sm-0 pb-0  d-flex align-items-center justify-content-between">
                        <div className="title">{item.package_name}</div>
                        <div className="price">${item.package_price}</div>
                      </div>
                      <div
                        className="content p-sm-4 p-3 py-sm-0 py-0 flex-fill h-100 overflow-x-auto"
                        dangerouslySetInnerHTML={{ __html: item?.details }}
                      >
                        {/* <NavLink to="#" className="btnSeeMore">
                          Show more
                        </NavLink> */}
                      </div>
                    </div>
                  </>
                );
              })
            : null}
          {errors.package_id && (
            <p className="text-danger">{errors.package_id}</p>
          )}
        </div>
        )}
        {formData.enable_packages == true && optionList.length > 0 ? (
          <>
            <div className="additional-option">
              <label>Additional Options</label>
              <div className="additional-option-list">
                {optionList.length > 0
                  ? optionList.map((item, index) => {
                      return (
                        <>
                          <div className="single-option" key={index}>
                            <label className="custom-checkbox">
                              <FormControl
                                onClick={(event) =>
                                  setAdditional(
                                    event,
                                    item._id,
                                    item.item_price
                                  )
                                }
                                type="checkbox"
                              ></FormControl>
                              <span className="checkmark"></span>
                              {item.item_name}
                            </label>
                            <div className="additional-opt-price">
                              <p>${item.item_price} </p>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
          </>
        ) : null}
        {formData.enable_packages == true && (
          <>
        <div className="add-hours-sec">
          <label>Add Hours (${formData.hours_rate} / hour)</label>
          <p>You may request more hours in addition to the selected package.</p>
          <div className="add-price-opt">
            <div className="price-box-left">
              <div className="minus">
                <Button onClick={() => setHours("minus")}>-</Button>
              </div>
              <div className="input-form">
                <FormControl
                  className="incDecInp px-0 text-center"
                  type="number"
                  min={0}
                  max={15}
                  placeholder="1"
                  value={extra_hours}
                  onChange={(e) =>
                    setFormData({ ...formData, extra_hours: e.target.value })
                  }
                ></FormControl>
              </div>
              <div className="plus">
                <Button onClick={() => setHours("plus")}>+</Button>
              </div>
            </div>
            <div className="price-right">
              <p>$ {parseFloat(hours_rate) * parseFloat(extra_hours)}</p>
            </div>
          </div>
        </div>
        <div className="add-hours-sec">
          <label>Estimate Total</label>
          <div className="d-flex gap-3 justify-content-between">
            <p className="txt">
              This is an estimate. Your booking will be reviewed and you will be
              notified of any changes.
            </p>
            <p className="totalPrice">$ {totalCharges}</p>
          </div>
        </div>
        </>
        )}
        <div className="next-prev-btn">
          <Row className="gap-3 gap-sm-0">
            <Col sm="6">
              <div className="step-from-btn h-md-100 mb-0">
                <Button
                  className="btn backFillBtn shadow-none w-100 h-100"
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
              </div>
            </Col>
            <Col sm="6">
              <div className="step-from-btn">
                <Button className="btn btnSubmit w-100" onClick={handleNext}>
                  Next: Event Details
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}


function Step3({ onPrevious, onNext, formData, setFormData, errors }) {
  const {
    sessionArr,
    event_index,
    event_name,
    event_date,
    event_starttime_1,
    event_endtime_1,
    event_starttime_2,
    event_endtime_2,
    event_address,
    event_city,
    event_state,
    event_venue,
    event_country,
    timeFormat,
  } = formData;

  const setSession = async () => {
    console.log("timeFormat", timeFormat);
    setFormData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    if (event_name.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_name: "Please enter event name",
        },
      }));
    } else if (event_date.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_date: "Please select event date",
        },
      }));
    } else if (timeFormat == 24 && event_starttime_1.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_starttime_1: "Please select event start time",
        },
      }));
    } else if (timeFormat == 24 && event_endtime_1.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_endtime_1: "Please select event end time",
        },
      }));
    } else if (timeFormat == 12 && event_starttime_2.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_starttime_2: "Please select event start time",
        },
      }));
    } else if (timeFormat == 12 && event_endtime_2.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_endtime_2: "Please select event end time",
        },
      }));
    } else if (event_address.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_address: "Please enter event address",
        },
      }));
    } else if (event_venue.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_venue: "Please enter event venue",
        },
      }));
    } else if (event_city.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_city: "Please enter event city",
        },
      }));
    } else if (event_state.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_state: "Please enter event state",
        },
      }));
    } else if (event_country.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          event_country: "Please enter event country",
        },
      }));
    } else {
      if (event_index == "" && event_index != "0") {
        let newSessionArr = sessionArr;
        newSessionArr.push({
          event_name: event_name,
          event_date: event_date,
          event_starttime_1: event_starttime_1,
          event_endtime_1: event_endtime_1,
          event_starttime_2: event_starttime_2,
          event_endtime_2: event_endtime_2,
          event_address: event_address,
          event_venue: event_venue,
          event_city: event_city,
          event_state: event_state,
          event_country: event_country,
        });
        setFormData({ ...formData, sessionArr: newSessionArr });
        setFormData({
          ...formData,
          event_index: "",
          event_name: "",
          event_date: "",
          event_starttime_1: "",
          event_endtime_1: "",
          event_starttime_2: "",
          event_endtime_2: "",
          event_address: "",
          event_venue: "",
          event_city: "",
          event_state: "",
          event_country: "",
        });
      } else if (event_index == "0" || event_index >= 0) {
        let newSessionArr_11 = {
          event_name: event_name,
          event_date: event_date,
          event_starttime_1: event_starttime_1,
          event_endtime_1: event_endtime_1,
          event_starttime_2: event_starttime_2,
          event_endtime_2: event_endtime_2,
          event_address: event_address,
          event_venue: event_venue,
          event_city: event_city,
          event_state: event_state,
          event_country: event_country,
        };
        sessionArr[event_index] = newSessionArr_11;
        setFormData({ ...formData, sessionArr: sessionArr });
        setFormData({
          ...formData,
          event_index: "",
          event_name: "",
          event_date: "",
          event_starttime_1: "",
          event_endtime_1: "",
          event_starttime_2: "",
          event_endtime_2: "",
          event_address: "",
          event_venue: "",
          event_city: "",
          event_state: "",
          event_country: "",
        });
      }
    }
  };
  const setEventdata = (index) => {
    let eventData = sessionArr[index];
    setFormData({
      ...formData,
      event_index: index,
      event_name: eventData.event_name,
      event_date: eventData.event_date,
      event_starttime_1: eventData.event_starttime_1,
      event_endtime_1: eventData.event_endtime_1,
      event_starttime_2: eventData.event_starttime_2,
      event_endtime_2: eventData.event_endtime_2,
      event_venue: eventData.event_venue,
      event_address: eventData.event_address,
      event_city: eventData.event_city,
      event_state: eventData.event_state,
      event_country: eventData.event_country,
    });
  };
  const deleteEventdata = (index) => {
    const newArray = [...sessionArr]; // Create a copy of the array
    newArray.splice(index, 1); // Remove the element at the specified index
    setFormData({ ...formData, sessionArr: newArray });
  };
  const handleNext = () => {
    setFormData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    if (sessionArr.length == 0) {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          session_error: "Please enter at least one event session",
        },
      }));
    } else {
      onNext();
    }
  };
  const handlePrevious = () => {
    onPrevious();
  };
  const handleTimeChange_1 = (time) => {
    const formattedTime = moment(time).format("hh:mm A");
    setFormData({ ...formData, event_starttime_2: formattedTime });
  };
  const handleTimeChange_2 = (time) => {
    const formattedTime = moment(time).format("hh:mm A");
    setFormData({ ...formData, event_endtime_2: formattedTime });
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = tomorrow.toISOString().split('T')[0];
  return (
    <>
      <div className="stepsOuter step_3">
        <div className="formSubTitle text-center">Event Information</div>
        <div className="stepsMain position-relative"></div>
        <Form className="booking-form">
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Event Name <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  type="hidden"
                  value={event_index}
                  placeholder="enter index"
                  name="event_index"
                ></FormControl>
                <FormControl
                  value={event_name}
                  onChange={(e) =>
                    setFormData({ ...formData, event_name: e.target.value })
                  }
                  name="event_name"
                ></FormControl>
                {errors.event_name && (
                  <p className="text-danger">{errors.event_name}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Date <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  type="date"
                  value={event_date}
                  onChange={(e) =>
                    setFormData({ ...formData, event_date: e.target.value })
                  }
                  name="event_date"
                  min = {dayAfterTomorrow}
                ></FormControl>
                {errors.event_date && (
                  <p className="text-danger">{errors.event_date}</p>
                )}
              </FormGroup>
            </Col>
            {formData.timeFormat == 24 ? (
              <>
                <Col md="6">
                  <FormGroup className="form-group">
                    <FormLabel>
                      Start Time <span className="text-danger">*</span>
                    </FormLabel>
                    <FormControl
                      type="time"
                      value={event_starttime_1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          event_starttime_1: e.target.value,
                        })
                      }
                      name="event_starttime_1"
                    ></FormControl>
                    {errors.event_starttime_1 && (
                      <p className="text-danger">{errors.event_starttime_1}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-group">
                    <FormLabel>
                      End Time <span className="text-danger">*</span>
                    </FormLabel>
                    <FormControl
                      type="time"
                      value={event_endtime_1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          event_endtime_1: e.target.value,
                        })
                      }
                      name="event_endtime_1"
                    ></FormControl>
                    {errors.event_endtime_1 && (
                      <p className="text-danger">{errors.event_endtime_1}</p>
                    )}
                  </FormGroup>
                </Col>
              </>
            ) : (
              <>
                <Col md="6">
                  <FormGroup className="form-group">
                    <FormLabel>
                      Start Time
                      <span className="text-danger">*</span>
                    </FormLabel>
                    <DatePicker
                      onChange={handleTimeChange_1}
                      value={formData.event_starttime_2}
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm A"
                    />
                    {errors.event_starttime_2 && (
                      <p className="text-danger">{errors.event_starttime_2}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-group">
                    <FormLabel>
                      End Time <span className="text-danger">*</span>
                    </FormLabel>
                    <DatePicker
                      onChange={handleTimeChange_2}
                      value={formData.event_endtime_2}
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm A"
                    />
                    {errors.event_endtime_2 && (
                      <p className="text-danger">{errors.event_endtime_2}</p>
                    )}
                  </FormGroup>
                </Col>
              </>
            )}
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Venue Name <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={event_venue}
                  onChange={(e) =>
                    setFormData({ ...formData, event_venue: e.target.value })
                  }
                  name="event_venue"
                ></FormControl>
                {errors.event_venue && (
                  <p className="text-danger">{errors.event_venue}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Address <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={event_address}
                  onChange={(e) =>
                    setFormData({ ...formData, event_address: e.target.value })
                  }
                  name="event_address"
                ></FormControl>
                {errors.event_address && (
                  <p className="text-danger">{errors.event_address}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  City <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={event_city}
                  onChange={(e) =>
                    setFormData({ ...formData, event_city: e.target.value })
                  }
                  name="event_city"
                ></FormControl>
                {errors.event_city && (
                  <p className="text-danger">{errors.event_city}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  State/Province <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={event_state}
                  onChange={(e) =>
                    setFormData({ ...formData, event_state: e.target.value })
                  }
                  name="event_state"
                ></FormControl>
                {errors.event_state && (
                  <p className="text-danger">{errors.event_state}</p>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="form-group">
                <FormLabel>
                  Country <span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  value={event_country}
                  onChange={(e) =>
                    setFormData({ ...formData, event_country: e.target.value })
                  }
                  name="event_country"
                ></FormControl>
                {errors.event_country && (
                  <p className="text-danger">{errors.event_country}</p>
                )}
              </FormGroup>
            </Col>

            <Col md="12">
              <div className="add-session text-center">
                <NavLink
                  to="#"
                  className="btn btnAddSession shadow-none"
                  onClick={() => setSession()}
                >
                  {parseInt(event_index) == 0 || parseInt(event_index) > 0 ? (
                    <>Save Changes </>
                  ) : (
                    <>+ Add Another Event </>
                  )}
                </NavLink>
              </div>
            </Col>
            {errors.session_error && (
              <p className="text-danger">{errors.session_error}</p>
            )}

            {sessionArr.length > 0 ? (
              <div className="col-12">
                <div className="session-list border-top mb-5">
                  <Row className="mt-4 pt-2 gap-3">
                    {sessionArr.length > 0
                      ? sessionArr.map((item, index) => {
                          return (
                            <>
                              <Col md="12" key={index}>
                                <Card className="session-card">
                                  <div className="card_header border-0 mb-0 align-items-center">
                                    <h5 className="eventTitle text-uppercase">
                                      {item.event_name}
                                    </h5>
                                    <div className="edit-opt gap-3 pt-0">
                                      <NavLink
                                        to="#"
                                        onClick={() => setEventdata(index)}
                                      >
                                        <img src={EditIcon} alt="edit-icon" />
                                      </NavLink>
                                      <NavLink
                                        to="#"
                                        onClick={() => deleteEventdata(index)}
                                      >
                                        <img
                                          className="deletIcon"
                                          src={DeleteIcon}
                                          alt="delete-icon"
                                        />
                                      </NavLink>
                                    </div>
                                  </div>
                                  <div className="client-info-main mb-2">
                                    <div className="client-info mb-0">
                                      <p className="eventDate">
                                        {moment(item.event_date).format(
                                          "MMMM DD, YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="client-info-main mb-2">
                                    <div className="client-info mb-0">
                                      <p className="address">
                                        {item.event_venue},
                                      </p>
                                      <p className="address">
                                        {item.event_address},
                                      </p>
                                      <p className="address">
                                        {item.event_city},{item.event_state},
                                      </p>
                                      <p className="address">
                                        {item.event_country}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="client-info-main mb-2">
                                    <div className="client-info mb-0">
                                      <p className="eventDate">
                                        {formData.timeFormat == 24 ? (
                                          <>
                                            {item.event_starttime_1} -{" "}
                                            {item.event_endtime_1}
                                          </>
                                        ) : (
                                          <>
                                            {item.event_starttime_2} -{" "}
                                            {item.event_endtime_2}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </>
                          );
                        })
                      : null}
                  </Row>
                </div>
              </div>
            ) : null}
            <Col sm="6">
              <div className="step-from-btn">
                <Button
                  className="btn backFillBtn shadow-none w-100 h-100"
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
              </div>
            </Col>
            <Col sm="6" className="mt-3 mt-sm-0">
              <div className="step-from-btn">
                {formData.event_name !== "" ? (
                  <>
                    <Button
                      className="btn btnSubmit w-100"
                      onClick={() => {
                        setSession();
                        handleNext();
                      }}
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="btn btnSubmit w-100"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

function Step4({ onPrevious, onSubmit, formData, isButtonDisabled,setFormData, errors }) {
  ///Image PART
  const {
    imagesArr,
    image,
    document_title,
    additional_notes,
    image_index,
    message,
  } = formData;
  const handlePrevious = () => {
    onPrevious();
  };
  /////////LINK PART
  const { links_arr, links, links_title, links_desc, links_index } = formData;

  const setLinksData = async () => {
    setFormData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    if (links_title.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          links_title: "Please enter link title",
        },
      }));
    } else if (links.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          links: "Please enter your link here",
        },
      }));
    } else if (links_desc.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          links_desc: "Please enter your link description here",
        },
      }));
    } else {
      if (links_index == "" && links_index != "0") {
        let newSessionArr = links_arr;
        newSessionArr.push({
          links: links,
          links_desc: links_desc,
          links_title: links_title,
        });
        setFormData({ ...formData, links_arr: newSessionArr });
        setFormData({
          ...formData,
          links: "",
          links_title: "",
          links_desc: "",
        });
      } else if (links_index == "0" || links_index >= 0) {
        let newSessionArr_11 = {
          links: links,
          links_title: links_title,
          links_desc: links_desc,
        };
        links_arr[links_index] = newSessionArr_11;
        setFormData({ ...formData, links_arr: links_arr });
        setFormData({
          ...formData,
          links_index: "",
          links: "",
          links_title: "",
          links_desc: "",
        });
      }
    }
  };
  const setLinksdata = (index) => {
    let linkData = links_arr[index];
    setFormData({
      ...formData,
      links_index: index,
      links: linkData.links,
      links_title: linkData.links_title,
      links_desc: linkData.links_desc,
    });
  };
  const deleteLinksdata = (index) => {
    const newArray = [...links_arr]; // Create a copy of the array
    newArray.splice(index, 1); // Remove the element at the specified index
    setFormData({ ...formData, links_arr: newArray });
  };
  const handleImage = async (e) => {
    setFormData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    if (document_title.trim() == "") {
      setFormData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          document_title: "Please enter document title",
        },
      }));
    } else {
      ///image Upload
      let imgFile = e.target.files[0];
      let formData_11 = new FormData();
      formData_11.append("image", imgFile);
      try {
        let res = await UserService.imageUpload(formData_11);
        if (res?.data?.status === 200) {
          imagesArr.push({
            document_title: document_title,
            image: res?.data?.data,
          });
          setFormData({
            ...formData,
            imagesArr: imagesArr,
          });
          setFormData({
            ...formData,
            image_index: "",
            document_title: "",
            image: "",
          });
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };
  const deleteImagedata = (index) => {
    const newArray = [...imagesArr]; // Create a copy of the array
    newArray.splice(index, 1); // Remove the element at the specified index
    setFormData({ ...formData, imagesArr: newArray });
  };
  console.log("----formdata----", formData);
  const handelSubmit = () => {
    onSubmit();
  };
  return (
    <>
      <div className="stepsOuter step_4">
        <div className="formSubTitle text-center">Additional Information</div>
        <div className="stepsMain position-relative"></div>
        <ul
          className="nav additionalInfoTabs nav-pills w-100 bg-white mb-3"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link w-100 border-0 shadow-none active"
              id="attachFile-tab"
              data-bs-toggle="pill"
              data-bs-target="#attachFile"
              type="button"
              role="tab"
              aria-controls="attachFile"
              aria-selected="true"
            >
              Attach File
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link w-100 border-0 shadow-none"
              id="attachLinks-tab"
              data-bs-toggle="pill"
              data-bs-target="#attachLinks"
              type="button"
              role="tab"
              aria-controls="attachLinks"
              aria-selected="false"
            >
              Attach Links
            </button>
          </li>
        </ul>
        <div className="tab-content mt-4 pt-2" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="attachFile"
            role="tabpanel"
            aria-labelledby="attachFile-tab"
          >
            <Form className="booking-form">
              <Row>
                <Col md="12">
                  <FormGroup className="form-group">
                    <FormLabel>Document Title</FormLabel>
                    <FormControl
                      name="document_title"
                      value={document_title}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          document_title: e.target.value,
                        })
                      }
                    ></FormControl>
                    {errors.document_title && (
                      <p className="text-danger">{errors.document_title}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="form-group uplaod-item">
                    <div className="file_input">
                      <label htmlFor="file-upload" className="d-none"></label>
                      <input
                        type="file"
                        name="logo"
                        accept=".png, .jpg, .jpeg"
                        id="fileToUpload"
                      />

                      <FormControl
                        type="file"
                        name="fileToUpload"
                        id="fileToUpload"
                        accept="application/pdf, image/*, .doc, .docx, .txt"
                        onChange={(e) => {
                          handleImage(e);
                        }}
                      />
                      <span htmlFor="fileToUpload">
                        <img src={ArrowUp} alt="" />
                        <p className="text-dark">
                          Drag and drop to upload a file
                        </p>
                        <p className="text-dark">OR</p>
                        <span className="text-dark">Select File</span>
                      </span>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="12 d-flex flex-column gap-2">
                  {imagesArr.length > 0
                    ? imagesArr.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="uploadeFileOuter p-sm-4 p-3 bg-white"
                            >
                              <div className="uploadFileHeader d-flex align-items-center justify-content-between">
                                <div className="fileTitle text-uppercase">
                                  {item.document_title}
                                </div>
                                <NavLink
                                  to="javascript:void(0);"
                                  onClick={() => deleteImagedata(index)}
                                >
                                  <img
                                    className="deletIcon"
                                    src={DeleteIcon}
                                    alt="delete-icon"
                                  />
                                </NavLink>
                              </div>
                              <div className="fileName mt-1">
                                <NavLink to={item.image} target="_blank">
                                  Linked File
                                </NavLink>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : null}
                </Col>
              </Row>
            </Form>
          </div>
          <div
            className="tab-pane fade"
            id="attachLinks"
            role="tabpanel"
            aria-labelledby="attachLinks-tab"
          >
            <Form className="booking-form">
              <Row>
                <Col md="12">
                  <FormGroup className="form-group">
                    <FormLabel>Link Title</FormLabel>
                    <FormControl
                      value={links_index}
                      name="links_index"
                      type="hidden"
                    ></FormControl>
                    <FormControl
                      value={links_title}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          links_title: e.target.value,
                        })
                      }
                      name="links_title"
                    ></FormControl>
                    {errors.links_title && (
                      <p className="text-danger">{errors.links_title}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="form-group">
                    <FormLabel>Link URL</FormLabel>
                    <FormControl
                      value={links}
                      onChange={(e) =>
                        setFormData({ ...formData, links: e.target.value })
                      }
                      name="links"
                    ></FormControl>
                    {errors.links && (
                      <p className="text-danger">{errors.links}</p>
                    )}{" "}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="form-group">
                    <FormLabel>Link Description</FormLabel>
                    <FormControl
                      value={links_desc}
                      onChange={(e) =>
                        setFormData({ ...formData, links_desc: e.target.value })
                      }
                      name="links_desc"
                    ></FormControl>
                    {errors.links_desc && (
                      <p className="text-danger">{errors.links_desc}</p>
                    )}{" "}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <div className="add-session text-center">
                    <NavLink
                      to="javascript:void(0);"
                      className="btn btnAddSession shadow-none"
                      onClick={() => setLinksData()}
                    >
                      + Add Another Link
                    </NavLink>
                  </div>
                </Col>
                {links_arr.length > 0 ? (
                  <>
                    <div className="session-list">
                      <Row className="gap-sm-3 gap-2">
                        {links_arr.length > 0
                          ? links_arr.map((item, index) => {
                              return (
                                <>
                                  <Col md="12" key={index}>
                                    <Card className="session-card">
                                      <div className="card_header">
                                        <h5>{item.links_title}</h5>
                                        <div className="edit-opt gap-3 pt-0">
                                          <NavLink
                                            to="javascript:void(0);"
                                            onClick={() => setLinksdata(index)}
                                          >
                                            <img
                                              src={EditIcon}
                                              alt="edit-icon"
                                            />
                                          </NavLink>
                                          <NavLink
                                            to="javascript:void(0);"
                                            onClick={() =>
                                              deleteLinksdata(index)
                                            }
                                          >
                                            <img
                                              className="deletIcon"
                                              src={DeleteIcon}
                                              alt="delete-icon"
                                            />
                                          </NavLink>
                                        </div>
                                      </div>
                                      <div className="client-info-main">
                                        <div className="client-info">
                                          <h5>{item.links}</h5>
                                        </div>
                                      </div>
                                      <div className="client-info-main">
                                        <div className="client-info">
                                          <h5>{item.links_desc}</h5>
                                        </div>
                                      </div>
                                    </Card>
                                  </Col>
                                </>
                              );
                            })
                          : null}
                      </Row>
                    </div>
                  </>
                ) : null}
              </Row>
            </Form>
          </div>
          <Form className="booking-form">
            <Row>
              <Col md="12">
                <div className="additionalNote border-top">
                  <FormGroup className="form-group">
                    <FormLabel>Additional Notes</FormLabel>
                    <FormControl
                      name="message"
                      value={message}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          message: e.target.value,
                        })
                      }
                    ></FormControl>
                  </FormGroup>
                </div>
              </Col>
              <Col md="6">
                <div className="step-from-btn">
                  <Button
                    className="btn backFillBtn shadow-none w-100 h-100"
                    onClick={handlePrevious}
                  >
                    Previous
                  </Button>
                </div>
              </Col>

              <Col md="6">
                <div className="step-from-btn">
                  <Button
                    disabled={isButtonDisabled}
                    className="btn btnSubmit w-100"
                    onClick={handelSubmit}
                  >
                    Submit{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}
const BookingForm = () => {
  const id = localStorage.getItem("userId");
  const [packageList, setpackageList] = useState([]);
  const navigate = useNavigate();
  const [optionList, setoptionList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [photographerData, setPhotographerData] = useState({});
  const [filteredPackages, setFilteredPackages] = useState({});
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  let formValues = {
    step: 1,
    first_name: "",
    last_name: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    message: "",
    ///For package
    package_id: "",
    package_value: "",
    package_requested: "",
    additionalItems: [],
    extra_hours: 0,
    totalCharges: 0,
    hours_rate: 0,
    timeFormat: 12,
    enable_packages: false,
    event_type: "",
    ///For events sessions
    sessionArr: [],
    event_name: "",
    event_date: "",
    event_starttime_1: "",
    event_endtime_1: "",
    event_starttime_2: "",
    event_endtime_2: "",
    event_venue: "",
    event_address: "",
    event_city: "",
    event_state: "",
    event_country: "",
    event_index: "",
    ///Images
    imagesArr: [],
    image_index: "",
    document_title: "",
    additional_notes: "",
    ////Links
    links_arr: [],
    links: "",
    links_title: "",
    links_desc: "",
    links_index: "",
    // Add more form fields as needed
    errors: {},
  };
  const [formData, setFormData] = useState(formValues);

  const getOptions = async () => {
    try {
      const res = await UserService.itemList(id);
      if (res?.data?.success === true) {
        ///toast.success(res?.data?.msg, { autoClose: 2000 });
        setoptionList(res?.data?.result);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg, { autoClose: 2000 });
    }
  };
  const { step, errors } = formData;

  const handleNext = () => {
    setFormData((prevData) => ({ ...prevData, step: step + 1 }));
  };

  const handlePrevious = () => {
    setFormData((prevData) => ({ ...prevData, step: step - 1 }));
  };

  const handleSubmit = async () => {
    setButtonDisabled(true)
    let eventsArr = [];
    let itemsArr = [];
    let linkArr = [];
    let imageArr = [];
    if (formData.sessionArr.length > 0) {
      for (let event of formData.sessionArr) {
        let startTime = event.event_starttime_1;
        let endTime = event.event_endtime_1;
        if (formData.timeFormat == 12) {
          startTime = event.event_starttime_2;
          endTime = event.event_endtime_2;
        }
        eventsArr.push({
          session_name: event.event_name,
          date: event.event_date,
          startTime: startTime,
          endTime: endTime,
          address: event.event_address,
          venue: event.event_venue,
          city: event.event_city,
          state: event.event_state,
          country: event.event_country,
        });
      }
    }
    if (formData.additionalItems.length > 0) {
      for (let item of formData.additionalItems) {
        itemsArr.push(item.label);
      }
    }
    if (formData.links_arr.length > 0) {
      for (let links of formData.links_arr) {
        linkArr.push({
          name: links.links,
          link: links.links_title,
          detail: links.links_desc,
        });
      }
    }
    if (formData.imagesArr.length > 0) {
      for (let image_11 of formData.imagesArr) {
        imageArr.push({
          name: image_11.document_title,
          attachment: image_11.image,
          detail: "dummay",
        });
      }
    }
    let jsonArr = {
      event_type: formData.event_type,
      first_name: formData.first_name,
      event_type: formData.event_type,
      last_name: formData.last_name,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      postalCode: formData.postalCode,
      message: formData.message,
      events: eventsArr,
      package_id: formData.package_id,
      totalCharges: formData.totalCharges.toString(),
      item_id: itemsArr,
      checkList: [],
      additionalHours: formData.extra_hours.toString(),
      hours_rate: formData.hours_rate.toString(),
      link: linkArr,
      attachment: imageArr,
    };
    try {
      const res = await UserService.sendBooking(jsonArr, id);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/add-booking");
        }, 3000);
        setButtonDisabled(false)

      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
        setButtonDisabled(false)

      }
    } catch (error) {
      toast.error(error?.response?.data?.data.error[0], { autoClose: 2000 });
      console.log("error", error);
      setButtonDisabled(false)

    }
  };

  const renderFormStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            onNext={handleNext}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        );
      case 2:
        return (
          <Step2
            onPrevious={handlePrevious}
            onNext={handleNext}
            formData={formData}
            optionList={optionList}
            packageList={packageList}
            setFormData={setFormData}
            categoryList={categoryList}
            filteredPackages={filteredPackages}
            setFilteredPackages={setFilteredPackages}
            errors={errors}
          />
        );
      case 3:
        return (
          <Step3
            onPrevious={handlePrevious}
            onNext={handleNext}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        );
      case 4:
        return (
          <Step4
            onPrevious={handlePrevious}
            onNext={handleNext}
            formData={formData}
            setFormData={setFormData}
            isButtonDisabled = {isButtonDisabled}

            onSubmit={handleSubmit}
            errors={errors}
          />
        );
      default:
        return null;
    }
  };
  const getPhotographer = async () => {
    try {
      const res = await UserService.getPhotographerDetails(id);
      if (res?.data?.success === true) {
        setPhotographerData(res?.data?.result);
        setcategoryList(res?.data?.result?.jobCategory);
        setFormData({
          ...formData,
          hours_rate: res?.data?.result?.settingData?.hours_rate,
          timeFormat: res?.data?.result?.settingData?.time_format,
          enable_packages: res?.data?.result?.settingData?.enable_packages,

        });
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const getPackages = async () => {
    try {
      const res = await UserService.packageList(id);
      if (res?.data?.success === true) {
        //toast.success(res?.data?.msg, { autoClose: 2000 });
        setpackageList(res?.data?.result);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg, { autoClose: 2000 });
    }
  };
  const getEventtypes = async () => {
    try {
      const res = await UserService.eventTypes(id);
      if (res?.data?.success === true) {
        setcategoryList(res?.data?.result);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getOptions();
    getPackages();
    getPhotographer();
    //getEventtypes();
  }, []);
  return (
    <>
      <ToastContainer position="top-right" />
      <div className="formMainOuter">
        <div className="formTitle">Booking Form</div>
        <div className="formInner text-start">{renderFormStep()}</div>
      </div>
    </>
  );
};
export default BookingForm;
