import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import calenderImg from "../../assets/img/calendar.svg";
import watchImg from "../../assets/img/watch.svg";
import mapImg from "../../assets/img/map.svg";
import { toast } from "react-toastify";
import ContentLoader from 'react-content-loader';
import CheckListModal from "../../components/CheckList";
import AddEventTeamModel from "../project/AddEventTeamModel";
import EditEventTeamModel from "../project/EditEventTeamModel";
import EditEventDetail from "../Booking/edit-booking/EditEventDetails";
import UserService from "../../services/UserService";
import OwlCarousel from "react-owl-carousel3";
import editIcon from "../../assets/img/edit.svg";
import moment from "moment";
import loaderGif from "../../assets/img/loading.gif";
import ContractModal from "../../components/ContractModal";
import addIcon from "../../assets/img/plus.svg";
import AdditionalNotesModal from "../../components/AdditionalNotesModal";
import Edit from "../Booking/edit-booking/EditClientDetails";
import Add from "../Booking/add-booking/AddEvent";
import EditPackage from "../Booking/edit-booking/EditPackage";
import EditAttachment from "../Booking/edit-booking/EditAttachment";
import PackageRequestedModal from "../../components/PackageRequestedModal";
import EditDueDate from "../Booking/edit-booking/DueDateModal";

const Projectdetail = () => {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState();
  const [clientData, setClientData] = useState();
  const [bookingData, setBookingData] = useState();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [contractData, setContractData] = useState();
  const [attachmentData, setAttachmentData] = useState();
  const [linkData, setLinkData] = useState();
  const [eventData, setEventData] = useState();
  const [teamList, setTeamList] = useState([]);
  const [checkList, setCheckList] = useState(false);
  const [editAttachment, setEditAttachment] = useState(false);
  const [addTeam, setAddTeam] = useState(false);
  const [editTeam, setEditTeam] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [editEventData, setEditEventData] = useState();
  const [editDueDate,setEditDueDate] = useState();
  const [editDate,setEditDate] = useState(false);

  const [currentEvent, setCurrentEvent] = useState();
  const [settingData, setsettingData] = useState();
  const [roles, setRoles] = useState();
  const [editClient, setEditClient] = useState(false);
  const [addEvent, setAddEvent] = useState(false);
  const [packageData, setPackageData] = useState();
  const [editPackage, setEditPackage] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [viewAdditionalModal, setViewAdditionalModal] = useState(false);
  const [viewPackageRequestedModal, setViewPackageRequestedModal] =
    useState(false);
  const [index, setIndex] = useState();
  const { id } = useParams();
  const { state } = useLocation();
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const getProjectDetail = async () => {
    try {
      let res = await UserService.getProjectDetail(id);
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.message, { autoClose: 1500 });
        setData(res?.data?.data?.details);
        setBookingData(res?.data?.data?.details?.docs[0]);
        setPackageData(res?.data?.data?.details?.docs[0]?.packageData[0]);
        setClientData(res?.data?.data?.details?.docs[0]?.clientData[0]);
        setEventData(res?.data?.data?.details?.docs[0]?.eventData);
        setContractData(res?.data?.data?.details?.docs[0]?.contract);
        setAttachmentData(res?.data?.data?.details?.docs[0]?.attachment);
        setLinkData(res?.data?.data?.details?.docs[0]?.link);
        setLoader(false)
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1500 });
    }
  };

  const getTeamList = async () => {
    try {
      let res = await UserService.getActiveTeam();
      if (res?.data?.status === 200) {
        setTeamList(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const eData = eventData?.sort((a, b) => {
    a.date.localeCompare(b.date);
    const nameComparison = a.date.localeCompare(b.date);
    if (nameComparison !== 0) {
      return nameComparison;
    }
    const timeComparison = a.startTime.localeCompare(b.startTime);
    if (timeComparison !== 0) {
      return timeComparison;
    }
  });

  const getSettingData = async () => {
    try {
      let res = await UserService.getSettings();
      if (res?.data?.success === true) {
        let due_date = res?.data?.result?.due_date.split(" ");
        setsettingData(res?.data?.result);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const getRoles = async () => {
    try {
      let res = await UserService.getRoles();
      if (res?.data?.success === true) {
        setRoles(res?.data?.result);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  useEffect(() => {
    getTeamList();
    getProjectDetail();
    getSettingData();
    getRoles();
    setLoader(true)
  }, [
    !editEvent,
    !addTeam,
    !editTeam,
    !checkList,
    !editClient,
    !editPackage,
    !addEvent,
    !editAttachment,
 refresh

  ]);

  const openChekList = () => {
    setCheckList(true);
  };

  const closeChekList = () => {
    setCheckList(false);
  };

  const openAddTeam = (eventData, index) => {
    setAddTeam(true);
    setCurrentEvent(eventData);
    setIndex(index);
  };

  const closeAddTeam = () => {
    setAddTeam(false);
    setIndex(null);
    getProjectDetail();
  };
  const openEditClient = () => {
    setEditClient(true);
  };

  const closeEditClient = () => {
    setEditClient(false);
  };

  const openEditEvent = (data) => {
    setEditEventData(data);
    setEditEvent(true);
  };
  const openDueDate = (data) => {
    console.log(data, 1233)
    setEditDueDate(data);
    setEditDate(true);
  }
  const closeDueDate = () => {
    getProjectDetail();
    setEditDate(false);
  }
  const closeEditEvent = () => {
    setEditEvent(false);
  };
  const openAddEvent = () => {
    setAddEvent(true);
  };
  const closeAddEvent = () => {
    setAddEvent(false);
  };
  const openEditPackage = () => {
    setEditPackage(true);
  };
  const closeEditPackage = () => {
    setEditPackage(false);
  };
  const openContract = () => {
    if (
      window.confirm(
        "Are you sure ? Any previous edits made to the contract will be lost."
      )
    ) {
      setOpenModal(true);
    }
  };
  const closeContract = () => {
    setOpenModal(false);
  };
  const openAdditionalModal = () => {
    setViewAdditionalModal(true);
  };

  const closeAdditionalModal = () => {
    setViewAdditionalModal(false);
  };
  //Attachment
  const openEditAttachment = () => {
    setEditAttachment(true);
  };
  const closeEditAttachment = () => {
    setEditAttachment(false);
  };
  const openRequestedModal = () => {
    setViewPackageRequestedModal(true);
  };

  const closeRequestedModal = () => {
    setViewPackageRequestedModal(false);
  };
  const comleteProject = async () => {
    setButtonDisabled(true)

    try {
      // let data = { due_date:  };
      let res = await UserService.updateProjectStatus(id, data);
      if (res?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/projects", { state: { booking: true } });
        }, 1000);
        setButtonDisabled(false)
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)

      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      setButtonDisabled(false)

    }
  };
  const itemsTotal = () => {
    let itemsTotal_11 = 0;
    if (bookingData?.itemData?.length > 0) {
      bookingData?.itemData?.map((item, index) => {
        itemsTotal_11 += parseFloat(item.item_price);
      });
    }
    return itemsTotal_11;
  };
  const pkgBkgDtl = {
    hour_rate: bookingData?.hours_rate,
    add_hours: bookingData?.additionalHours,
  };
  const contractedStatus = () => {
    let a;
    if (bookingData?.contractSigned_date) {
      a = " signed ";
    } else if (bookingData?.contractSent_date) {
      a = " sent ";
    } else {
      a = " generated ";
    }
    return a;
  };
  return (
    <>
      {" "}
      {viewPackageRequestedModal && (
        <PackageRequestedModal
          open={openRequestedModal}
          close={closeRequestedModal}
          data={bookingData?.package_requested}
        />
      )}
      {viewAdditionalModal && (
        <AdditionalNotesModal
          open={openAdditionalModal}
          close={closeAdditionalModal}
          data={bookingData?.message}
        />
      )}
      {addEvent && (
        <Add
          open={openAddEvent}
          close={closeAddEvent}
          bookingId={id}
          //teamId={teamId}
        />
      )}
      {editEvent && (
        <EditEventDetail
          open={openEditEvent}
          close={closeEditEvent}
          bookingId={id}
          eventData={editEventData}
        />
      )}
      {editClient && (
        <Edit
          open={openEditClient}
          close={closeEditClient}
          bookingId={id}
          clientData={clientData}
        />
      )}
      {editPackage && (
        <EditPackage
          open={openEditPackage}
          close={closeEditPackage}
          bookingId={id}
          packageData={packageData}
          addOns={bookingData?.itemData}
          pkgBkgDtl={pkgBkgDtl}
        />
      )}
      {openModal && (
        <ContractModal
          open={openContract}
          close={closeContract}
          bookingId={id}
        />
      )}
      {checkList && (
        <CheckListModal
          open={openChekList}
          close={closeChekList}
          clientData={clientData}
          bookingId={id}
        />
      )}
      {addTeam && (
        <AddEventTeamModel
          settingData={settingData}
          teamList={teamList}
          currentEvent={currentEvent}
          open={openAddTeam}
          close={closeAddTeam}
          id={id}
          index={index}
          roles={roles}
        />
      )}
      {editAttachment && (
        <EditAttachment
          open={openEditAttachment}
          close={closeEditAttachment}
          bookingId={id}
          attachmentData={attachmentData}
          linkData={linkData}
        />
      )}
      {editDate && (
        <EditDueDate        
        open={openDueDate}
        close={closeDueDate}
        bookingId={id}
        dueDate={editDueDate}   
        refresh= {()=>setRefresh(prev=>!prev)}   
        />
       
       
      )

      }
     { loader? <ContentLoader/>: <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <div className="projectDetail">
              <div className="row">
                <div className="col-12">
                  <div className="inner-title mb-1">
                    <div className="back-wrap">
                      <NavLink to="/projects">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                            stroke="#2E1885"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Projects
                      </NavLink>
                    </div>
                  </div>
                  <div className="breadcurm-title">
                    <div className="breadcrum-left breadcrum-cust">
                      <h3 className="breadcurm-title my-2">
                        {clientData?.first_name + " " + clientData?.last_name}
                      </h3>
                      <div className="d-flex event-due-date">
                        <div className="eventName p-1 px-3 rounded-pill d-inline-flex mb-4">
                          {bookingData?.eventType[0]?.category_name}
                          
                        </div>
                          <>
                            <div className="mark-complate mt-2">
                              {/* {console.log(bookingData.due_date, 3911)} */}
                            {bookingData?.due_date?
                              <p><span>Due Date:</span>{moment(bookingData?.due_date).format("MMMM DD, YYYY")}
                              <img
                                                    src={editIcon}
                                                    onClick={() =>
                                                      openDueDate(bookingData?.due_date)
                                                    }
                                                  />
                              </p>: 

                            <button
                              disabled={isButtonDisabled}
                                className="btn btn_primary rounded-pill ms-auto"
                                onClick={() => comleteProject()}
                              >
                                Mark as Complete{" "}
                                {isButtonDisabled ? (
                                  <>
                                    <img height={20} width={20} src={loaderGif} />
                                  </>
                                ) : null}
                              </button> }
                            </div>
                          </>
                    </div>
                    </div>
                    <div className="breadcrum-right">
                      {/* {bookingData?.is_completed == true? ( */}
                        
                      {/* ) : null} */}
                      {bookingData?.paymentStatus === "pending" && (
                        <div className="add-new-wrap generate-contract">
                          <button
                            className="btn btn_primary"
                            //onClick={() => openContract()}
                          >
                            Generate Contract
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="booking-dtl-cls">
                    <div className="row">
                      <div className="col-12 col-md-8">
                        <div className="row">
                          <div className="col-md-12 space-sec mt-0">
                            <div className="client-dtl event-cls">
                              <h4>
                                Event/Session
                                <img
                                  src={addIcon}
                                  onClick={() => openAddEvent()}
                                />
                              </h4>
                              {eventData?.length > 0 ? (
                                <div className="row gap-3">
                                  {eData?.map((event, index) => (
                                    <div className="col-12" key={index}>
                                      <div className="card">
                                        <div className="session-header align-items-center">
                                          <h5 className="mb-0">
                                            {event?.session_name
                                              ? event?.session_name
                                              : "-"}
                                          </h5>
                                          <div className="mb-2 d-flex justify-content-start gap-4">
                                            <img
                                              src={editIcon}
                                              onClick={() =>
                                                openEditEvent(event)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="session-dtl">
                                          <div className="date-time-wrap">
                                            <div className="date-left">
                                              <span className="calendar-ic d-flex">
                                                <img
                                                  src={calenderImg}
                                                  alt="Calender Image"
                                                />
                                              </span>
                                              <div className="date">
                                                <p className="m-0 ms-2">
                                                  {event?.date
                                                    ? moment(
                                                        event?.date
                                                      ).format("MMMM DD, YYYY")
                                                    : null}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="time-right">
                                              <span className="wath-ic">
                                                <img
                                                  src={watchImg}
                                                  alt="Watch Image"
                                                />
                                              </span>
                                              <div className="time">
                                                <p className="m-0 ms-2">
                                                  {event?.startTime
                                                    ? event?.startTime
                                                    : "-"}{" "}
                                                  -{" "}
                                                  {event?.endTime
                                                    ? event?.endTime
                                                    : "-"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="location-wrap mt-2">
                                            <span className="map-ic">
                                              <img src={mapImg} alt="" />
                                            </span>
                                            <p className="m-0 ms-2">
                                              <NavLink
                                                target="_blank"
                                                to={
                                                  "https://www.google.com/maps/search/?api=1&query=" +
                                                  event?.venue +
                                                  "," +
                                                  event?.address +
                                                  "," +
                                                  event?.city +
                                                  "," +
                                                  event?.country
                                                }
                                              >
                                                {event?.venue},
                                                {event?.address},
                                               
                                                {event?.city}, {event?.state},
                                                {event?.country}
                                              </NavLink>
                                            </p>
                                          </div>
                                          <div className="team-info mt-3">
                                            <h6>Team</h6>
                                            <div className="team-inner">
                                              <ul>
                                                {event?.eventTeamDetails?.map(
                                                  (team, index) => (
                                                    <>
                                                      <li
                                                        onClick={() =>
                                                          openAddTeam(
                                                            event,
                                                            index
                                                          )
                                                        }
                                                        key={index}
                                                      >
                                                        <span className="team-img">
                                                          <img
                                                            src={
                                                              team
                                                                ?.TeamDetails[0]
                                                                ?.image
                                                            }
                                                            title={
                                                              team
                                                                ?.TeamDetails[0]
                                                                ?.firstName +
                                                              " " +
                                                              team
                                                                ?.TeamDetails[0]
                                                                ?.lastName
                                                            }
                                                          />
                                                        </span>
                                                      </li>
                                                    </>
                                                  )
                                                )}
                                                <li className="add-img">
                                                  <button
                                                    className="add_btn"
                                                    onClick={() =>
                                                      openAddTeam(event, null)
                                                    }
                                                  >
                                                    <svg
                                                      width="25"
                                                      height="26"
                                                      viewBox="0 0 25 26"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12.5001 4.44446C12.7064 4.44446 12.9042 4.5264 13.0501 4.67226C13.196 4.81813 13.2779 5.01596 13.2779 5.22224V12.2222H20.2779C20.4842 12.2222 20.682 12.3042 20.8279 12.45C20.9737 12.5959 21.0557 12.7937 21.0557 13C21.0557 13.2063 20.9737 13.4041 20.8279 13.55C20.682 13.6958 20.4842 13.7778 20.2779 13.7778H13.2779V20.7778C13.2779 20.9841 13.196 21.1819 13.0501 21.3278C12.9042 21.4736 12.7064 21.5556 12.5001 21.5556C12.2939 21.5556 12.096 21.4736 11.9502 21.3278C11.8043 21.1819 11.7224 20.9841 11.7224 20.7778V13.7778H4.72236C4.51608 13.7778 4.31825 13.6958 4.17239 13.55C4.02652 13.4041 3.94458 13.2063 3.94458 13C3.94458 12.7937 4.02652 12.5959 4.17239 12.45C4.31825 12.3042 4.51608 12.2222 4.72236 12.2222H11.7224V5.22224C11.7224 5.01596 11.8043 4.81813 11.9502 4.67226C12.096 4.5264 12.2939 4.44446 12.5001 4.44446Z"
                                                        fill="white"
                                                      ></path>
                                                    </svg>
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                            {bookingData?.contractData?.length > 0 ? (
                              <>
                                <div className="contract-box">
                                  <h4>Contract</h4>
                                  <div className="file_wrap">
                                    <NavLink
                                      to={
                                        `/projects/project-detail/contract-preview/` +
                                        bookingData?.contractData[0]._id
                                      }
                                    >
                                      {bookingData?.clientData[0]?.first_name}{" "}
                                      {bookingData?.clientData[0]?.last_name} -{" "}
                                      {bookingData?.eventType[0]?.category_name}{" "}
                                      Contract
                                      <p class="fst-italic m-0">
                                        Contract {contractedStatus()} on{" "}
                                        {bookingData?.contractGenerate_date
                                          ? moment(
                                              bookingData?.contractGenerate_date
                                            ).format("MMMM DD, YYYY")
                                          : null}
                                      </p>
                                    </NavLink>
                                    <button
                                      onClick={() => openContract()}
                                      className="btn btn_default ms-auto"
                                    >
                                      Regenerate Contract
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : null}

                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="client-dtl project-client-wrap">
                              <div className="card shadow-none bg-transparent p-0">
                                <div className="client-info flex-column gap-2">
                                  <div className="signle-client-info p-0 w-100 mt-1">
                                  {state?.completed === true ? (
                              ""
                            ) : (
                              <div className="col-md-12 space-sec mb-0">
                                <div className="checklist-dtl">
                                  <div className="card">
                                    <button
                                      type="button"
                                      className="btn align-items-center justify-content-between"
                                      onClick={() => openChekList()}
                                    >
                                      <div className="checklist-title">
                                        <h6>Checklist</h6>
                                      </div>
                                      <div className="checklist-status gap-2">
                                        <p className="me-1">
                                          {bookingData?.trueStatusCount}/
                                          {bookingData?.checkDataCount}{" "}
                                          completed
                                        </p>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.8081 3.30806C11.0521 3.06398 11.4479 3.06398 11.6919 3.30806L17.9419 9.55806C18.0592 9.67527 18.125 9.83424 18.125 10C18.125 10.1658 18.0592 10.3247 17.9419 10.4419L11.6919 16.6919C11.4479 16.936 11.0521 16.936 10.8081 16.6919C10.564 16.4479 10.564 16.0521 10.8081 15.8081L15.9911 10.625H2.5C2.15482 10.625 1.875 10.3452 1.875 10C1.875 9.65482 2.15482 9.375 2.5 9.375H15.9911L10.8081 4.19194C10.564 3.94786 10.564 3.55214 10.8081 3.30806Z"
                                            fill="#0F172A"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                                    <div className="clent-inner-dtl">
                                      <h4 className="d-flex justify-content-between">
                                        Client Details{" "}
                                        <span>
                                          <img
                                            src={editIcon}
                                            onClick={() => openEditClient()}
                                            alt="package-update"
                                          />
                                        </span>
                                      </h4>
                                      <div className="name-wrap">
                                        <h5 Name>Full Name</h5>
                                        <p>
                                          {clientData?.first_name}{" "}
                                          {clientData?.last_name}
                                        </p>
                                      </div>
                                      <div className="email-wrap">
                                        <h5>Email</h5>
                                        <a
                                          target="_blank"
                                          href={"mailto:" + clientData?.email}
                                        >
                                          {clientData?.email}
                                        </a>
                                      </div>
                                      <div className="phone-wrap">
                                        <h5>Phone</h5>
                                        <a
                                          target="_blank"
                                          href={
                                            "tel:" + clientData?.mobileNumber
                                          }
                                        >
                                          {clientData?.mobileNumber}
                                        </a>
                                      </div>
                                      <div className="address-wrap">
                                        <h5>Address</h5>
                                        <p>
                                          {clientData?.address} <br />
                                          {clientData?.city},{" "}
                                          {clientData?.state} <br />
                                          {clientData?.country},
                                          {clientData?.postalCode} <br />
                                        </p>
                                      </div>
                                    </div>

                                  
                                    {bookingData?.package_requested ? (
                                      <>
                                        <div className="col-md-12 mt-4">
                                          <div className="client-dtl additional-note">
                                            <h4 className="d-flex justify-content-between">
                                              Package Requested
                                            </h4>
                                            <div className="card shadow-none bg-transparent p-0">
                                              <div className="file_wrap">
                                                {bookingData?.package_requested}
                                              </div>
                                              {bookingData?.package_requested
                                                ?.length > 200 ? (
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={openRequestedModal}
                                                >
                                                  Read More
                                                </a>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) :   <div className="requested-service">
                                    <h4 className="d-flex justify-content-between">
                                      Requested Services
                                      <span>
                                        <img
                                          src={editIcon}
                                          onClick={() => openEditPackage()}
                                          alt="package-update"
                                        />
                                      </span>
                                    </h4>
                                    <div className="price-box">
                                      <ul>
                                        {packageData?.package_price > 0 ? (
                                          <>
                                            <li className="d-flex justify-content-between">
                                              <span>Essentials Package</span>
                                              <span className="price-font">
                                                ${packageData?.package_price}
                                              </span>
                                            </li>
                                          </>
                                        ) : null}

                                        <li className="d-flex justify-content-between">
                                          <span>Additional Options</span>
                                          <span className="price-font">
                                            ${itemsTotal()}
                                          </span>
                                        </li>
                                        <li className="d-flex justify-content-between">
                                          <span>Addon Hours</span>
                                          <span className="price-font">
                                            $
                                            {parseInt(
                                              bookingData?.hours_rate
                                            ) * bookingData?.additionalHours}
                                          </span>
                                        </li>
                                        <li className="total-price">
                                          <span>Total</span>
                                          <span className="price-font">
                                            $
                                            {parseInt(
                                              bookingData?.totalCharges
                                            )}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {bookingData?.link?.length ||
                          bookingData?.attachment?.length > 0 ? (
                            <>
                              <div className="col-md-12 mt-4">
                                <div className="client-dtl attachment-wrap">
                                  <h4>
                                    Attachments
                                    <span>
                                      <img
                                        src={editIcon}
                                        onClick={() => openEditAttachment()}
                                        alt="attachment-update"
                                      />
                                    </span>
                                  </h4>
                                  <div className="card shadow-none bg-transparent p-0">
                                    {bookingData?.attachment?.length
                                      ? bookingData?.attachment?.map(
                                          (attach, index_1) => (
                                            <>
                                              <div className="file_wrap">
                                                <NavLink
                                                  target="_blank"
                                                  to={attach.attachment}
                                                >
                                                  <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M4.6875 1.25C3.82456 1.25 3.125 1.94955 3.125 2.8125V17.1875C3.125 18.0504 3.82455 18.75 4.6875 18.75H15.3125C16.1754 18.75 16.875 18.0504 16.875 17.1875V10.625C16.875 8.89911 15.4759 7.5 13.75 7.5H12.1875C11.3246 7.5 10.625 6.80044 10.625 5.9375V4.375C10.625 2.64911 9.22589 1.25 7.5 1.25H4.6875Z"
                                                      fill="#0F172A"
                                                    />
                                                    <path
                                                      d="M10.8093 1.51325C11.4733 2.2804 11.875 3.28079 11.875 4.375V5.9375C11.875 6.11009 12.0149 6.25 12.1875 6.25H13.75C14.8442 6.25 15.8446 6.6517 16.6117 7.31567C15.8719 4.48323 13.6418 2.25315 10.8093 1.51325Z"
                                                      fill="#0F172A"
                                                    />
                                                  </svg>
                                                  {attach.name}
                                                </NavLink>
                                              </div>
                                            </>
                                          )
                                        )
                                      : null}

                                    {bookingData?.link?.length
                                      ? bookingData?.link?.map(
                                          (links, index_2) => (
                                            <>
                                              <div className="file_wrap gap-3">
                                                <NavLink
                                                  target="_blank"
                                                  to={links.link}
                                                >
                                                  <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M16.5847 3.41529C15.3643 2.1949 13.3857 2.1949 12.1653 3.41529L8.41529 7.16529C7.1949 8.38568 7.1949 10.3643 8.41529 11.5847C8.67435 11.8438 8.96648 12.0471 9.27752 12.1957C9.58899 12.3445 9.72086 12.7176 9.57207 13.0291C9.42328 13.3405 9.05018 13.4724 8.73871 13.3236C8.30141 13.1147 7.89219 12.8294 7.53141 12.4686C5.82286 10.76 5.82286 7.98995 7.53141 6.28141L11.2814 2.53141C12.99 0.822864 15.76 0.822864 17.4686 2.53141C19.1771 4.23995 19.1771 7.01005 17.4686 8.71859L16.0045 10.1827C15.7604 10.4268 15.3646 10.4268 15.1206 10.1827C14.8765 9.93866 14.8765 9.54293 15.1206 9.29885L16.5847 7.83471C17.8051 6.61432 17.8051 4.63568 16.5847 3.41529ZM10.4279 6.97095C10.5767 6.65948 10.9498 6.52761 11.2613 6.67639C11.6986 6.8853 12.1078 7.17062 12.4686 7.53141C14.1771 9.23995 14.1771 12.01 12.4686 13.7186L8.71859 17.4686C7.01005 19.1771 4.23995 19.1771 2.53141 17.4686C0.822864 15.76 0.822864 12.99 2.53141 11.2814L3.99555 9.81727C4.23963 9.57319 4.63536 9.57319 4.87943 9.81727C5.12351 10.0613 5.12351 10.4571 4.87943 10.7011L3.41529 12.1653C2.1949 13.3857 2.1949 15.3643 3.41529 16.5847C4.63568 17.8051 6.61432 17.8051 7.83471 16.5847L11.5847 12.8347C12.8051 11.6143 12.8051 9.63568 11.5847 8.41529C11.3257 8.15623 11.0335 7.95289 10.7225 7.80431C10.411 7.65552 10.2791 7.28241 10.4279 6.97095Z"
                                                      fill="#0F172A"
                                                    />
                                                  </svg>
                                                  {links.name}
                                                  <p className="fst-italic m-0">
                                                    {links.detail}
                                                  </p>
                                                </NavLink>
                                              </div>
                                            </>
                                          )
                                        )
                                      : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <div className="col-md-12 mt-4">
                            <div className="client-dtl additional-note">
                              <h4>Additional Notes</h4>
                              <div className="card shadow-none bg-transparent p-0">
                                <div className="file_wrap">
                                  {bookingData?.message}
                                </div>
                                {bookingData?.message?.length > 200 ? (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={openAdditionalModal}
                                  >
                                    Read More
                                  </a>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Projectdetail;
