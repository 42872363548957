import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Email from "../../../assets/img/email.png";
import Mobile from "../../../assets/img/mobile.png";
import Message from "../../../assets/img/msg.png";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import moment from "moment";

const ResponseDetail = () => {
  const [responseDetail, setResponseDetail] = useState();
  const { id } = useParams();

  const getDetail = async () => {
    try {
      let res = await UserService.getTemplate(id);
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.msg, { autoClose: 2000 });
        console.log(res?.data?.data, "jjsjsdjsjddjs");
        setResponseDetail(res?.data?.data);
      } else {
        toast.error(res?.data?.msg, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  useEffect(() => {
    getDetail();
  }, []);

  //   console.log("first", responseDetail);

  return (
    <>
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">View Template</h3>
            <div className="space-sec mt-0">
              <div className="inner-title">
                <div className="back-wrap">
                  <NavLink to="/settings" state={{ settingTab: "response" }}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                        stroke="#2E1885"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Response Templates
                  </NavLink>
                </div>
              </div>
              <div className="booking-dtl-cls">
                <div className="row">
                  <div className="col-md-12">
                    <div className="client-dtl project-client-wrap">
                      <h4>Response Title</h4>
                      <div className="card">
                        <div className="client-info">
                          <div className="signle-client-info">
                            <h5>{responseDetail?.subject}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 space-sec mb-0">
                    <div className="col-md-12">
                      <div className="client-dtl">
                        <h4>Response Message</h4>
                        <div className="card">
                          <div className="client-info-main">
                            <div
                              className="client-info"
                              dangerouslySetInnerHTML={{
                                __html: responseDetail?.message,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponseDetail;
