import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const AdditionalNotesModal = ({ open, close, data }) => {
  return (
    <>
      <Modal
        show={open}
        hide={close}
        className="modal notificationModal fade customModal"
        id="viewNotification"
        tabindex="-1"
        aria-labelledby="viewNotificationLabel"
        aria-hidden="true"
      >
        <div className="modal-header position-relative start-0 top-0 border-bottom p-3">
          <div className="modal-title" id="exampleModalLabel">
            Additional Notes
          </div>
          <button
            type="button"
            className="btn-close border-0 shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          ></button>
        </div>
        <div className="modal-body">
          <div className="notificationItem">
            <div className="row">
              <div className="col-12">
                <div className="pera">{data}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdditionalNotesModal;
