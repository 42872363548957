import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

/* Global Routes */
import CustomerForm from "./pages/customer/Form";
import BookingForm from "./pages/customer/Booking";
import BookingThanks from "./pages/customer/BookingThanks";
import CustomerThanks from "./pages/customer/CustomerThanks";
import TransactionForm from "./pages/customer/TransactionForm";
import TransactionThanks from "./pages/customer/TransactionThanks";
import PaymentThanks from "./pages/customer/PaymentThanks";
import CustomerContractPage from "./pages/customer/CustomerContractPage";

/* Auth Routes */
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Register_2 from "./pages/auth/Register_2";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

/* User Routes */
import Dashboard from "./pages/dashboard/Dashboard";
import Private from "./components/Private";
import Public from "./components/Public";
import VerifyOtp from "./pages/auth/VerifyOtp";
import Inquiries from "./pages/Inquiries/Inquiries";
import Booking from "./pages/Booking/Booking";
import Settings from "./pages/settings/Settings";
import InquiryDetail from "./pages/Inquiries/InquiryDetail";
import BookingDetail from "./pages/Booking/BookingDetail";
import AddResponse from "./pages/settings/response-template/Add";
import EditResponse from "./pages/settings/response-template/Edit";
import ResponseDetail from "./pages/settings/response-template/ResponseDetail";
import AddContract from "./pages/settings/contract-template/Add";
import EditContract from "./pages/settings/contract-template/Edit";
import ContractDetail from "./pages/settings/contract-template/ContractDetail";
import SendResponse from "./pages/Inquiries/SendResponse";
import Projects from "./pages/project/Projects";
import ProjectDetail from "./pages/project/ProjectDetail";
import Teams from "./pages/team/Teams";
import TeamDetail from "./pages/team/TeamDetail";
import EditBookingDetail from "./pages/Booking/edit-booking/index";
import AddBooking from "./pages/Booking/add-booking/Index";
import Notification from "./components/Notification";
import ContractPreview from "./pages/project/contract/ContractPreview";
import EditBookingContract from "./pages/project/contract/EditBookingContract";
import { ToastContainer, toast } from "react-toastify";
import PaymentThanksApp from "./pages/customer/PaymentThanksApp";

const App = () => {
  const element = useRoutes([
    {
      path: "/genrate-contract/:photographerid/:contractId",
      element: <CustomerContractPage />,
    },
    /****Customer Routes****/
    {
      path: "/customer-form/:id",
      element: <CustomerForm />,
    },
    {
      path: "/customer-form-thanks",
      element: <CustomerThanks />,
    },
    {
      path: "/booking-form-thanks",
      element: <BookingThanks />,
    },
    {
      path: "/booking-form/:id",
      element: <BookingForm />,
    },
    {
      path: "/transaction/:id/:transId",
      element: <TransactionForm />,
    },
    {
      path: "/transaction-thanks",
      element: <TransactionThanks />,
    },
    // {
    //   path: "/thanks-payment-screen/:photographerId/:subscriptionId",
    //   element: <PaymentThanks />,
    // },
   
    /****Authentication*****/
    {
      path: "/forgot-password",
      element: <Public children={<ForgotPassword />} />,
    },
    {
      path: "/verify-otp",
      element: <Public children={<VerifyOtp />} />,
    },
    {
      path: "/reset-password/:token_id",
      element: <Public children={<ResetPassword />} />,
    },
    {
      path: "/",
      element: <Private children={<Dashboard />} />,
    },
    { path: "/login", element: <Public children={<Login />} /> },
    { path: "/register", element: <Public children={<Register />} /> },
    {
      path: "/register-complete/:id",
      element: <Public children={<Register_2 />} />,
    },
    {
      path: "/thanks-payment-screen/:photographerId/:subscriptionId",
      element:  <Public children={<PaymentThanks />}  />,
    },
   
    /***Projects Routes ***/
    {
      path: "/projects",
      element: <Private children={<Projects />} />,
    },
    {
      path: "/projects/project-detail/:id",
      element: <Private children={<ProjectDetail />} />,
    },
    {
      path: "/projects/project-detail/contract-preview/:id",
      element: <Private children={<ContractPreview />} />,
    },
    {
      path: "/projects/project-detail/contract-preview/edit-contract/:id",
      element: <Private children={<EditBookingContract />} />,
    },
    /***Team Routes ***/
    {
      path: "/teams",
      element: <Private children={<Teams />} />,
    },
    {
      path: "/teams/team-details/:id",
      element: <Private children={<TeamDetail />} />,
    },
    /****Setting Routes****/
    {
      path: "/settings",
      element: <Private children={<Settings />} />,
    },
    {
      path: "/settings/contract-template/add",
      element: <Private children={<AddContract />} />,
    },
    {
      path: "/settings/contract-template/edit/:id",
      element: <Private children={<EditContract />} />,
    },
    {
      path: "/settings/contract-template/view/:id",
      element: <Private children={<ContractDetail />} />,
    },
    {
      path: "/settings/response-template/add",
      element: <Private children={<AddResponse />} />,
    },
    {
      path: "/settings/response-template/edit/:id",
      element: <Private children={<EditResponse />} />,
    },
    {
      path: "/settings/response-template/view/:id",
      element: <Private children={<ResponseDetail />} />,
    },
    /***Inquiries Routes ***/
    {
      path: "/inquiries",
      element: <Private children={<Inquiries />} />,
    },
    {
      path: "/inquiries/inquiry-detail/:id",
      element: <Private children={<InquiryDetail />} />,
    },
    {
      path: "/inquiries/inquiry-detail/send-response/:id",
      element: <Private children={<SendResponse />} />,
    },
    /***Booking Routes ***/
    {
      path: "/bookings",
      element: <Private children={<Booking />} />,
    },
    {
      path: "/bookings/add-booking",
      element: <Private children={<AddBooking />} />,
    },
    {
      path: "/bookings/booking-detail/:id",
      element: <Private children={<EditBookingDetail />} />,
    },
    {
      path: "/bookings/edit-booking/:id",
      element: <Private children={<EditBookingDetail />} />,
    },
    {
      path: "/bookings/booking-detail/contract-preview/:id",
      element: <Private children={<ContractPreview />} />,
    },
    {
      path: "/bookings/booking-detail/contract-preview/edit-contract/:id",
      element: <Private children={<EditBookingContract />} />,
    },
    /***Notification Rotues***/
    {
      path: "/notification",
      element: <Private children={<Notification />} />,
    },

    { path: "/*", element: <Navigate to="/login" replace /> },
  ]);
  return(<>
    <ToastContainer position="top-right" />
    {element}
  </>);
};

export default App;
