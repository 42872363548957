import React from "react";
import calenderImg from "../../assets/img/calendar.svg";
import mapImg from "../../assets/img/map.svg";
import starImg from "../../assets/img/star.svg";
import { NavLink } from "react-router-dom";
import jobNotFound from "../../assets/img/jobs_not_found.svg";

import moment from "moment";

const Active = ({ data }) => {
  return (
    <>
      <div className="row">
        {data?.length > 0
          ? data?.map((list, idx) => (
              <div className="" key={idx}>
                {data === undefined ? (
                  <div className="pro-card card">
                     <>
                    <div className="not-found-img">
                      <img src={jobNotFound} />
                    </div>
                  </>
                  </div>
                ) : (
                  <div className="pro-card card">
                    <NavLink to={`/projects/project-detail/${list?._id}`}>
                      <div className="pro-tit">
                        <h5>
                          {list?.clientData[0]?.first_name +
                            " " +
                            list?.clientData[0]?.last_name}
                        </h5>
                        <div className="rating-star d-none">
                          <img src={starImg} alt="project-like" />
                        </div>
                      </div>
                      {/* <div className="pro-address">
                        <div className="map-ic">
                          <img src={mapImg} alt="Calender Image" />
                        </div>
                        <p>{list?.clientData[0]?.address}</p>
                        <p>
                          {list?.clientData[0]?.city},
                          {list?.clientData[0]?.state}
                        </p>
                        <p>{list?.clientData[0]?.country}</p>
                      </div> */}

                      <div className="date-session">
                        <div className="date-wrap">
                          <div className="calendar">
                          <p>Next Event: </p>

                            {/* <img src={calenderImg} alt="Calender Image" /> */}
                          </div>
                          <p>
                            {list?.eventData.length>0 ? moment(list?.eventData[0]?.date).format("MMMM DD, YYYY"): "no event"}
                          </p>
                        </div>
                        <div className="session-wrap">
                          <p>{list?.eventData?.length} Sessions</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )}
              </div>
            ))
          : <>
          <div className="not-found-img">
            <img src={jobNotFound} />
          </div>
        </>}
      </div>
    </>
  );
};

export default Active;
