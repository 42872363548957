import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo-2.png";
import upload from "../../assets/img/upload.svg";
import eyeOpen from "../../assets/img/eye.svg";
import eyeClose from "../../assets/img/eye-slash.svg";
import dummy from "../../assets/img/dummy.jpg";
import PhoneInput from "react-phone-input-2";
import { ToastContainer, toast } from "react-toastify";
import AuthCarousel from "../../components/AuthCarousel";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const Register = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    // phone: "",
    companyName: "",
    // profilepic: "",
    // link: "",
    password: "",
    // confirmPassword: "",
  };

  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();
  const [showPass, setShowPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const [imageName, setImageName] = useState();
  const [countryName, setCountryName] = useState(
    localStorage.getItem("country") ? localStorage.getItem("country") : ""
  );
  const [mobileNumber, setMobileNumber] = useState({
    mobileNo: "",
    country_code: "",
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required("First Name is required!"),
    lastName: Yup.string().max(255).required("Last Name is required!"),
    email: Yup.string().email().max(255).required("Email is required").trim(),
    // phone: Yup.string().required("Mobile No. is required!"),
    companyName: Yup.string().max(255).required("Company Name is required!"),
    // profilepic: Yup.mixed()
    //   .test("fileFormat", "Unsupported Format", (value) => {
    //     if (typeof value === "string") {
    //       return true;
    //     } else {
    //       return !value || (value && SUPPORTED_FORMATS.includes(value.type));
    //     }
    //   })
    //   .test("fileSize", "File too large (max size 5MB)", (value) => {
    //     if (typeof value === "string" || typeof value === "undefined") {
    //       return true;
    //     } else {
    //       return value && value.size <= 5000000;
    //     }
    //   }),
    // link: Yup.string()
    //   .url("Invalid URL format")
    //   .required("Website Link is required!"),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Password must contain at least 8 character. Only strong password allowed."
      )
      .required("Password is required")
      .trim(),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords do not match")
    //   .required("Confirm Password is required!"),
  });

  const handleImage = async (img) => {
    let formData = new FormData();
    formData.append("image", img);
    try {
      let res = await UserService.imageUpload(formData);
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.message, { autoClose: 2000 });
        setProfileImage(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const onSubmit = async (values) => {
    // if (!mobileNumber.mobileNo || !mobileNumber.country_code) {
    //   return toast.error("Mobile number is required!", { autoClose: 2000 });
    // } else if (mobileNumber.mobileNo.length < 10) {
    //   return toast.error("Mobile number is not correct", { autoClose: 2000 });
    // }
    const data = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      password: values?.password,
      // mobileNo: mobileNumber.mobileNo,
      // countryCode: mobileNumber.country_code,
      companyName: values?.companyName,
      // companyLogo: profileImage,
      // companySite: values?.link,
    };
    try {
      const res = await AuthService.register(data);
      console.log("res", res?.data);
      if (res?.data?.status === true) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/register-complete/" + res?.data?.data?._id);
        }, 1000);
      } else {
        toast.error(
          res?.data?.data ? res?.data?.data.error : res?.data?.message,
          { autoClose: 2000 }
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  const handleNumber = (value, data, event, formattedValue) => {
    console.log(value, 144, data, 133);
    var country_code = data.dialCode;

    setMobileNumber({
      country_code: country_code,
      mobileNo: value.slice(data.dialCode.length),
    });
  };

  useEffect(() => {
    // Function to fetch IP information and set the country name
    const fetchIPInfo = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io?token=e1ea52d0952a07"
        );
        const data = response.data;
        localStorage.setItem("country", data.country.toLowerCase());
      } catch (error) {
        console.error("Error fetching IP information:", error);
      }
    };
    // Call the function to fetch IP information
    if (countryName == "") {
      fetchIPInfo();
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="login-sec">
        <div className="container-fluid p-0">
          <div className="login-inner">
            <Row className="m-0">
              <Col md="6" className="p-0">
                <AuthCarousel />
              </Col>
              <Col md="6" className="p-0">
                <div className="login-form signup-form">
                  <div className="login-form-cont">
                    <img src={logo} alt="Logo" />
                    <h6>The simplest workflow tool for freelancers.</h6>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                              />
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="firstName"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                              />
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="lastName"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                              <label>Email Address</label>
                              <input
                                type="text"
                                name="email"
                                placeholder="name@email.com"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="email"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                              <label>Company Name</label>
                              <input
                                type="text"
                                name="companyName"
                                placeholder="Company Name"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.companyName}
                              />
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="companyName"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="form-group pw-show-cls">
                              <label>Password</label>

                              <input
                                type={showPass ? "text" : "password"}
                                name="password"
                                placeholder=". . . . . . ."
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              <div className="pw-show">
                                <img
                                  src={showPass ? eyeOpen : eyeClose}
                                  onClick={() => {
                                    setShowPass(!showPass);
                                  }}
                                />
                              </div>
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="password"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="login-btn">
                          <button
                            className="btn btn_primary"
                            type="submit"
                            // disabled={!isSubmitting}
                          >
                            Sign up
                          </button>
                        </div>
                        <div className="sign-up-free">
                          <p className="btn light_btn">
                            Already have an account?{" "}
                            <NavLink to="/login">Login</NavLink>
                          </p>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
