import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import LogoImg from "../../assets/img/logo.png";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";
import axios from "axios";
import ReactQuill from "react-quill";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const CustomerForm = () => {
  const [customerForm, setCustomerForm] = useState([]);
  const [formData, setformData] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [photographerData, setPhotographerData] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { id } = useParams();
  const validationSchema = Yup.object({
    email: Yup.string().email().required("Valid mail is required"),
  });
  const navigate = useNavigate();
  const onSubmit = async () => {
    setSubmitDisabled(true);
    let customerForms_11 = {};
    for (let form11 of formData) {
      customerForms_11[form11.id] = form11.value;
    }

    const data = {
      customerForm: customerForms_11,
    };
    console.log(data, 355);
    try {
      const res = await UserService.sendEnquiry(data, id);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setformData([]);
        setTimeout(() => {
          navigate("/customer-form-thanks");
        }, 1000);
      } else {
        setSubmitDisabled(false);
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      setSubmitDisabled(false);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  const getCutomerForm = async () => {
    try {
      const res = await UserService.getCustomerForm(id);
      if (res?.data?.status === 200) {
        setCustomerForm(res?.data?.data?.list);
        let formArrr = [];
        for (let formData of res?.data?.data?.list) {
          let key = formData._id;
          formArrr.push({ id: key, value: "" });
        }
        setformData(formArrr);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  const getPhotographer = async () => {
    try {
      const res = await UserService.getPhotographerDetails(id);
      if (res?.data?.success === true) {
        setPhotographerData(res?.data?.result);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const handleChange = (e) => {
    // console.log(e,89000)
    // console.log(formData);
    const itemIndex = formData.findIndex((item) => item.id === e.target.name);
    if (itemIndex !== -1) {
      const updatedItems = [...formData]; // Create a new copy of the array
      updatedItems[itemIndex].value = e.target.value; // Update the desired item
      setformData(updatedItems); // Set the updated array state
    }
    // console.log(formData, 9333);
  };
  const setFieldValue = (id, e) => {
    setformData((prev) =>
      prev.map((item) => (item.id === id ? { ...item, value: e } : item))
    );
  };

  useEffect(() => {
    getCutomerForm();
    getPhotographer();
  }, []);
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  return (
    <>
      <ToastContainer position="top-right" />
      <div className="pageMainWraper">
        <div className="container">
          <div className="pageHeader text-center">
            <div className="photoGrapherOuter">
              <div className="proImgOuter rounded-circle overflow-hidden mx-auto d-flex align-items-center justify-content-center">
                {photographerData?.photographerData?.companyLogo == null ? (
                  <>
                    <img
                      className="w-100 h-100"
                      src={LogoImg}
                      alt="photographer-logo"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="w-100 h-100"
                      src={photographerData?.photographerData?.companyLogo}
                      alt="photographer-logo"
                    />
                  </>
                )}
              </div>
              <div className="photographerName">
                {photographerData?.photographerData?.companyName}
              </div>
              <NavLink
                target="_blank"
                to={photographerData?.photographerData?.companySite}
                className="btn btnVisitBtn border-0 shadow-none"
              >
                Visit Website
              </NavLink>
            </div>
          </div>
          <div className="formMainOuter">
            <div className="formTitle">Inquiry Form</div>
            <div className="formInner text-start">
              <div className="login-form p-0">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      {customerForm?.map((item, index) =>
                        item.type == "Textbox" ? (
                          <>
                            {item.label == "Email" ? (
                              <>
                                <div className="form-group" key={index}>
                                  <label htmlFor={item._id}>
                                    {item.label}
                                    {item.answer_type == "Mandatory" ? (
                                      <>
                                        <span className="text-danger">*</span>
                                      </>
                                    ) : null}
                                  </label>
                                  <input
                                    type="email"
                                    name={item._id}
                                    id={item._id}
                                    className="form-control"
                                    onChange={handleChange}
                                    required={item.answer_type === "Mandatory"}
                                    autoComplete="off"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                    title="Enter a valid email address"
                                  />
                                </div>
                              </>
                            ) : item.label == "Mobile No." ? (
                              <>
                                <div className="form-group" key={index}>
                                  <label htmlFor={item._id}>
                                    {item.label}
                                    {item.answer_type == "Mandatory" ? (
                                      <>
                                        <span className="text-danger">*</span>
                                      </>
                                    ) : null}
                                  </label>
                                  {/* <p>+1</p> */}
                                  <PhoneInput
                                    type="phone"
                                    country="ca" // Set the default country code to Canada
                                    disableDropdown // Disable the country selection dropdown
                                    countryCodeEditable={false}
                                    name={item._id}
                                    id={item._id}
                                    className="form-control"
                                    onChange={(e) => {
                                      // setFieldValue(item._id, e)
                                      const inputValue = e;
                                      if (inputValue.length == 11) {
                                        // The input length is within the valid range
                                        setFieldValue(item._id, inputValue);
                                        setErrorState(false);
                                      } else {
                                        // The input length is outside the valid range, you can display an error message or take other actions
                                        // For example, you can set an error state and display an error message to the user
                                        setErrorState(true);
                                      }
                                    }}
                                    required={item.answer_type === "Mandatory"}
                                    autoComplete="off"
                                  />
                                  {errorState && (
                                    <p style={{ color: "red" }}>
                                      Incorrect mobile number.
                                    </p>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <label>
                                  <div className="form-group" key={index}>
                                    {item.label}
                                    {item.answer_type == "Mandatory" ? (
                                      <>
                                        {/* <label htmlFor={item._id}> */}
                                        <>
                                          <span className="text-danger">*</span>
                                        </>

                                        {/* </label> */}
                                        <input
                                          type="text"
                                          name={item._id}
                                          id={item._id}
                                          className="form-control"
                                          onChange={(e) => handleChange(e)}
                                          required={
                                            item.answer_type === "Mandatory"
                                          }
                                          autoComplete="off"
                                          // pattern="[A-Za-z]+"
                                          // title="Enter only alphabetic characters"
                                        />
                                      </>
                                    ) : (
                                      <input
                                        type="text"
                                        name={item._id}
                                        id={item._id}
                                        className="form-control"
                                        onChange={(e) => handleChange(e)}
                                        // required="required"
                                        autoComplete="off"
                                        // pattern="[A-Za-z]+"
                                        // title="Enter only alphabetic characters"
                                      />
                                    )}
                                  </div>
                                </label>
                              </>
                            )}

                            <p className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name={item._id}
                              />
                            </p>
                          </>
                        ) : item.type == "Selectbox" ? (
                          <>
                            <div className="form-group">
                              <label htmlFor={item._id}>
                                {item.label}{" "}
                                {item.answer_type == "Mandatory" ? (
                                  <>
                                    <span className="text-danger">*</span>
                                  </>
                                ) : null}
                              </label>

                              <select
                                name={item._id}
                                id={item._id}
                                className="form-control"
                                onChange={handleChange}
                                required={item.answer_type === "Mandatory"}
                              >
                                <option value={""}>Select</option>
                                {item.options.length > 0 &&
                                  item.options.map((options, indx) => (
                                    <option value={options} key={indx}>
                                      {options}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </>
                        ) : item.type == "Textarea" ? (
                          <>
                            <div className="form-group">
                              <label htmlFor={item._id}>
                                {item.label}
                                {item.answer_type == "Mandatory" ? (
                                  <>
                                    <span className="text-danger">*</span>
                                  </>
                                ) : null}
                              </label>

                              <ReactQuill
                                name={item._id}
                                id={item._id}
                                className="form-control"
                                cols={5}
                                onChange={(e) => setFieldValue(item._id, e)}
                                required={item.answer_type === "Mandatory"}
                                modules={{
                                  toolbar: false,
                                  clipboard: true,
                                }}
                                style={{ border: "none" }}
                              />
                            </div>
                          </>
                        ) : null
                      )}

                      <div className="login-btn">
                        <button
                          type="submit"
                          className="btn btnSubmit w-100"
                          disabled={submitDisabled}
                        >
                          Send Inquiry
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="pageFooter text-center">
            <div className="footerOuter">
              <div className="title">Powered by</div>
              <div className="companyLogo mx-auto">
                <img className="w-100" src={LogoImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerForm;
