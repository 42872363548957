import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { ErrorMessage, Formik } from "formik";
import { Button, FormControl, Modal, ToastContainer } from "react-bootstrap";
import UserService from "../../../services/UserService";
import { NavLink } from "react-router-dom";
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import addIcon from "../../../assets/img/plus.svg";
import AddAttachmentModal from "./AddAttachmentModal";

const EditAttachment = ({
  open,
  close,
  bookingId,
  attachmentData,
  linkData,
}) => {
  const [attachmentModel, setAttachmentModel] = useState(false);
  const [attachmentType, setAttachmentType] = useState("");
  const [attachArr, setattachArr] = useState(attachmentData);
  const [linkArr, setlinkArr] = useState(linkData);

  const userId = localStorage.getItem("userId");

  let initialValues = {
    // hours: pkgBkgDtl ? pkgBkgDtl?.add_hours : 0,
    // rate: pkgBkgDtl ? pkgBkgDtl?.hour_rate : 0,
  };
  const validationSchema = Yup.object().shape({
    packageId: Yup.string().required("Please select package!"),
  });
  const deleteAttachment = async (index, type) => {
    if (window.confirm("Do you want to delete this item ? ")) {
      if (type == "Attachment") {
        const filtered = attachArr.filter((item, index_1) => index_1 !== index);
        setattachArr(filtered, deleteAttachment_2(type, filtered));
      } else if (type == "Links") {
        const filtered = linkArr.filter((item, index_1) => index_1 !== index);
        setlinkArr(filtered, deleteAttachment_2(type, filtered));
      }
    }
  };
  const deleteAttachment_2 = async (type, updateArr) => {
    let data;
    if (type == "Attachment") {
      let attachArr_1 = [];
      for (let att of updateArr) {
        attachArr_1.push({
          name: att.name,
          attachment: att.attachment,
          detail: att.detail,
        });
      }
      data = { attachment: attachArr_1 };
    } else if (type == "Links") {
      let newLinksArr = [];
      for (let att of updateArr) {
        newLinksArr.push({
          name: att.name,
          attachment: att.attachment,
          detail: att.detail,
        });
      }
      data = { link: newLinksArr };
    }
    try {
      const res = await UserService.updateBooking(bookingId, data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  const openAttachmentModel = () => {
    setAttachmentModel(true);
  };
  const closeAttachmentModel = () => {
    setAttachmentModel(false);
  };
  return (
    <>
      {attachmentModel && (
        <AddAttachmentModal
          open={openAttachmentModel}
          close={closeAttachmentModel}
          attachmentType={attachmentType}
          attachArr={attachArr}
          linkArr={linkArr}
          bookingId={bookingId}
        />
      )}
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Edit Attachment Details</h4>
            <div className="checklist-wrap">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form
                    className="add-team-member-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="add-form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Attachment Details{" "}
                              <span className="text-center">
                                <img
                                  className="ml-12"
                                  onClick={() => {
                                    openAttachmentModel();
                                    setAttachmentType("Attachment");
                                  }}
                                  src={addIcon}
                                />
                              </span>
                            </label>
                          </div>
                        </div>
                        {attachArr?.length ? (
                          attachArr?.map((attach, index_1) => (
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="file_wrap">
                                  <NavLink
                                    target="_blank"
                                    to={attach.attachment}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.6875 1.25C3.82456 1.25 3.125 1.94955 3.125 2.8125V17.1875C3.125 18.0504 3.82455 18.75 4.6875 18.75H15.3125C16.1754 18.75 16.875 18.0504 16.875 17.1875V10.625C16.875 8.89911 15.4759 7.5 13.75 7.5H12.1875C11.3246 7.5 10.625 6.80044 10.625 5.9375V4.375C10.625 2.64911 9.22589 1.25 7.5 1.25H4.6875Z"
                                        fill="#0F172A"
                                      />
                                      <path
                                        d="M10.8093 1.51325C11.4733 2.2804 11.875 3.28079 11.875 4.375V5.9375C11.875 6.11009 12.0149 6.25 12.1875 6.25H13.75C14.8442 6.25 15.8446 6.6517 16.6117 7.31567C15.8719 4.48323 13.6418 2.25315 10.8093 1.51325Z"
                                        fill="#0F172A"
                                      />
                                    </svg>{" "}
                                  </NavLink>
                                  {attach?.name}
                                  <img
                                    className="mr-absolute-0"
                                    onClick={() =>
                                      deleteAttachment(index_1, "Attachment")
                                    }
                                    src={deleteIcon}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className="text-danger text-center">
                            No attachment found.
                          </span>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Link Details
                              <span className="text-center">
                                <img
                                  className="ml-12"
                                  onClick={() => {
                                    openAttachmentModel();
                                    setAttachmentType("Links");
                                  }}
                                  src={addIcon}
                                />
                              </span>
                            </label>
                          </div>
                        </div>

                        {linkArr?.length ? (
                          linkArr?.map((links, index_1) => (
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="file_wrap">
                                  <NavLink target="_blank" to={links.link}>
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.5847 3.41529C15.3643 2.1949 13.3857 2.1949 12.1653 3.41529L8.41529 7.16529C7.1949 8.38568 7.1949 10.3643 8.41529 11.5847C8.67435 11.8438 8.96648 12.0471 9.27752 12.1957C9.58899 12.3445 9.72086 12.7176 9.57207 13.0291C9.42328 13.3405 9.05018 13.4724 8.73871 13.3236C8.30141 13.1147 7.89219 12.8294 7.53141 12.4686C5.82286 10.76 5.82286 7.98995 7.53141 6.28141L11.2814 2.53141C12.99 0.822864 15.76 0.822864 17.4686 2.53141C19.1771 4.23995 19.1771 7.01005 17.4686 8.71859L16.0045 10.1827C15.7604 10.4268 15.3646 10.4268 15.1206 10.1827C14.8765 9.93866 14.8765 9.54293 15.1206 9.29885L16.5847 7.83471C17.8051 6.61432 17.8051 4.63568 16.5847 3.41529ZM10.4279 6.97095C10.5767 6.65948 10.9498 6.52761 11.2613 6.67639C11.6986 6.8853 12.1078 7.17062 12.4686 7.53141C14.1771 9.23995 14.1771 12.01 12.4686 13.7186L8.71859 17.4686C7.01005 19.1771 4.23995 19.1771 2.53141 17.4686C0.822864 15.76 0.822864 12.99 2.53141 11.2814L3.99555 9.81727C4.23963 9.57319 4.63536 9.57319 4.87943 9.81727C5.12351 10.0613 5.12351 10.4571 4.87943 10.7011L3.41529 12.1653C2.1949 13.3857 2.1949 15.3643 3.41529 16.5847C4.63568 17.8051 6.61432 17.8051 7.83471 16.5847L11.5847 12.8347C12.8051 11.6143 12.8051 9.63568 11.5847 8.41529C11.3257 8.15623 11.0335 7.95289 10.7225 7.80431C10.411 7.65552 10.2791 7.28241 10.4279 6.97095Z"
                                        fill="#0F172A"
                                      />
                                    </svg>
                                    {links.name}{" "}
                                    <p className="fst-italic m-0">
                                      ( {links.detail})
                                    </p>
                                  </NavLink>
                                  <img
                                    className="mr-absolute-0"
                                    onClick={() =>
                                      deleteAttachment(index_1, "Links")
                                    }
                                    src={deleteIcon}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className="text-danger text-center">
                            No link found.
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditAttachment;
