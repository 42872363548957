import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../services/UserService";
import TemplateModal from "./TemplateModal";

const ResponseModal = ({ open, close, inquiryId, type }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [addResponse, setAddResponse] = useState(false);
  const getTemplateList = async () => {
    try {
      let res = await UserService.getTemplateList();
      if (res?.data?.status === 200) {
        setList(res?.data?.data?.list);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const handleClose = () => {
    setAddResponse(false);
    getTemplateList();
  };

  const handleOpen = () => {
    navigate("/settings", { state: { settingTab: "response" } }); ///setAddResponse(true);
  };

  useEffect(() => {
    getTemplateList();
  }, []);
  return (
    <>
      <ToastContainer />
      {addResponse === true && (
        <TemplateModal open={handleOpen} close={handleClose} />
      )}
      <Modal
        show={open}
        onHide={close}
        className="modal fade customModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close mt-1 me-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Select Response</h4>
            <div className="contract_list">
              {list?.length > 0
                ? list?.map((item, index) => (
                    <div className="single-contract" key={index}>
                      <NavLink
                        to={`/inquiries/inquiry-detail/send-response/${inquiryId}`}
                        state={item}
                      >
                        <div className="contract_ic">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.848 2.771C7.21613 2.4234 9.60649 2.24927 12 2.25C14.43 2.25 16.817 2.428 19.152 2.77C21.13 3.062 22.5 4.794 22.5 6.74V12.76C22.5 14.706 21.13 16.438 19.152 16.73C17.9983 16.8991 16.8389 17.0268 15.676 17.113C15.6168 17.1169 15.5593 17.1342 15.5079 17.1637C15.4564 17.1931 15.4123 17.234 15.379 17.283L12.624 21.416C12.5555 21.5187 12.4627 21.6029 12.3539 21.6612C12.245 21.7194 12.1235 21.7499 12 21.7499C11.8765 21.7499 11.755 21.7194 11.6461 21.6612C11.5373 21.6029 11.4445 21.5187 11.376 21.416L8.621 17.283C8.58768 17.234 8.54361 17.1931 8.49214 17.1637C8.44068 17.1342 8.38317 17.1169 8.324 17.113C7.16113 17.0265 6.00172 16.8984 4.848 16.729C2.87 16.439 1.5 14.705 1.5 12.759V6.741C1.5 4.795 2.87 3.061 4.848 2.771ZM6.75 8.25C6.75 8.05109 6.82902 7.86032 6.96967 7.71967C7.11032 7.57902 7.30109 7.5 7.5 7.5H16.5C16.6989 7.5 16.8897 7.57902 17.0303 7.71967C17.171 7.86032 17.25 8.05109 17.25 8.25C17.25 8.44891 17.171 8.63968 17.0303 8.78033C16.8897 8.92098 16.6989 9 16.5 9H7.5C7.30109 9 7.11032 8.92098 6.96967 8.78033C6.82902 8.63968 6.75 8.44891 6.75 8.25ZM7.5 10.5C7.30109 10.5 7.11032 10.579 6.96967 10.7197C6.82902 10.8603 6.75 11.0511 6.75 11.25C6.75 11.4489 6.82902 11.6397 6.96967 11.7803C7.11032 11.921 7.30109 12 7.5 12H12C12.1989 12 12.3897 11.921 12.5303 11.7803C12.671 11.6397 12.75 11.4489 12.75 11.25C12.75 11.0511 12.671 10.8603 12.5303 10.7197C12.3897 10.579 12.1989 10.5 12 10.5H7.5Z"
                              fill="#FE5F55"
                            />
                          </svg>
                        </div>
                        <div className="contact_cont">
                          <h5>{item?.subject}</h5>
                        </div>
                      </NavLink>
                    </div>
                  ))
                : "Please add a Response!"}

              <div className="single-contract">
                <NavLink
                  to={`/inquiries/inquiry-detail/send-response/${inquiryId}`}
                  //  state={""}
                >
                  <div className="contract_ic">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM10.75 7C10.75 6.58579 10.4142 6.25 10 6.25C9.58579 6.25 9.25 6.58579 9.25 7V9.25H7C6.58579 9.25 6.25 9.58579 6.25 10C6.25 10.4142 6.58579 10.75 7 10.75H9.25V13C9.25 13.4142 9.58579 13.75 10 13.75C10.4142 13.75 10.75 13.4142 10.75 13V10.75H13C13.4142 10.75 13.75 10.4142 13.75 10C13.75 9.58579 13.4142 9.25 13 9.25H10.75V7Z"
                        fill="#2E1885"
                      />
                    </svg>
                  </div>
                  <div className="contact_cont">
                    <h5>New Blank Message</h5>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResponseModal;
