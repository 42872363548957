import * as Yup from "yup";
import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import UserService from "../../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import loaderGif from "../../../assets/img/loading.gif";

const Edit = ({ open, close, bookingId, clientData }) => {
  const initialValues = {
    firstName: clientData ? clientData?.first_name : "",
    lastName: clientData ? clientData?.last_name : "",
    email: clientData ? clientData?.email : "",
    mobileNumber: clientData ? clientData?.mobileNumber : "",
    address: clientData ? clientData?.address : "",
    city: clientData ? clientData?.city : "",
    state: clientData ? clientData?.state : "",
    country: clientData ? clientData?.country : "",
    postalCode: clientData ? clientData?.postalCode : "",
  };
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required("First Name is required!"),
    lastName: Yup.string().max(255).required("Last Name is required!"),
    email: Yup.string().email().max(255).required("Email is required").trim(),
    mobileNumber: Yup.string().max(12).required("Mobile No. is required!"),
    address: Yup.string().max(255).required("Address is required!"),
    city: Yup.string().max(255).required("City is required!"),
    state: Yup.string().max(255).required("State is required!"),
    country: Yup.string().max(255).required("Country is required!"),
    postalCode: Yup.string().max(6).required("Postal Code is required!"),
  });

  const onSubmit = async (values) => {
    setButtonDisabled(true)
    const data = {
      first_name: values ? values?.firstName : "",
      last_name: values?.lastName.toString(),
      email: values?.email.toString(),
      mobileNumber: values?.mobileNumber.toString(),
      address: values?.address.toString(),
      city: values?.city.toString(),
      state: values?.state.toString(),
      country: values?.country.toString(),
      postalCode: values?.postalCode.toString(),
    };
    console.log(data);
    try {
      const res = await UserService.updateBooking(bookingId, data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          close();
        }, 400);
        setButtonDisabled(false)

      } else {
        setButtonDisabled(false)
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      setButtonDisabled(false)
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  console.log("clientData", clientData);

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Edit Client Details</h4>
            <div className="checklist-wrap">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                  <form
                    className="add-team-member-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="add-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              name="firstName"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="firstName"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              name="lastName"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="lastName"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              name="email"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="email"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Mobile No.</label>
                            <input
                              type="number"
                              name="mobileNumber"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobileNumber}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="mobileNumber"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Address</label>
                            <input
                              type="text"
                              name="address"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="address"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                            />
                            <p className="error-msg">
                              <ErrorMessage className="error-msg" name="city" />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>State</label>
                            <input
                              type="text"
                              name="state"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.state}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="state"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Country</label>
                            <input
                              type="text"
                              name="country"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="country"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Postal Code</label>
                            <input
                              type="text"
                              name="postalCode"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.postalCode}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="postalCode"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="add-new">
                          <button
                            type="submit"
                            disabled={isButtonDisabled}
                            className="btn btn_primary w-100"
                          >
                            Update{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Edit;
