import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo.png";
import Feedback from "../assets/img/feedback.png";
import userImg from "../assets/img/user.png";
import logoutImg from "../assets/img/logout.svg";
import bookingImg from "../assets/img/booking.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../services/UserService";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [photographerData, setPhotographerData] = useState({});
  const id = localStorage.getItem("userId");
  // console.log(pathname);
  const getPhotographer = async () => {
    try {
      const res = await UserService.getPhotographer(id);
      if (res?.data?.success === true) {
        setPhotographerData(res?.data?.result);
        
        if(res?.data?.result.is_delete) {
          localStorage.removeItem("userToken");
          setTimeout(() => {
            navigate("/login");
          }, 800);
        }
      } 
    } catch (error) {
      // if (error?.response?.data?.status === 500) {
        toast.info("Logged Out", { autoClose: 1000 });
        localStorage.removeItem("userToken");
        setTimeout(() => {
          navigate("/login");
        }, 800);
      // }
      logOut()
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const logOut = () => {
    toast.info("Logged out Successfully!", { autoClose: 2000 });
    setTimeout(() => {
      localStorage.removeItem("userToken");
      navigate("/login");
    }, 2000);
  };
  useEffect(() => {
    getPhotographer();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="fm-panel">
        <div className="panel-sidebar">
          <div className="sidebar-top">
            <div className="logo-sidebar">
              <img src={logo} alt="Logo" />
            </div>
            <div className="sidebar-menu">
              <div className="my-account-wrap">
                <div className="user-img">
                  <img
                    className="w-100 h-100"
                    src={
                      (photographerData?.companyLogo !== null ||
                        photographerData?.companyLogo !== undefined ||
                        photographerData?.companyLogo !== " ") &&
                      photographerData?.companyLogo?.length > 3
                        ? photographerData?.companyLogo
                        : userImg
                    }
                    alt="photographer-logo"
                  />
                </div>
                <div className="user-cont">
                  <h5>
                    {photographerData?.firstName} {photographerData?.lastName}
                  </h5>
                  <NavLink to={"/settings"} onClick={() => logOut()}>
                    Logout
                  </NavLink>
                </div>
              </div>
              <ul>
                <li>
                  <NavLink to="/" className={pathname === "/" ? "active" : ""}>
                    <span className="menu-ic">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.875 10L9.33709 2.53792C9.7032 2.17181 10.2968 2.17181 10.6629 2.53792L18.125 10M3.75 8.12501V16.5625C3.75 17.0803 4.16973 17.5 4.6875 17.5H8.125V13.4375C8.125 12.9197 8.54473 12.5 9.0625 12.5H10.9375C11.4553 12.5 11.875 12.9197 11.875 13.4375V17.5H15.3125C15.8303 17.5 16.25 17.0803 16.25 16.5625V8.12501M6.875 17.5H13.75"
                          stroke="#2E1885"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="menu">Overview</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/inquiries"
                    className={pathname === "/inquiries" ? "active" : ""}
                    title="Inquiries"
                  >
                    <span className="menu-ic">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.5 3.125H5.75983C4.93669 3.125 4.20981 3.66185 3.96774 4.44859L1.95791 10.9805C1.90295 11.1592 1.875 11.345 1.875 11.5319V15C1.875 16.0355 2.71447 16.875 3.75 16.875H16.25C17.2855 16.875 18.125 16.0355 18.125 15V11.5319C18.125 11.345 18.0971 11.1592 18.0421 10.9805L16.0323 4.44859C15.7902 3.66185 15.0633 3.125 14.2402 3.125H12.5M1.875 11.25H5.09119C5.80138 11.25 6.45063 11.6513 6.76824 12.2865L6.98176 12.7135C7.29937 13.3487 7.94862 13.75 8.65881 13.75H11.3412C12.0514 13.75 12.7006 13.3487 13.0182 12.7135L13.2318 12.2865C13.5494 11.6513 14.1986 11.25 14.9088 11.25H18.125M10 2.5V9.375M10 9.375L7.5 6.875M10 9.375L12.5 6.875"
                          stroke="#0F172A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="menu">Inquiries</span>
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink
                    to="/bookings"
                    title="Bookings"
                    className={pathname === "/bookings" ? "active" : ""}
                  >
                    <img
                      src={bookingImg}
                      alt="booking-photographer"
                      title="Bookings"
                      className="menu-ic"
                    />
                    <span className="menu">Bookings</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/projects"
                    className={pathname === "/projects" ? "active" : ""}
                    title="Projects"
                  >
                    <span className="menu-ic">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.875 10.625V10C1.875 8.96447 2.71447 8.125 3.75 8.125H16.25C17.2855 8.125 18.125 8.96447 18.125 10V10.625M10.8839 5.25888L9.11612 3.49112C8.8817 3.2567 8.56375 3.125 8.23223 3.125H3.75C2.71447 3.125 1.875 3.96447 1.875 5V15C1.875 16.0355 2.71447 16.875 3.75 16.875H16.25C17.2855 16.875 18.125 16.0355 18.125 15V7.5C18.125 6.46447 17.2855 5.625 16.25 5.625H11.7678C11.4362 5.625 11.1183 5.4933 10.8839 5.25888Z"
                          stroke="#22282E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="menu">Projects</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/teams"
                    className={pathname === "/teams" ? "active" : ""}
                    title="Team"
                  >
                    <span className="menu-ic">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5 15.9396C13.1941 16.1417 13.9281 16.25 14.6875 16.25C15.9198 16.25 17.0854 15.9647 18.122 15.4565C18.124 15.4088 18.125 15.3607 18.125 15.3125C18.125 13.414 16.5859 11.875 14.6875 11.875C13.5058 11.875 12.4633 12.4713 11.8446 13.3793M12.5 15.9396V15.9375C12.5 15.0101 12.2624 14.1382 11.8446 13.3793M12.5 15.9396C12.5 15.9693 12.4997 15.999 12.4992 16.0286C10.9478 16.9627 9.1304 17.5 7.1875 17.5C5.2446 17.5 3.4272 16.9627 1.87577 16.0286C1.87526 15.9983 1.875 15.9679 1.875 15.9375C1.875 13.0035 4.25349 10.625 7.1875 10.625C9.1941 10.625 10.9409 11.7375 11.8446 13.3793M10 5.3125C10 6.8658 8.7408 8.125 7.1875 8.125C5.6342 8.125 4.375 6.8658 4.375 5.3125C4.375 3.7592 5.6342 2.5 7.1875 2.5C8.7408 2.5 10 3.7592 10 5.3125ZM16.875 7.1875C16.875 8.39562 15.8956 9.375 14.6875 9.375C13.4794 9.375 12.5 8.39562 12.5 7.1875C12.5 5.97938 13.4794 5 14.6875 5C15.8956 5 16.875 5.97938 16.875 7.1875Z"
                          stroke="#22282E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="menu">Team</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/notification"
                    className={pathname === "/notification" ? "active" : ""}
                    title="Notification"
                  >
                    <span className="menu-ic">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.8966 10.0617V8.72414H13.8621V10.2759C13.8621 10.413 13.9166 10.5446 14.0136 10.6416L15.4138 12.0417V12.8621H4.03448V12.0417L5.43465 10.6416C5.53166 10.5446 5.58618 10.413 5.58621 10.2759V8.2069C5.58477 7.48007 5.77516 6.76573 6.13815 6.13603C6.50114 5.50633 7.02388 4.98357 7.65357 4.62056C8.28325 4.25754 8.99759 4.06713 9.72442 4.06854C10.4512 4.06996 11.1648 4.26316 11.7931 4.62862V3.47259C11.3007 3.25458 10.7771 3.11563 10.2414 3.06086V2H9.2069V3.06034C7.93168 3.19014 6.74988 3.78816 5.89002 4.73878C5.03016 5.6894 4.55333 6.92509 4.55172 8.2069V10.0617L3.15155 11.4619C3.05454 11.5589 3.00003 11.6904 3 11.8276V13.3793C3 13.5165 3.05449 13.6481 3.1515 13.7451C3.2485 13.8421 3.38006 13.8966 3.51724 13.8966H7.13793V14.4138C7.13793 15.0997 7.41041 15.7575 7.89541 16.2425C8.38042 16.7275 9.03823 17 9.72414 17C10.41 17 11.0679 16.7275 11.5529 16.2425C12.0379 15.7575 12.3103 15.0997 12.3103 14.4138V13.8966H15.931C16.0682 13.8966 16.1998 13.8421 16.2968 13.7451C16.3938 13.6481 16.4483 13.5165 16.4483 13.3793V11.8276C16.4482 11.6904 16.3937 11.5589 16.2967 11.4619L14.8966 10.0617ZM11.2759 14.4138C11.2759 14.8253 11.1124 15.22 10.8214 15.511C10.5304 15.802 10.1357 15.9655 9.72414 15.9655C9.31259 15.9655 8.91791 15.802 8.6269 15.511C8.3359 15.22 8.17241 14.8253 8.17241 14.4138V13.8966H11.2759V14.4138Z"
                          fill="#22282E"
                          stroke="#22282E"
                          strokeWidth="0.25"
                        />
                        <path
                          d="M14.8971 7.68871C16.0397 7.68871 16.9661 6.7624 16.9661 5.61975C16.9661 4.47709 16.0397 3.55078 14.8971 3.55078C13.7544 3.55078 12.8281 4.47709 12.8281 5.61975C12.8281 6.7624 13.7544 7.68871 14.8971 7.68871Z"
                          fill="#22282E"
                        />
                      </svg>
                      {/* {photographerData.notifications_count} */}
                    </span>
                    <span className="menu">Notification({photographerData?.notifications_count})</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings"
                    className={pathname === "/settings" ? "active" : ""}
                    title="Settings"
                  >
                    <span className="menu-ic">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.61895 3.28338C8.69429 2.83132 9.08541 2.5 9.54369 2.5H10.4553C10.9136 2.5 11.3047 2.83132 11.3801 3.28338L11.5042 4.02822C11.5631 4.38178 11.8239 4.66532 12.155 4.80276C12.4861 4.94027 12.8664 4.9219 13.1582 4.71348L13.7729 4.27445C14.1458 4.00807 14.6566 4.05035 14.9807 4.37441L15.6253 5.01903C15.9494 5.34309 15.9916 5.85393 15.7253 6.22686L15.2861 6.84172C15.0777 7.13343 15.0593 7.51361 15.1968 7.84469C15.3342 8.17565 15.6177 8.43636 15.9712 8.49527L16.7161 8.61944C17.1682 8.69478 17.4995 9.0859 17.4995 9.54418V10.4558C17.4995 10.9141 17.1682 11.3052 16.7161 11.3806L15.9713 11.5047C15.6177 11.5636 15.3342 11.8244 15.1968 12.1554C15.0593 12.4866 15.0776 12.8669 15.286 13.1587L15.725 13.7732C15.9914 14.1461 15.9491 14.657 15.625 14.981L14.9804 15.6256C14.6563 15.9497 14.1455 15.992 13.7726 15.7256L13.1579 15.2866C12.8662 15.0782 12.486 15.0598 12.1549 15.1973C11.8239 15.3347 11.5631 15.6182 11.5042 15.9717L11.3801 16.7166C11.3047 17.1687 10.9136 17.5 10.4553 17.5H9.54369C9.08541 17.5 8.69429 17.1687 8.61895 16.7166L8.49481 15.9718C8.43588 15.6182 8.17511 15.3347 7.84408 15.1972C7.51291 15.0597 7.13264 15.0781 6.84085 15.2865L6.22616 15.7256C5.85324 15.992 5.3424 15.9497 5.01834 15.6256L4.37371 14.981C4.04966 14.6569 4.00738 14.1461 4.27375 13.7732L4.71294 13.1583C4.92131 12.8666 4.93968 12.4864 4.80223 12.1553C4.66482 11.8244 4.38134 11.5636 4.02786 11.5047L3.28289 11.3806C2.83084 11.3052 2.49951 10.9141 2.49951 10.4558V9.54418C2.49951 9.0859 2.83084 8.69478 3.28289 8.61944L4.02773 8.4953C4.38129 8.43637 4.66483 8.1756 4.80228 7.84456C4.93978 7.51337 4.92142 7.13308 4.71299 6.84128L4.27408 6.2268C4.0077 5.85388 4.04998 5.34304 4.37404 5.01898L5.01867 4.37435C5.34272 4.0503 5.85357 4.00802 6.22649 4.27439L6.84115 4.71344C7.13288 4.92181 7.51308 4.94018 7.84418 4.80272C8.17515 4.66531 8.43588 4.38182 8.49479 4.02833L8.61895 3.28338Z"
                          stroke="#22282E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5001 10C12.5001 11.3807 11.3808 12.5 10.0001 12.5C8.61938 12.5 7.50009 11.3807 7.50009 10C7.50009 8.61931 8.61938 7.50002 10.0001 7.50002C11.3808 7.50002 12.5001 8.61931 12.5001 10Z"
                          stroke="#22282E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="menu">Settings</span>
                  </NavLink>
                </li>
                {/* <li>
                  <a onClick={() => logOut()} title="Logout">
                    <img
                      src={logoutImg}
                      alt="Logout Image"
                      className="menu-ic"
                    />
                    <span className="menu">Logout</span>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="feedback-cls">
            <div className="feedback-img">
              <img src={Feedback} alt="" />
            </div>
            <div className="feedback-cont">
              <p>Tell us about your experience!</p>
              <NavLink
                // to={"https://flomatic.app"}
                target="_blank"
                className="btn btn_primary"
              >
                Give Feedback
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="mobile-menu">
        <div className="container">
          <div className="my-account-wrap">
            <div className="user-img">
              <img src={userImg} alt="Mariam Sheikh" />
            </div>
            <div className="user-cont">
              <h5>Mariam Sheikh</h5>
              <NavLink to="#">Manage Account</NavLink>
            </div>
          </div>
          <div className="sidebar-menu">
            <ul>
              <li>
                <NavLink to="/" className={pathname === "/" ? "active" : ""}>
                  <span className="menu-ic">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.875 10L9.33709 2.53792C9.7032 2.17181 10.2968 2.17181 10.6629 2.53792L18.125 10M3.75 8.12501V16.5625C3.75 17.0803 4.16973 17.5 4.6875 17.5H8.125V13.4375C8.125 12.9197 8.54473 12.5 9.0625 12.5H10.9375C11.4553 12.5 11.875 12.9197 11.875 13.4375V17.5H15.3125C15.8303 17.5 16.25 17.0803 16.25 16.5625V8.12501M6.875 17.5H13.75"
                        stroke="#2E1885"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="menu">Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/inbox"
                  className={pathname === "/inbox" ? "active" : ""}
                >
                  <span className="menu-ic">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 3.125H5.75983C4.93669 3.125 4.20981 3.66185 3.96774 4.44859L1.95791 10.9805C1.90295 11.1592 1.875 11.345 1.875 11.5319V15C1.875 16.0355 2.71447 16.875 3.75 16.875H16.25C17.2855 16.875 18.125 16.0355 18.125 15V11.5319C18.125 11.345 18.0971 11.1592 18.0421 10.9805L16.0323 4.44859C15.7902 3.66185 15.0633 3.125 14.2402 3.125H12.5M1.875 11.25H5.09119C5.80138 11.25 6.45063 11.6513 6.76824 12.2865L6.98176 12.7135C7.29937 13.3487 7.94862 13.75 8.65881 13.75H11.3412C12.0514 13.75 12.7006 13.3487 13.0182 12.7135L13.2318 12.2865C13.5494 11.6513 14.1986 11.25 14.9088 11.25H18.125M10 2.5V9.375M10 9.375L7.5 6.875M10 9.375L12.5 6.875"
                        stroke="#0F172A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="menu">Inbox</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="projects"
                  className={pathname === "/projects" ? "active" : ""}
                >
                  <span className="menu-ic">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.875 10.625V10C1.875 8.96447 2.71447 8.125 3.75 8.125H16.25C17.2855 8.125 18.125 8.96447 18.125 10V10.625M10.8839 5.25888L9.11612 3.49112C8.8817 3.2567 8.56375 3.125 8.23223 3.125H3.75C2.71447 3.125 1.875 3.96447 1.875 5V15C1.875 16.0355 2.71447 16.875 3.75 16.875H16.25C17.2855 16.875 18.125 16.0355 18.125 15V7.5C18.125 6.46447 17.2855 5.625 16.25 5.625H11.7678C11.4362 5.625 11.1183 5.4933 10.8839 5.25888Z"
                        stroke="#22282E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="menu">Projects</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/teams"
                  className={pathname === "/teams" ? "active" : ""}
                >
                  <span className="menu-ic">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 15.9396C13.1941 16.1417 13.9281 16.25 14.6875 16.25C15.9198 16.25 17.0854 15.9647 18.122 15.4565C18.124 15.4088 18.125 15.3607 18.125 15.3125C18.125 13.414 16.5859 11.875 14.6875 11.875C13.5058 11.875 12.4633 12.4713 11.8446 13.3793M12.5 15.9396V15.9375C12.5 15.0101 12.2624 14.1382 11.8446 13.3793M12.5 15.9396C12.5 15.9693 12.4997 15.999 12.4992 16.0286C10.9478 16.9627 9.1304 17.5 7.1875 17.5C5.2446 17.5 3.4272 16.9627 1.87577 16.0286C1.87526 15.9983 1.875 15.9679 1.875 15.9375C1.875 13.0035 4.25349 10.625 7.1875 10.625C9.1941 10.625 10.9409 11.7375 11.8446 13.3793M10 5.3125C10 6.8658 8.7408 8.125 7.1875 8.125C5.6342 8.125 4.375 6.8658 4.375 5.3125C4.375 3.7592 5.6342 2.5 7.1875 2.5C8.7408 2.5 10 3.7592 10 5.3125ZM16.875 7.1875C16.875 8.39562 15.8956 9.375 14.6875 9.375C13.4794 9.375 12.5 8.39562 12.5 7.1875C12.5 5.97938 13.4794 5 14.6875 5C15.8956 5 16.875 5.97938 16.875 7.1875Z"
                        stroke="#22282E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="menu">Team</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className={pathname === "/projects" ? "active" : ""}
                >
                  <span className="menu-ic">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.61895 3.28338C8.69429 2.83132 9.08541 2.5 9.54369 2.5H10.4553C10.9136 2.5 11.3047 2.83132 11.3801 3.28338L11.5042 4.02822C11.5631 4.38178 11.8239 4.66532 12.155 4.80276C12.4861 4.94027 12.8664 4.9219 13.1582 4.71348L13.7729 4.27445C14.1458 4.00807 14.6566 4.05035 14.9807 4.37441L15.6253 5.01903C15.9494 5.34309 15.9916 5.85393 15.7253 6.22686L15.2861 6.84172C15.0777 7.13343 15.0593 7.51361 15.1968 7.84469C15.3342 8.17565 15.6177 8.43636 15.9712 8.49527L16.7161 8.61944C17.1682 8.69478 17.4995 9.0859 17.4995 9.54418V10.4558C17.4995 10.9141 17.1682 11.3052 16.7161 11.3806L15.9713 11.5047C15.6177 11.5636 15.3342 11.8244 15.1968 12.1554C15.0593 12.4866 15.0776 12.8669 15.286 13.1587L15.725 13.7732C15.9914 14.1461 15.9491 14.657 15.625 14.981L14.9804 15.6256C14.6563 15.9497 14.1455 15.992 13.7726 15.7256L13.1579 15.2866C12.8662 15.0782 12.486 15.0598 12.1549 15.1973C11.8239 15.3347 11.5631 15.6182 11.5042 15.9717L11.3801 16.7166C11.3047 17.1687 10.9136 17.5 10.4553 17.5H9.54369C9.08541 17.5 8.69429 17.1687 8.61895 16.7166L8.49481 15.9718C8.43588 15.6182 8.17511 15.3347 7.84408 15.1972C7.51291 15.0597 7.13264 15.0781 6.84085 15.2865L6.22616 15.7256C5.85324 15.992 5.3424 15.9497 5.01834 15.6256L4.37371 14.981C4.04966 14.6569 4.00738 14.1461 4.27375 13.7732L4.71294 13.1583C4.92131 12.8666 4.93968 12.4864 4.80223 12.1553C4.66482 11.8244 4.38134 11.5636 4.02786 11.5047L3.28289 11.3806C2.83084 11.3052 2.49951 10.9141 2.49951 10.4558V9.54418C2.49951 9.0859 2.83084 8.69478 3.28289 8.61944L4.02773 8.4953C4.38129 8.43637 4.66483 8.1756 4.80228 7.84456C4.93978 7.51337 4.92142 7.13308 4.71299 6.84128L4.27408 6.2268C4.0077 5.85388 4.04998 5.34304 4.37404 5.01898L5.01867 4.37435C5.34272 4.0503 5.85357 4.00802 6.22649 4.27439L6.84115 4.71344C7.13288 4.92181 7.51308 4.94018 7.84418 4.80272C8.17515 4.66531 8.43588 4.38182 8.49479 4.02833L8.61895 3.28338Z"
                        stroke="#22282E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5001 10C12.5001 11.3807 11.3808 12.5 10.0001 12.5C8.61938 12.5 7.50009 11.3807 7.50009 10C7.50009 8.61931 8.61938 7.50002 10.0001 7.50002C11.3808 7.50002 12.5001 8.61931 12.5001 10Z"
                        stroke="#22282E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="menu">Settings</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="#" onClick={() => logOut()}>
                  <span className="menu-ic">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.61895 3.28338C8.69429 2.83132 9.08541 2.5 9.54369 2.5H10.4553C10.9136 2.5 11.3047 2.83132 11.3801 3.28338L11.5042 4.02822C11.5631 4.38178 11.8239 4.66532 12.155 4.80276C12.4861 4.94027 12.8664 4.9219 13.1582 4.71348L13.7729 4.27445C14.1458 4.00807 14.6566 4.05035 14.9807 4.37441L15.6253 5.01903C15.9494 5.34309 15.9916 5.85393 15.7253 6.22686L15.2861 6.84172C15.0777 7.13343 15.0593 7.51361 15.1968 7.84469C15.3342 8.17565 15.6177 8.43636 15.9712 8.49527L16.7161 8.61944C17.1682 8.69478 17.4995 9.0859 17.4995 9.54418V10.4558C17.4995 10.9141 17.1682 11.3052 16.7161 11.3806L15.9713 11.5047C15.6177 11.5636 15.3342 11.8244 15.1968 12.1554C15.0593 12.4866 15.0776 12.8669 15.286 13.1587L15.725 13.7732C15.9914 14.1461 15.9491 14.657 15.625 14.981L14.9804 15.6256C14.6563 15.9497 14.1455 15.992 13.7726 15.7256L13.1579 15.2866C12.8662 15.0782 12.486 15.0598 12.1549 15.1973C11.8239 15.3347 11.5631 15.6182 11.5042 15.9717L11.3801 16.7166C11.3047 17.1687 10.9136 17.5 10.4553 17.5H9.54369C9.08541 17.5 8.69429 17.1687 8.61895 16.7166L8.49481 15.9718C8.43588 15.6182 8.17511 15.3347 7.84408 15.1972C7.51291 15.0597 7.13264 15.0781 6.84085 15.2865L6.22616 15.7256C5.85324 15.992 5.3424 15.9497 5.01834 15.6256L4.37371 14.981C4.04966 14.6569 4.00738 14.1461 4.27375 13.7732L4.71294 13.1583C4.92131 12.8666 4.93968 12.4864 4.80223 12.1553C4.66482 11.8244 4.38134 11.5636 4.02786 11.5047L3.28289 11.3806C2.83084 11.3052 2.49951 10.9141 2.49951 10.4558V9.54418C2.49951 9.0859 2.83084 8.69478 3.28289 8.61944L4.02773 8.4953C4.38129 8.43637 4.66483 8.1756 4.80228 7.84456C4.93978 7.51337 4.92142 7.13308 4.71299 6.84128L4.27408 6.2268C4.0077 5.85388 4.04998 5.34304 4.37404 5.01898L5.01867 4.37435C5.34272 4.0503 5.85357 4.00802 6.22649 4.27439L6.84115 4.71344C7.13288 4.92181 7.51308 4.94018 7.84418 4.80272C8.17515 4.66531 8.43588 4.38182 8.49479 4.02833L8.61895 3.28338Z"
                        stroke="#22282E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5001 10C12.5001 11.3807 11.3808 12.5 10.0001 12.5C8.61938 12.5 7.50009 11.3807 7.50009 10C7.50009 8.61931 8.61938 7.50002 10.0001 7.50002C11.3808 7.50002 12.5001 8.61931 12.5001 10Z"
                        stroke="#22282E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="menu">Logout</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
