import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import UserService from "../../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import loaderGif from "../../../assets/img/loading.gif";

const EditDueDate = ({ open, close, bookingId, dueDate,refresh }) => {
  const [timeData, setTimeData] = useState();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const initialValues = {
    due_date:  moment(dueDate).format("YYYY-MM-DD") 
  };

  const validationSchema = Yup.object().shape({
    due_date: Yup.string().required("Date is required")
  });

  const getTimeFormat = async () => {
    try {
      let res = await UserService.getSettings();
      console.log(res?.data?.result);
      setTimeData(res?.data?.result);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const onSubmit = async (values) => {
    console.log(values, 3444)
    setButtonDisabled(true)
    const data = {
        dueDate: values.due_date
    };
    try {
      const res = await UserService.updateProjectStatus(bookingId, data);
      console.log("res", res?.data);
      if (res?.data?.status === 200) {
        toast.success("Due date updated", { autoClose: 2000 });
        setTimeout(() => {
          close();
        }, 400);
        refresh()
        console.log(refresh(), 48888)
        setButtonDisabled(false)
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
      setButtonDisabled(false)
    }
  };
  useEffect(() => {
    getTimeFormat();
    console.log()
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
show={open}
hide={close}
className="modal fade checklist-modal customModal"
id="exampleModal"
tabIndex="-1"
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-header">
  <button
    type="button"
    className="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    onClick={() => close()}
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 13L1 1M13 1L1 13"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </button>
</div>
<div className="modal-body">
  <div className="contract_modal">
    <h4>Edit Due Date</h4>
    <div className="checklist-wrap">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form
            className="add-team-member-form"
            onSubmit={handleSubmit}
          >
            <div className="add-form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Due Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="due_date"
                      autoComplete="off"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.due_date}
                    />
                    <p className="error-msg">
                      <ErrorMessage className="error-msg" name="date" />
                    </p>
                  </div>
                </div>
               
                <div className="add-new">
                  <button
                                              disabled={isButtonDisabled}

                    type="submit"
                    className="btn btn_primary w-100"
                  >
                    Update{" "}
                      {isButtonDisabled ? (
                        <>
                          <img height={20} width={20} src={loaderGif} />
                        </>
                      ) : null}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </div>
</div>
</Modal> 
    </>
  );
};

export default EditDueDate;