import React, { useEffect, useState, useRef } from "react";
import loaderGif from "../../assets/img/loading.gif";
import DeleteIcon from "../../assets/img/plus.svg";
import Minus from "../../assets/img/minus.svg";
import Dot from "../../assets/img/dot.svg";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  FormSelect,
  Dropdown,
} from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import FormGenrateModal from "../../components/FormGenrateModal";
import Edit from "./response-template/Edit";
import ResponseView from "./response-template/View";
import ContractView from "./contract-template/View";
import View from "./pricing/View";
import AddCategory from "./project-setting/AddCategory";
import EditCategory from "./project-setting/EditCategory";
import AddRole from "./team-setting/AddRole";
import EditRole from "./team-setting/EditRole";
import ManageAccount from "./manage-account/ManageAccount";
// import loaderGif from "../../assets/img/loading.gif";

const Settings = () => {
  const paragraphRef = useRef(null);
  const paragraphRef_1 = useRef(null);
  const [addCategory, setAddCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [addRole, setAddRole] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [deleteRole, setDeleteRole] = useState(false);

  const [list, setList] = useState();
  const [roleList, setRoleList] = useState();
  const [isActive, setIsActive] = useState(true);
  const [openFormGenrateModal, setOpenFormGenrateModal] = useState(false);
  const [editResponse, setEditResponse] = useState(false);
  const [enquiryUrl, setEnquiryUrl] = useState("");
  const [formArr, setFormArr] = useState([]);
  const [newFormArr, setNewFormArr] = useState([]);
  const [roles, setRoles] = useState([]);
  const [categoryList, setCategoryList] = useState();
  const userId = localStorage.getItem("userId");
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [settingData, setsettingData] = useState({
    time_format: "",
    due_date_1: "",
    due_date_2: "",
    hours_rate: 0,
    enable_packages: false,
    booking_notification: false,
    enquiry_notification: false,
    signedContract_notification: false,
    teamMember_notification: false,
    errors: {},
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  //Form 0
  const addForm_0_Fields = async () => {
    const newValue = {
      form_id: "",
      label: "",
      type: "",
      answer_type: "",
      place_order: "",
      options: [],
      isSelected: false,
      disabled: false,
      readOnly: false,
      deletable: true,
    };

    setFormArr((prevArray) => [...prevArray, newValue]);
    // setNewFormArr(newValue)
  };

  const reomveForm_0_Fields = async (index) => {
    const updatedItems = [...formArr]; // Remove the existing index data of the array
    const lastOption = updatedItems?.filter((_, id) => id !== index);
    console.log(lastOption, "lastoption++++++++")
    setFormArr(lastOption);
  };

  const handleAddField = (index) => {
    // Add an empty string to the inputFields array
    const updatedItems = [...formArr]; // Create a new copy of the array
    const lastOption = updatedItems[index].options;
    const newValue = [{ option: "" }];
    lastOption.push(newValue);
    updatedItems[index].options = lastOption; // Update the desired item
    setFormArr(updatedItems);
  };

  const handleRemoveField = (index, idx) => {
    const updatedItems = [...formArr]; // Remove the existing index data of the array
    const lastOption = updatedItems[index]?.options;
    const updatedFields = lastOption.filter((_, id) => id !== idx);
    updatedItems[index].options = updatedFields; // Update the desired item
    setFormArr(updatedItems);
  };

  const formInputChange = (e, index) => {
   
    const { name, value } = e.target;
    const labelExists = formArr.some((item, i) => i !== index && item.label === value);
    const placeOrderExists = formArr.some((item, i) => i !== index && item.place_order == value);
  
    if (labelExists || placeOrderExists) {
      toast.error("Check provided values! Already exists.", { autoClose: 2000 });
      return; // Prevent further processing if there's a duplicate
    }
    const updatedItems = [...formArr]; // Remove the existing index data of the array
    updatedItems[index][name] = value;
    setFormArr(updatedItems);
    if (e.target.name === "type") {
      if (e.target.value === "Selectbox") {
        const updatedItems = [...formArr]; // Create a new copy of the array
        updatedItems[index].isSelected = true; // Update the desired item
        setFormArr(updatedItems); // Set the updated array state
      } else {
        const updatedItems = [...formArr]; // Create a new copy of the array
        updatedItems[index].isSelected = false; // Update the desired item
        updatedItems[index].options = []; // Update the desired item
        setFormArr(updatedItems); // Set the updated array state
      }
    }
  };

  const handleInputChange = (e, index, idx) => {
    const updatedItems = [...formArr];
    const { name, value } = e.target;
    updatedItems[index].options[idx].options = e.target.value;
    setFormArr(updatedItems);
  };

  const getFormData = async () => {
    try {
      let res = await UserService.getCustomerForm(`${userId}`);
      if (res?.data?.status === 200) {
        let serviceOptions = [];
        if (categoryList.length > 0) {
          for (var service_1 of categoryList) {
            serviceOptions.push({ options: service_1.category_name });
          }
        }
        if (Object.keys(res?.data?.data).length === 0) {
          let formArrr_1 = [
            {
              form_id: "",
              label: "Full Name",
              type: "Textbox",
              answer_type: "Mandatory",
              place_order: "1",
              options: [],
              isSelected: false,
              disabled: true,
              readOnly: true,
              deletable: false,
            },
            {
              form_id: "",
              label: "Email",
              type: "Textbox",
              answer_type: "Mandatory",
              place_order: "2",
              options: [],
              isSelected: false,
              disabled: true,
              readOnly: true,
              deletable: false,
            },
            {
              form_id: "",
              label: "Mobile No.",
              type: "Textbox",
              answer_type: "Mandatory",
              place_order: "3",
              options: [],
              isSelected: false,
              disabled: true,
              readOnly: true,
              deletable: false,
            },
            {
              form_id: "",
              label: "Service Type",
              type: "Selectbox",
              answer_type: "Mandatory",
              place_order: "4",
              options: serviceOptions,
              isSelected: true,
              disabled: true,
              readOnly: true,
              deletable: false,
            },
            {
              form_id: "",
              label: "Your Message",
              type: "Textarea",
              answer_type: "Mandatory",
              place_order: "5",
              options: [],
              isSelected: false,
              disabled: true,
              readOnly: true,
              deletable: false,
            },
            {
              form_id: "",
              label: "How did you find us ?",
              type: "Selectbox",
              answer_type: "Mandatory",
              place_order: "6",
              options: [
                { options: "Google" },
                { options: "Facebook" },
                { options: "LinkedIn" },
                { options: "Instagram" },
              ],
              isSelected: true,
              disabled: true,
              readOnly: true,
              deletable: false,
            },
          ];
          setFormArr(formArrr_1);
        } else {
          let completeForm_11 = [];
          if (res?.data?.data?.list?.length > 0) {
            for (let formdata of res?.data?.data?.list) {
              let makejson = {
                label: formdata.label,
                type: formdata.type,
                answer_type: formdata.answer_type,
                place_order: formdata.place_order,
                options: [],
                disabled: true,
                readOnly: true,
                deletable: formdata.deletable
              };
              if (formdata.label == "Service Type") {
                makejson.options = serviceOptions;
              } else {
                if (formdata.options.length > 0) {
                  makejson.options = [];
                  for (let opt_11 of formdata.options) {
                    makejson.options.push({ options: opt_11 });
                  }
                }
              }
              completeForm_11.push(makejson);
            }
          }
          setFormArr(completeForm_11);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const addFormData = async () => {
    setButtonDisabled(true)
    let completeForm = [];
    for (let formdata of formArr) {
      let makejson = {
        label: formdata.label,
        type: formdata.type,
        answer_type: formdata.answer_type,
        place_order: formdata.place_order,
        deletable: formdata.deletable,
      };
      if (formdata?.options?.length > 0) {
        makejson.options = [];
        for (let opt_11 of formdata.options) {
          makejson.options.push(opt_11.options);
        }
      }
      completeForm.push(makejson);
    }
    let data = {
      form: completeForm,
    };
    try {
      let res = await UserService.addForm(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        //setOpenFormGenrateModal(true);
        //setEnquiryUrl(res?.data?.data?.enquiry_url);
        getFormData()
        getPhotographer();
        setButtonDisabled(false)

      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)

      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      setButtonDisabled(false)

    }
  };
  const handleChange = (e) => {
    setsettingData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    const { name, value } = e.target;

    if (name == "due_date_1" && value > 50) {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          due_date_1: "Please enter valid due date range.",
        },
        due_date_1: "",
      }));
      setButtonDisabled(true);
      return;
    } else if (name == "due_date_2" && value.trim() == "") {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          due_date_2: "Please enter valid due date type.",
        },
        due_date_2: "",
      }));
      setButtonDisabled(true);
      return;
    } else if (name == "time_format" && value.trim() == "") {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          time_format: "Please enter valid time format.",
        },
        time_format: "",
      }));
      setButtonDisabled(true);
      return;
    } else if (name == "hours_rate" && value > 1000) {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          hours_rate: "Please enter valid hours rate",
        },
        hours_rate: 0,
      }));
      setButtonDisabled(true);
      return;
    } else {
      setButtonDisabled(false);
    }

    setsettingData((prevState) => ({
      ...prevState,
      [name]: value, // Update the desired property
    }));
  };

  const handleChecked = async (e) => {
    setsettingData((prevState) => ({
      ...prevState,
      booking_notification: e.target.checked,
      enquiry_notification: e.target.checked,
      signedContract_notification: e.target.checked,
      teamMember_notification: e.target.checked,
    }));
    let data = {
      booking_notification: e.target.checked,
      enquiry_notification: e.target.checked,
      signedContract_notification: e.target.checked,
      teamMember_notification: e.target.checked,
    };
    try {
      let res = await UserService.updateSettings(data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const handleSettingSubmit = async () => {
    console.log(settingData, 4000)
    setButtonDisabled(true);

    setsettingData((prevData) => ({
      ...prevData,
      errors: {},
    }));
    if (settingData.due_date_1 > 50) {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          due_date_1: "Please enter valid due date range.",
        },
        due_date_1: "",
      }));
      setButtonDisabled(true);
      return;
    } else if (settingData.due_date_2.trim() == "") {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          due_date_2: "Please enter valid due date type.",
        },
        due_date_2: "",
      }));
      setButtonDisabled(true);
      return;
    } else if (settingData.time_format.trim() == "") {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          time_format: "Please enter valid time format.",
        },
        time_format: "",
      }));
      setButtonDisabled(true);
      return;
    } else if ( settingData.hours_rate > 1000) {
      setsettingData((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          hours_rate: "Please enter valid hours rate",
        },
        hours_rate: 0,
      }));
      setButtonDisabled(true);
      return;
    } else {
      let data = {
        due_date: settingData.due_date_1 + " " + settingData.due_date_2,
        hours_rate: settingData.hours_rate,
        time_format: settingData.time_format,
      };
      try {
        let res = await UserService.updateSettings(data);
        if (res?.data?.status === 200) {
          setButtonDisabled(false);

          toast.success(res?.data?.message, { autoClose: 2000 });
        } else {
          setButtonDisabled(false);

          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };
  const getSettingData = async () => {
    try {
      let res = await UserService.getSettings();
      if (res?.data?.success === true) {
        let due_date = res?.data?.result?.due_date.split(" ");
        setsettingData((prevState) => ({
          ...prevState,
          due_date_1: due_date[0],
          due_date_2: due_date[1],
          time_format: res?.data?.result?.time_format,
          hours_rate: res?.data?.result?.hours_rate,
          booking_notification: res?.data?.result?.booking_notification,
          enquiry_notification: res?.data?.result?.enquiry_notification,
          signedContract_notification:
            res?.data?.result?.signedContract_notification,
          teamMember_notification: res?.data?.result?.teamMember_notification,
          enable_packages: res?.data?.result?.enable_packages,
        }));
        console.log("Settgin data", settingData);
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const onClick = async (values) => {
    const data = {
      category_name: values.name,
    };
    try {
      let res = await UserService.addJobCategory(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/settings");
        }, 1000);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const getRoles = async () => {
    try {
      let res = await UserService.getRoles();
      if (res?.data?.success === true) {
        setRoles(res?.data?.result);
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const getCategoryList = async () => {
    try {
      let res = await UserService.getCategoryList(userId);
      if (res?.data?.success === true) {
        setCategoryList(res?.data?.result);
      } 
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const getPhotographer = async () => {
    try {
      const res = await UserService.getPhotographer(userId);
      if (res?.data?.success === true) {
        setUser(res?.data?.result);
      } 
    } catch (error) {
      if (error?.response?.data?.status === 500) {
        toast.info("Logged Out", { autoClose: 1000 });
        localStorage.removeItem("userToken");
        setTimeout(() => {
          navigate("/login");
        }, 800);
      }
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getFormData();
    getPhotographer();
    getSettingData();
    getCategoryList();
    getRoles();
  }, [addCategory, editCategory, addRole, editRole]);

  // console.log("categoryList===", categoryList);

  const handleCloseEdit = () => {
    setEditResponse(false);
  };

  const handleOpenEdit = () => {
    setEditResponse(true);
  };
  const handleCloseEdit_11 = () => {
    setOpenFormGenrateModal(false);
  };

  const handleOpenEdit_111 = () => {
    setOpenFormGenrateModal(true);
  };

  const openAddCategory = () => {
    setAddCategory(true);
  };

  const closeAddCategory = () => {
    setAddCategory(false);
  };

  const openEditCategory = (catList) => {
    setList(catList);
    setEditCategory(true);
  };

  const openDeleteCategory = async (index, id) => {
    const confirmed = window.confirm("Do you want to delete?");
    if (confirmed) {
      try {
        let res = await UserService.deleteJobCategory(id);
        // list.splice(index, 1);
        if (res?.data?.status === 200) {
          getCategoryList();
          toast.success(res?.data?.message, { autoClose: 2000 });
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };

  const closeEditCategory = () => {
    setEditCategory(false);
  };

  const openAddRole = () => {
    setAddRole(true);
  };

  const closeAddRole = () => {
    setAddRole(false);
  };

  const openEditRole = (roleList) => {
    setRoleList(roleList);
    setEditRole(true);
  };
  const openDeleteRole = async (index, id) => {
    const confirmed = window.confirm("Do you want to delete?");
    if (confirmed) {
      try {
        let res = await UserService.deleteJobRole(id);
        // list.splice(index, 1);
        if (res?.data?.status === 200) {
          getRoles();
          toast.success(res?.data?.message, { autoClose: 2000 });
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };
  const closeDeleteRole = () => {
    setDeleteRole(false);
  };

  const closeEditRole = () => {
    setEditRole(false);
  };
  useEffect(() => {
    getFormData();
  }, [categoryList]);
  const copyText = () => {
    if (paragraphRef.current) {
      const range = document.createRange();
      range.selectNode(paragraphRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      toast.success("Copied", { autoClose: 2000 });
    }
  };
  const copyText_1 = () => {
    if (paragraphRef_1.current) {
      const range = document.createRange();
      range.selectNode(paragraphRef_1.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      toast.success("Copied", { autoClose: 2000 });
    }
  };
  const updatePackageSettings = async (e, fieldKey) => {
    setsettingData((prevState) => ({
      ...prevState,
      [fieldKey]: e.target.checked,
    }));

    let data = {
      [fieldKey]: e.target.checked,
    };
    try {
      let res = await UserService.updateSettings(data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  return (
    <>
      {editResponse === true && (
        <Edit open={handleOpenEdit} close={handleCloseEdit} />
      )}
      {addCategory === true && (
        <AddCategory open={openAddCategory} close={closeAddCategory} />
      )}
      {editCategory === true && (
        <EditCategory
          open={openEditCategory}
          close={closeEditCategory}
          list={list}
        />
      )}
      {addRole === true && <AddRole open={openAddRole} close={closeAddRole} />}
      {editRole === true && (
        <EditRole open={openEditRole} close={closeEditRole} list={roleList} />
      )}
      {/* {deleteRole === true && (
        <DeleteRole open={openDeleteRole} close={closeDeleteRole} list={roleList} />
      )} */}
      {openFormGenrateModal === true && (
        <FormGenrateModal
          open={handleOpenEdit_111}
          close={handleCloseEdit_11}
          enquiryUrl={enquiryUrl}
        />
      )}
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Settings</h3>
            <div className="booking-inner-wrap">
              <div className="inner-title setting-navs">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className={
                        state?.settingTab === undefined ||
                        state?.settingTab === "genral"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="nav-general-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-general"
                      type="button"
                      role="tab"
                      aria-controls="nav-general"
                      aria-selected="true"
                      state={{ settingTab: "genral" }}
                    >
                      General
                    </button>
                    <button
                      className={
                        state?.settingTab === "response"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="nav-responses-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-responses"
                      type="button"
                      role="tab"
                      aria-controls="nav-responses"
                      aria-selected="false"
                      state={{ settingTab: "response" }}
                    >
                      Response Templates
                    </button>
                    <button
                      className={
                        state?.settingTab === "contract"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="nav-contracts-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contracts"
                      type="button"
                      role="tab"
                      aria-controls="nav-contracts"
                      aria-selected="true"
                      state={{ settingTab: "contract" }}
                    >
                      Contract Templates
                    </button>
                    <button
                      className={
                        state?.settingTab === "pricing"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="nav-pricing-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-pricing"
                      type="button"
                      role="tab"
                      aria-controls="nav-pricing"
                      aria-selected="false"
                      state={{ settingTab: "pricing" }}
                    >
                      Pricing
                    </button>
                    <button
                      className={
                        state?.settingTab === "inquiry"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="nav-forms-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-forms"
                      type="button"
                      role="tab"
                      aria-controls="nav-forms"
                      aria-selected="true"
                      state={{ settingTab: "inquiry" }}
                    >
                      Inquiry Form
                    </button>
                    <button
                      className={
                        state?.settingTab === "manage"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="nav-forms-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-manage-account"
                      type="button"
                      role="tab"
                      aria-controls="nav-forms"
                      aria-selected="true"
                      state={{ settingTab: "manage" }}
                    >
                      Manage Account
                    </button>
                  </div>
                </nav>
              </div>
              <div className="tab-inner-cont">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className={
                      state?.settingTab === undefined ||
                      state?.settingTab === "genral"
                        ? "tab-pane fade active show"
                        : "tab-pane fade"
                    }
                    id="nav-general"
                    role="tabpanel"
                    aria-labelledby="nav-general-tab"
                  >
                    <div className="booking-dtl-cls space-sec">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="client-dtl setting-wrap">
                            <h4>General Settings</h4>
                            <div className="card">
                              <div className="client-info setting-inner">
                                <h5>Default Due Date</h5>
                                <p>
                                  Set the default time period a gallery is due
                                  after a project is marked as completed.
                                </p>
                                <div className="form-group">
                                  <div className="input_group">
                                    <input
                                      type="number"
                                      name="due_date_1"
                                      className="form-control"
                                      max={50}
                                      min={0}
                                      value={settingData.due_date_1}
                                      onChange={handleChange}
                                      id="due_date_1"
                                    />

                                    <select
                                      className="form-control"
                                      name="due_date_2"
                                      onChange={handleChange}
                                      id="due_date_2"
                                      value={settingData.due_date_2}
                                    >
                                      <option value={""}>Select </option>
                                      <option value={"Days"}>Days</option>
                                      <option value={"Weeks"}>Weeks</option>
                                      <option value={"Months"}>Months</option>
                                      <option value={"Years"}>Years</option>
                                    </select>
                                  </div>
                                  {settingData.errors.due_date_1 && (
                                    <p className="text-danger">
                                      {settingData.errors.due_date_1}
                                    </p>
                                  )}
                                  {settingData.errors.due_date_2 && (
                                    <p className="text-danger">
                                      {settingData.errors.due_date_2}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="client-info setting-inner">
                                <h5>Default Time Format</h5>
                                <p>Set the default time format.</p>
                                <div className="form-group">
                                  <div className="input_group">
                                    <select
                                      className="form-control"
                                      name="time_format"
                                      id="time_format"
                                      onChange={handleChange}
                                      value={settingData.time_format}
                                    >
                                      <option value={""}>Select </option>
                                      <option value={"12"}>12 Hour</option>
                                      <option value={"24"}>24 Hour</option>
                                    </select>
                                  </div>
                                  {settingData.errors.time_format && (
                                    <p className="text-danger">
                                      {settingData.errors.time_format}
                                    </p>
                                  )}
                                </div>
                              </div>
                            
                              <div className="client-info setting-inner">
                                <button
                                  type="submit"
                                  className="btn btn_primary"
                                  disabled={isButtonDisabled}
                                  onClick={handleSettingSubmit}
                                >
                                  Update{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 space-sec">
                          <div className="client-dtl setting-wrap">
                            <h4>Project Service Type</h4>
                            <div className="card">
                              <div className="client-info setting-inner">
                                <h5>Service Types</h5>
                                <p>
                                  These service type allow you to organize your
                                  projects and pricing
                                </p>
                                <div className="project-category-wrap">
                                  <ul>
                                    {categoryList?.length > 0
                                      ? categoryList?.map((list, idx) => (
                                          <li key={idx}>
                                            <h5>{list?.category_name}</h5>
                                            <div className="notifi-dot">
                                              <Dropdown className="bookingDrop">
                                                <Dropdown.Toggle>
                                                  <span>
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                                                        stroke="#0F172A"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                      <path
                                                        d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                                                        stroke="#0F172A"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                      <path
                                                        d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                                                        stroke="#0F172A"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    as={Button}
                                                    onClick={() =>
                                                      openEditCategory(
                                                        list,
                                                        list?._id
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    as={Button}
                                                    onClick={() =>
                                                      openDeleteCategory(
                                                        idx,
                                                        list?._id
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </li>
                                        ))
                                      : ""}
                                  </ul>
                                  <div className="add-category">
                                    <button
                                      className="btn btn_primary"
                                      onClick={openAddCategory}
                                    >
                                      Add Service Type
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 space-sec">
                          <div className="client-dtl setting-wrap">
                            <h4>Team Settings</h4>
                            <div className="card">
                              <div className="client-info setting-inner">
                                <h5>Team Member Roles</h5>
                                <p>
                                  Set the roles you can assign to team members
                                  for each project.
                                </p>
                                <div className="project-category-wrap">
                                  <ul>
                                    {roles?.map((role, idx) => (
                                      <li key={idx}>
                                        <h5>{role.role_name}</h5>
                                        <div className="notifi-dot">
                                          <Dropdown className="bookingDrop">
                                            <Dropdown.Toggle>
                                              <span>
                                                <svg
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                                                    stroke="#0F172A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                                                    stroke="#0F172A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                                                    stroke="#0F172A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                </svg>
                                              </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                as={Button}
                                                onClick={() =>
                                                  openEditRole(role, role?._id)
                                                }
                                              >
                                                Edit
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as={Button}
                                                onClick={() =>
                                                  openDeleteRole(idx, role?._id)
                                                }
                                              >
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                  <div className="add-category">
                                    <button
                                      className="btn btn_primary"
                                      onClick={() => openAddRole()}
                                    >
                                      Add Role
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 space-sec">
                          <div className="client-dtl setting-wrap">
                            <h4>Email Notifications</h4>
                            <div className="card">
                              <div className="notification-wrap">
                                <div className="form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="teamMember_notification"
                                  >
                                    <input
                                      name="teamMember_notification"
                                      id="teamMember_notification"
                                      type="checkbox"
                                      className="form-check-input"
                                      value={
                                        settingData?.teamMember_notification
                                      }
                                      checked={
                                        settingData?.teamMember_notification ===
                                        true
                                      }
                                      onChange={(e) =>
                                        updatePackageSettings(
                                          e,
                                          "teamMember_notification"
                                        )
                                      }
                                    />
                                    Email team members when they are assigned to
                                    a project.
                                  </label>
                                  <label
                                    className="form-check-label"
                                    htmlFor="enquiry_notification"
                                  >
                                    <input
                                      name="enquiry_notification"
                                      id="enquiry_notification"
                                      type="checkbox"
                                      className="form-check-input"
                                      value={settingData?.enquiry_notification}
                                      checked={
                                        settingData?.enquiry_notification ===
                                        true
                                      }
                                      onChange={(e) =>
                                        updatePackageSettings(
                                          e,
                                          "enquiry_notification"
                                        )
                                      }
                                    />
                                    I receive a new enquiry.
                                  </label>
                                  <label
                                    className="form-check-label"
                                    htmlFor="booking_notification"
                                  >
                                    <input
                                      name="booking_notification"
                                      id="booking_notification"
                                      type="checkbox"
                                      className="form-check-input"
                                      value={settingData?.booking_notification}
                                      checked={
                                        settingData?.booking_notification ===
                                        true
                                      }
                                      onChange={(e) =>
                                        updatePackageSettings(
                                          e,
                                          "booking_notification"
                                        )
                                      }
                                    />
                                    I receive a new booking request.
                                  </label>
                                  <label
                                    className="form-check-label"
                                    htmlFor="signedContract_notification"
                                  >
                                    <input
                                      name="signedContract_notification"
                                      id="signedContract_notification"
                                      type="checkbox"
                                      className="form-check-input"
                                      value={
                                        settingData?.signedContract_notification
                                      }
                                      checked={
                                        settingData?.signedContract_notification ===
                                        true
                                      }
                                      onChange={(e) =>
                                        updatePackageSettings(
                                          e,
                                          "signedContract_notification"
                                        )
                                      }
                                    />
                                    A customer has signed a contract.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      state?.settingTab === "response"
                        ? "tab-pane fade active show"
                        : "tab-pane fade"
                    }
                    id="nav-responses"
                    role="tabpanel"
                    aria-labelledby="nav-responses-tab"
                  >
                    <div className="booking-dtl-cls space-sec">
                      <ResponseView open={handleOpenEdit} />
                    </div>
                  </div>
                  <div
                    className={
                      state?.settingTab === "contract"
                        ? "tab-pane fade active show"
                        : "tab-pane fade"
                    }
                    id="nav-contracts"
                    role="tabpanel"
                    aria-labelledby="nav-contracts-tab"
                  >
                    <div className="booking-dtl-cls space-sec">
                      <ContractView />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-pricing"
                    role="tabpanel"
                    aria-labelledby="nav-pricing-tab"
                  >
                    <div className="booking-link-main d-flex justify-content-between mt-50">
                      <div className="booking-link">
                        <h5>Enable Packages</h5>
                        <p>
                          If you'd like to create packages for your customers to
                          select from, you can configure them here.
                        </p>
                      </div>
                      <div className="form-check form-switch form-switch-sm">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="enable_packages"
                          id="flexSwitchCheckChecked1"
                          value={settingData?.enable_packages}
                          checked={settingData?.enable_packages === true}
                          onChange={(e) =>
                            updatePackageSettings(e, "enable_packages")
                          }
                        />
                      </div>
                    </div>
                    <div className="space-sec">
                      {settingData?.enable_packages === true ? (
                        <>
                          <div className="client-info setting-inner s-cls">
                                <h5>Hour rate($)</h5>
                                <p>Set your hour rate.</p>
                                <div className="form-group">
                                  <div className="input_group">
                                    <input
                                      type="number"
                                      name="hours_rate"
                                      placeholder="100"
                                      className="form-control"
                                      max={1000}
                                      min={0}
                                      id="hours_rate"
                                      onChange={handleChange}
                                      value={settingData.hours_rate}
                                    />
                                    <div className="client-info setting-inner mb-0 ms-auto">
                                <button
                                  type="submit"
                                  className="btn btn_primary"
                                  disabled={isButtonDisabled}
                                  onClick={handleSettingSubmit}
                                >
                                  Update{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                                </button>
                              </div>
                                  </div>
                                  {settingData.errors.hours_rate && (
                                    <p className="text-danger">
                                      {settingData.errors.hours_rate}
                                    </p>
                                  )}
                                </div>
                              </div>
                              
                          <div className="inner-title">
                            <nav>
                              <div
                                className="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                              >
                                <button
                                  className="nav-link active"
                                  id="nav-packages-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-packages"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-packages"
                                  aria-selected="true"
                                  onClick={() => setIsActive(true)}
                                >
                                  Packages
                                </button>
                                <button
                                  className="nav-link"
                                  id="nav-addons-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-addons"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-addons"
                                  aria-selected="false"
                                  onClick={() => setIsActive(false)}
                                >
                                  Addons
                                </button>
                              </div>
                            </nav>
                          </div>
                          <View active={isActive} />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={
                      state?.settingTab === "inquiry"
                        ? "tab-pane fade active show"
                        : "tab-pane fade"
                    }
                    id="nav-forms"
                    role="tabpanel"
                    aria-labelledby="nav-forms-tab"
                  >
                    <div className="create-form">
                      <div className="contract-box1">
                        <h4>Inquiry Form Link</h4>
                        <div className="file_wrap">
                          <NavLink
                            target="_blank"
                            ref={paragraphRef}
                            to={user?.enquiry_url}
                          >
                            {user?.enquiry_url}
                          </NavLink>
                          <button
                            onClick={() => copyText()}
                            className="btn btn_default ms-auto"
                          >
                            Copy Link
                          </button>
                        </div>
                      </div>
                      <div className="createform-tit mt-2">
                        <h3>Edit Inquiry Form</h3>
                        <div className="add-form">
                          <Button
                            className="btn btn_primary"
                            onClick={addForm_0_Fields}
                          >
                            Add Form Field
                          </Button>
                        </div>
                      </div>
                      <Form className="create-form-main">
                        {formArr.map((item, index) => (
                          <Row key={item.id}>
                            <Col xl="5" lg="12">
                              <FormGroup className="form-group">
                                <FormLabel>Label</FormLabel>
                                <FormControl
                                  placeholder="Enter Label"
                                  onChange={(e) => formInputChange(e, index)}
                                  name="label"
                                  value={item?.label}
                                  readOnly={!item?.deletable ? true:false}
                                ></FormControl>
                              </FormGroup>
                            </Col>
                            <Col xl="2" className="col-md-3 col-sm-6">
                              <FormGroup className="form-group">
                                <FormLabel>Place Order</FormLabel>
                                <FormControl
                                  type="number"
                                  min={0}
                                  max={100}
                                  placeholder="Enter form field order"
                                  onChange={(e) => formInputChange(e, index)}
                                  name="place_order"
                                  value={item?.place_order}
                                  readOnly={!item?.deletable ? true:false}
                                ></FormControl>
                              </FormGroup>
                            </Col>{" "}
                            <Col xl="2" className="col-md-3 col-sm-6">
                              <FormGroup className="form-group">
                                <FormLabel>Required ?</FormLabel>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={(e) => formInputChange(e, index)}
                                  name="answer_type"
                                  value={item?.answer_type}
                                  // disabled={!item?.deletable ? true:false}
                                >
                                  <option>Select</option>
                                  <option value="Mandatory">Yes</option>
                                  <option value="Optional">No</option>
                                </Form.Select>
                              </FormGroup>
                            </Col>
                            <Col xl="2" className="col-md-3 col-sm-6">
                              <FormGroup className="form-group">
                                <FormLabel>Field Type</FormLabel>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={(e) => formInputChange(e, index)}
                                  name="type"
                                  value={item?.type}
                                  // disabled ={!item?.deletable ? true:false}
                                 
                                >
                                  <option>Select Type</option>
                                  <option value="Textbox">Textbox</option>
                                  <option value="Textarea">Textarea</option>
                                  <option value="Selectbox">Selectbox</option>
                                </Form.Select>
                              </FormGroup>
                            </Col>
                            {console.log(item.deletable, 1403)}

                            {item?.deletable ? (
                              <>
                                <Col
                                  xl="1"
                                  className="col-auto pt-2 pt-sm-4 mt-sm-2  mb-3 mb-sm-0"
                                >
                                  <NavLink
                                    to="#"
                                    onClick={() => reomveForm_0_Fields(index)}
                                  >
                                    <img src={Minus} alt="Add" />
                                  </NavLink>
                                </Col>
                              </>
                            ) : null}
                            {item.isSelected === true ? (
                              <Col
                                xl="2"
                                className="col-2 mb-xl-3 pt-sm-4 pt-xl-0 mb-3 mb-sm-0"
                              >
                                <Button
                                  style={{ whiteSpace: "nowrap" }}
                                  className="btn btn_primary"
                                  onClick={() => handleAddField(index)}
                                >
                                  Add Options
                                </Button>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Row className="mx-0">
                              {item?.options?.length > 0 &&
                                item.options?.map((value, idx) => (
                                  <>
                                    <span className="col-12 optionsOuter">
                                      <Row className="mx-0">
                                        <Col
                                          md="3"
                                          key={index}
                                          className="px-0 pe-3"
                                        >
                                          <FormGroup className="form-group mb-3">
                                            <FormLabel>Option Label</FormLabel>
                                            <FormControl
                                              value={value?.options}
                                              placeholder="Enter option value here"
                                              type="text"
                                              onChange={(event) =>
                                                handleInputChange(
                                                  event,
                                                  index,
                                                  idx
                                                )
                                              }
                                              required="required"
                                            ></FormControl>
                                          </FormGroup>
                                        </Col>
                                        <Col
                                          md="1"
                                          className="px-0"
                                          style={{ marginTop: "32px" }}
                                        >
                                           <NavLink
                                            to="#"
                                            onClick={() =>
                                              handleRemoveField(index, idx)
                                            }
                                          >
                                            <img src={Minus} alt="Add" />
                                          </NavLink>
                                        </Col>
                                      </Row>
                                    </span>
                                  </>
                                ))}
                            </Row>
                          </Row>
                        ))}

                        <div className="add-btn">
                          <Button
                            className="btn btn_primary"
                            disabled={isButtonDisabled}

                            onClick={() => addFormData()}
                          >
                            Save Changes{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div
                    className={
                      state?.settingTab === "manage"
                        ? "tab-pane fade active show"
                        : "tab-pane fade"
                    }
                    id="nav-manage-account"
                    role="tabpanel"
                    aria-labelledby="nav-pricing-tab"
                  >
                    <div className="booking-dtl-cls space-sec">
                      {user && <ManageAccount user={user} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
