import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "../layout/Layout";

const Private = ({ children }) => {
  const location = useLocation();
  const userToken = localStorage.getItem("userToken");
  // console.log("token", userToken);

  if (userToken) {
    return <Layout children={children} />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default Private;
