import * as Yup from "yup";
import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import UserService from "../../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const AddAttachmentModal = ({
  open,
  close,
  bookingId,
  attachArr,
  linkArr,
  attachmentType,
}) => {
  const initialValues_attach = {
    name: "",
    image: "",
  };

  const validationSchema_attach = Yup.object().shape({
    name: Yup.string().max(255).required("Attachment title is required!"),
    image: Yup.mixed()
      .test("fileSize", "File too large (max size 5MB)", (value) => {
        if (typeof value === "string" || typeof value === "undefined") {
          return true;
        } else {
          return value && value.size <= 5000000;
        }
      })
      .required("Image is required!"),
  });
  const initialValues_link = {
    links_title: "",
    links: "",
    links_desc: "",
  };

  const validationSchema_link = Yup.object().shape({
    links_title: Yup.string().max(255).required("Link title is required!"),
    links: Yup.string().max(255).required("Link title is required!"),
  });
  const onSubmit = async (values) => {
    let data;
    if (attachmentType == "Attachment") {
      attachArr.push({
        name: values?.name,
        attachment: values?.image,
        detail: "dummay",
      });
      data = { attachment: attachArr };
    }
    if (attachmentType == "Links") {
      linkArr.push({
        name: values?.links_title,
        link: values?.links,
        detail: values?.links_desc,
      });
      data = { link: linkArr };
    }
    console.log("data", data);
    try {
      const res = await UserService.updateBooking(bookingId, data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          close();
        }, 400);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  const handleImage = async (img) => {
    let formData = new FormData();
    formData.append("image", img);
    try {
      let res = await UserService.imageUpload(formData);
      if (res?.data?.status === 200) {
        //setProfileImage(res?.data?.data);
        initialValues_attach.image = res?.data?.data;
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Add New {attachmentType}</h4>
            <div className="checklist-wrap">
              {attachmentType == "Attachment" ? (
                <>
                  <Formik
                    initialValues={initialValues_attach}
                    validationSchema={validationSchema_attach}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form
                        className="add-team-member-form"
                        onSubmit={handleSubmit}
                      >
                        <div className="add-form">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Attachment Title</label>
                                <input
                                  type="text"
                                  name="name"
                                  autoComplete="off"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="name"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Attachment File</label>
                                <input
                                  type="file"
                                  name="logo"
                                  accept=".png, .jpg, .jpeg"
                                  id="fileToUpload"
                                  onChange={(e) => {
                                    handleChange(e);
                                    let imgFile = e.target.files[0];
                                    const fileRegex =
                                      /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
                                    if (fileRegex?.test(imgFile?.name)) {
                                      setFieldValue(
                                        "image",
                                        URL.createObjectURL(imgFile)
                                      );
                                      handleImage(imgFile);
                                    } else {
                                      toast.error("Formate not supported!", {
                                        autoClose: 2000,
                                      });
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values.logo}
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="image"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="add-new">
                              <button
                                type="submit"
                                className="btn btn_primary w-100"
                              >
                                Add Attachment
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  <Formik
                    initialValues={initialValues_link}
                    validationSchema={validationSchema_link}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                      <form
                        className="add-team-member-form"
                        onSubmit={handleSubmit}
                      >
                        <div className="add-form">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Link Title</label>
                                <input
                                  type="text"
                                  name="links_title"
                                  autoComplete="off"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.links_title}
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="links_title"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Link URL</label>
                                <input
                                  type="text"
                                  name="links"
                                  autoComplete="off"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.links}
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="links"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Link Description</label>
                                <input
                                  type="text"
                                  name="links_desc"
                                  autoComplete="off"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.links_desc}
                                />
                              </div>
                            </div>
                            <div className="add-new">
                              <button
                                type="submit"
                                className="btn btn_primary w-100"
                              >
                                Add Link
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddAttachmentModal;
