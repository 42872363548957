import React, { useContext, useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import ContentLoader from 'react-content-loader';

import UserContext from "../../context/UserContext";
import { NavLink } from "react-router-dom";
import dueNextNotFound from "../../assets/img/due_next_not_found.svg";
import jobNotFound from "../../assets/img/jobs_not_found.svg";
import moment from "moment";

const Dashboard = () => {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  const { user } = useContext(UserContext);
  const getData = async () => {
    try {
      let res = await UserService.getDashboardData(user?._id);
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.message, { autoClose: 2000 });
        setData(res?.data?.data?.list);
        setLoader(false)
      } 
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getData();
    setLoader(true)
  }, []);

  console.log(data);
  console.log("user", user);

  return (
    <>
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Overview</h3>
            <div className="dashboard-card">
              <div className="row gap-3 gap-sm-0">
                <div className="col-sm-6">
                  <NavLink to={"/inquiries"} state={{ booking: false }}>
                    <div className="card booking-bg">
                      <div className="card-body">
                        <p>New Inquiries</p>
                        <h3>{data?.enquiry}</h3>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="col-sm-6">
                  <NavLink to={"/bookings"} state={{ booking: true }}>
                    <div className="card inquiries-bg">
                      <div className="card-body">
                        <p>New Bookings </p>
                        <h3>{data?.bookings}</h3>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="space-sec upcoming-job-wrap">
              <div className="main-title">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9061 9C20.2881 9 20.6551 9.057 21.0001 9.162V9C21.0001 8.20435 20.684 7.44129 20.1214 6.87868C19.5588 6.31607 18.7957 6 18.0001 6H14.1211C13.9222 5.99983 13.7316 5.92069 13.5911 5.78L11.4701 3.66C11.2612 3.45089 11.0132 3.28497 10.7402 3.17173C10.4672 3.05848 10.1746 3.00013 9.87907 3H6.00007C5.20442 3 4.44136 3.31607 3.87875 3.87868C3.31614 4.44129 3.00007 5.20435 3.00007 6V9.162C3.35474 9.05431 3.72341 8.99972 4.09407 9H19.9061ZM4.09407 10.5C3.77176 10.5001 3.45322 10.5694 3.16002 10.7032C2.86682 10.8371 2.60581 11.0324 2.39464 11.2759C2.18347 11.5194 2.02709 11.8054 1.93607 12.1146C1.84505 12.4238 1.82151 12.7489 1.86707 13.068L2.72407 19.068C2.80061 19.6041 3.06796 20.0947 3.47705 20.4496C3.88613 20.8045 4.4095 20.9999 4.95107 21H19.0501C19.5916 20.9999 20.115 20.8045 20.5241 20.4496C20.9332 20.0947 21.2005 19.6041 21.2771 19.068L22.1341 13.068C22.1796 12.7489 22.1561 12.4238 22.0651 12.1146C21.9741 11.8054 21.8177 11.5194 21.6065 11.2759C21.3953 11.0324 21.1343 10.8371 20.8411 10.7032C20.5479 10.5694 20.2294 10.5001 19.9071 10.5H4.09407Z"
                      fill="#2E1885"
                    />
                  </svg>
                </span>
                <h5>Upcoming Jobs</h5>
              </div>
             {loader? <ContentLoader/>: <div className="row">
                {data?.upcoming_jobs?.length > 0 ? (
                  data?.upcoming_jobs.map((job) => (
                    <div className="col_5 position-relative">
                      <div className="card">
                        <div className="card-body">
                          <div className="job-topbar">
                            <h5>{job?.client_name}</h5>
                          </div>
                          <NavLink
                            className={"stretched-link"}
                            to={"/projects/project-detail/" + job?.project_id}
                          ></NavLink>
                          <div className="job-bottom-bar">
                            <h6>{job?.session_name}</h6>
                            <p>
                              {moment(job?.event_date).format("MMMM DD, YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="not-found-img">
                      <img src={jobNotFound} />
                    </div>
                  </>
                )}
              </div>}
            </div>
            <div className="space-sec">
              <div className="main-title">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V12C11.25 12.4142 11.5858 12.75 12 12.75H16.5C16.9142 12.75 17.25 12.4142 17.25 12C17.25 11.5858 16.9142 11.25 16.5 11.25H12.75V6Z"
                      fill="#2E1885"
                    />
                  </svg>
                </span>
                <h5>Due Next</h5>
              </div>
             {loader? <ContentLoader/>: <div className="row due-next-list">
              <div className="col-sm-12">
                {data?.due_next?.length > 0 ? (
                  data?.due_next.map((due_next) => (
                    <div className="card position-relative">
                      <div className="card-body">
                        <div className="due-wrap">
                          <div className="date-cls">
                            <p>
                              {moment(due_next?.due_date).format(
                                "MMMM DD, YYYY"
                              )}
                            </p>
                          </div>
                          <NavLink
                            className={"stretched-link"}
                            to={
                              "/projects/project-detail/" + due_next?.project_id
                            }
                          ></NavLink>
                          <div className="event-dtl mt-0">
                            <h5>
                              {due_next?.client_name?.first_name}{" "}
                              {due_next?.client_name?.last_name}
                            </h5>
                            <p>{due_next?.number_events} Events</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  ) : (
                  
                  <>
                    <div className="not-found-img">
                      <img src={dueNextNotFound} />
                    </div>
                  </>
                )}
              </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
