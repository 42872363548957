import React from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo-2.png";
import AuthService from "../../services/AuthService";
import AuthCarousel from "../../components/AuthCarousel";

const ForgotPassword = () => {
  const initialValues = {
    email: "",
  };
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{1,})?$/,
        "Invalid email"
      )
      .max(255)
      .required("Email is required")
      .trim(),
  });

  const onSubmit = async (values) => {
    console.log(values);
    const data = { email: values?.email };
    try {
      const res = await AuthService.forgotPassword(data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        console.log(res);
        setTimeout(() => {
          navigate("/verify-otp", { state: values?.email });
        }, 1000);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log(error?.response?.data?.message);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="login-sec">
        <div className="container-fluid p-0">
          <div className="login-inner">
            <Row className="m-0">
              <Col md="6" className="p-0">
                <AuthCarousel />
              </Col>
              <Col md="6" className="p-0">
                <div className="login-form">
                  <div className="login-form-cont">
                    <img src={logo} alt="" />
                    <h6>The simplest workflow tool for freelancers.</h6>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            placeholder="name@email.com"
                            className="form-control"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            autoComplete="off"
                          />
                        </div>
                        <p className="text-danger">
                          <ErrorMessage className="text-danger" name="email" />
                        </p>
                        <div className="login-btn">
                          <button className="btn btn_primary" type="submit">
                            Submit
                          </button>
                        </div>
                        <div className="sign-up-free">
                          <p className="btn light_btn">
                            Do you know your account?{" "}
                            <NavLink to="/login">Login</NavLink>
                          </p>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
