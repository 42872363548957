import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import loaderGif from "../../../assets/img/loading.gif";

const Add = ({ open, close }) => {
  const [image, setImage] = useState();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [saveButton, setSaveButton] = useState(false);

  const initialValues = {
    title: state?.title ? state?.title : "",
    message: state?.message ? state?.message : "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Template name is required"),
    message: Yup.string().required("Message is required"),
  });

  const onSubmit = async (values) => {
    setSaveButton(true);
    const data = {
      title: values?.title,
      message: values?.message,
    };
    try {
      let res = await UserService.addContract(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/settings", { state: { settingTab: "contract" } });
        }, 1000);
      } else {
        setSaveButton(false);
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      setSaveButton(false);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Add Contract Template</h3>
            <div className="space-sec mt-0">
              <div className="inner-title">
                <div className="back-wrap">
                  <NavLink to="/settings" state={{ settingTab: "contract" }}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                        stroke="#2E1885"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Contract Templates
                  </NavLink>
                </div>
              </div>
              <div className="booking-dtl-cls space-sec">
                <div className="client-dtl">
                  <h4></h4>
                  <div className="card">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              name="title"
                              onChange={handleChange}
                              value={values?.title}
                              className="form-control"
                            />
                            <p className="error-msg mt-2">
                              <ErrorMessage
                                className="error-msg"
                                name="title"
                              />
                            </p>
                          </div>
                          <div className="form-group">
                            <div className="message-label d-flex  justify-content-between">
                              <label>Message</label>
                              {/* <NavLink to="#">Insert Placeholder</NavLink> */}
                            </div>
                            <ReactQuill
                              name="message"
                              value={values?.message}
                              onChange={(e) => setFieldValue("message", e)}
                              style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="message"
                              />
                            </p>
                          </div>
                          <div className="add-new">
                            <button
                              type="submit"
                              className="btn btn_primary w-100"
                              disabled={saveButton}
                            >
                              Save Template{" "}
                              {saveButton ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
