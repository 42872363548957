import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import FromIMG from "../../assets/img/customer-img.png";
import LogoImg from "../../assets/img/logo.png";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";
import UserImg from "../../assets/img/team-img.png";

const TransactionForm = () => {
  const [formData, setformData] = useState([]);
  const [photographerData, setPhotographerData] = useState({});
  const { id, transId } = useParams();
  const validationSchema = Yup.object().shape({});
  const navigate = useNavigate();

  const onSubmit = async () => {
    let customerForms_11 = {};
    for (let form11 of formData) {
      customerForms_11[form11.id] = form11.value;
    }
    const data = {
      photographer_id: id,
      booking_id: transId,
    };
    try {
      const res = await UserService.sendTransaction(data, id);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setformData([]);
        setTimeout(() => {
          navigate("/transaction-thanks");
        }, 1000);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  const getPhotographer = async () => {
    try {
      const res = await UserService.getPhotographer(id);
      if (res?.data?.success === true) {
        setPhotographerData(res?.data?.result);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  useEffect(() => {
    getPhotographer();
  }, []);
  return (
    <>
      <ToastContainer position="top-right" />

      <div className="pageMainWraper">
        <div className="container">
          <div className="pageHeader text-center">
            <div className="photoGrapherOuter">
              <div className="proImgOuter rounded-circle overflow-hidden mx-auto d-flex align-items-center justify-content-center">
                {photographerData.companyLogo == null ? (
                  <>
                    <img
                      className="w-100 h-100"
                      src={LogoImg}
                      alt="photographer-logo"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="w-100 h-100"
                      src={photographerData.companyLogo}
                      alt="photographer-logo"
                    />
                  </>
                )}
              </div>
              <div className="photographerName">
                {photographerData.firstName} {photographerData.lastName}
              </div>
              <a href="/login" className="btn btnVisitBtn border-0 shadow-none">
                Visit Website
              </a>
            </div>
          </div>
          <div className="formMainOuter">
            <div className="formTitle">Payment Form</div>
            <div className="formInner text-start">
              <div className="login-form p-0">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="login-btn">
                        <button type="submit" className="btn btnSubmit w-100">
                          Pay Now
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="pageFooter text-center">
            <div className="footerOuter">
              <div className="title">Powered by</div>
              <div className="companyLogo mx-auto">
                <img className="w-100" src={LogoImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionForm;
