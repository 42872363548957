import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo-2.png";
import AuthService from "../../services/AuthService";
import AuthCarousel from "../../components/AuthCarousel";
import { ToastContainer, toast } from "react-toastify";
import OTPInput from "react-otp-input";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [error, setError] = useState();
  const [seconds, setSeconds] = useState(30);

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
    setIsOtpValid(otpValue.length === 4); // Set validity based on the length of OTP (4 digits in this case)
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      otp: otp,
      email: state,
    };
    if (isOtpValid) {
      try {
        const res = await AuthService.verifyOtp(data);
        if (res?.data?.status === 200) {
          console.log(res?.data);
          toast.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            navigate(`/reset-password/123456`, { state: state });
          }, 1000);
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
        console.log(error?.response?.data?.message);
      }
    } else {
      setError("Invalid OTP");
    }
  };
  const resendOtp = async () => {
    const data = { email: state };
    try {
      const res = await AuthService.forgotPassword(data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setSeconds(30);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [seconds]);
  useEffect(() => {
    if (seconds === 0) {
      // Timer has reached 0 seconds, you can add logic here for what to do when the timer ends.
      // For example, show a message, trigger an action, etc.
    }
  }, [seconds]);

  return (
    <div className="login-sec">
      <ToastContainer />
      <div className="container-fluid p-0">
        <div className="login-inner">
          <Row className="m-0">
            <Col md="6" className="p-0">
              <AuthCarousel />
            </Col>
            <Col md="6" className="p-0">
              <div className="login-form">
                <div className="login-form-cont">
                  <img src={logo} alt="" />
                  <h6>The simplest workflow tool for freelancers.</h6>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label className="otp-input">Verify Otp</label>
                    <OTPInput
                      inputStyle={{
                        display: "flex",
                      }}
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={4}
                      separator={<span>-</span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      renderInput={(props) => <input {...props} />}
                    />
                    {error && <span className="error-msg">{error}</span>}
                  </div>
                  <div className="resend-cls d-flex">
                    <span>
                      {seconds > 0 ? <>Resend otp in {seconds}</> : <></>}
                    </span>
                    <button
                      to={"#"}
                      className="resend-link"
                      onClick={resendOtp}
                      type="button"
                      disabled={seconds > 0 ? true : false}
                    >
                      Resend
                    </button>
                  </div>
                  <div className="login-btn ">
                    <button className="btn btn_primary" type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
