import * as Yup from "yup";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ErrorMessage, Field, Formik } from "formik";
import teamImg from "../../assets/img/team-img.png";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import loaderGif from "../../assets/img/loading.gif";

const EditTeam = ({ open, close, teamData, roles }) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  let makeRole = [];

  teamData.role?.map((role) => makeRole.push(role._id));
  const initialValues = {
    firstName: teamData ? teamData?.firstName : "",
    lastName: teamData ? teamData?.lastName : "",
    email: teamData ? teamData?.email : "",
    image: teamData ? teamData?.image : "",
    // role: makeRole ? makeRole?.role : [],
    hourly_rate: teamData ? teamData?.hourly_rate : "",
  };

  const [profileImage, setProfileImage] = useState(teamData?.image);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required("First Name is required!"),
    lastName: Yup.string().max(255).required("Last Name is required!"),
    email: Yup.string().email().max(255).required("Email is required").trim(),
    image: Yup.string().required("Image is required"),
    // role: Yup.array().min(1, "Select at least one role"),
    hourly_rate: Yup.number()
      .max(10000, "Hourly Rate must be less than 10000")
      .required("Hourly rate is required!"),
  });

  const handleImage = async (img) => {
    let formData = new FormData();
    formData.append("image", img);
    try {
      let res = await UserService.imageUpload(formData);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setProfileImage(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const onSubmit = async (values) => {
    setButtonDisabled(true)
    const data = {
      firstName: values?.firstName.toString(),
      lastName: values?.lastName.toString(),
      image:
        profileImage !== undefined || profileImage !== null ? profileImage : "",
      // role: values?.role,
      email: values?.email.toString(),
      hourly_rate: values?.hourly_rate.toString(),
    };
    console.log(data);
    try {
      const res = await UserService.updateTeam(teamData?._id, data);
      console.log("res", res?.data);
      if (res?.data?.status === 200) {
        setButtonDisabled(false)
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          close();
        }, 400);
      } else {
        setButtonDisabled(false)
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      setButtonDisabled(false)
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  console.log("teamData", teamData);

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Edit Team Member</h4>
            <div className="checklist-wrap">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form
                    className="add-team-member-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="team-member-profile">
                      <div className="profile-img">
                        <img
                          src={
                            profileImage === null || profileImage === undefined
                              ? teamImg
                              : profileImage
                          }
                          alt="Team Image"
                        />
                      </div>
                      <div className="file_input">
                        <label for="file-upload" className="d-none"></label>
                        <p className="error-msg">
                          <ErrorMessage className="error-msg" name="image" />
                        </p>
                        <input
                          type="file"
                          name="image"
                          id="file-upload"
                          onChange={(e) => {
                            handleChange(e);
                            let imgFile = e.target.files[0];
                            const fileRegex =
                              /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
                            if (fileRegex?.test(imgFile?.name)) {
                              setFieldValue(
                                "image",
                                URL.createObjectURL(imgFile)
                              );
                              handleImage(imgFile);
                            } else {
                              toast.error("Formate not supported!", {
                                autoClose: 2000,
                              });
                            }
                          }}
                        />
                        <span for="fileToUpload">Change Picture</span>
                      </div>
                      <p className="error-msg">
                        <ErrorMessage className="error-msg" name="firstName" />
                      </p>
                    </div>
                    <div className="add-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              name="firstName"
                              placeholder="Aaliyah"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="firstName"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              name="lastName"
                              placeholder="Popal"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="lastName"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              name="email"
                              placeholder="shirazk@outlook.com"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="email"
                              />
                            </p>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Title</label>
                            <Field
                              as="select"
                              id="role"
                              name="role"
                              value={values.role}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiple
                              className="form-control"
                            >
                              <option value={""}></option>
                              {roles?.map((role) => (
                                <option value={role._id}>
                                  {role.role_name}
                                </option>
                              ))}
                            </Field>
                            <p className="error-msg">
                              <ErrorMessage className="error-msg" name="role" />
                            </p>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Hourly Rate($)</label>
                            <input
                              type="number"
                              name="hourly_rate"
                              placeholder="16.00"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.hourly_rate}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="hourly_rate"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="add-new">
                          <button
                            type="submit"
                            disabled={isButtonDisabled}
                            className="btn btn_primary w-100"
                          >
                            Update Team Member{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditTeam;
