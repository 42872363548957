import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo-2.png";
import eyeOpen from "../../assets/img/eye.svg";
import eyeClose from "../../assets/img/eye-slash.svg";
import AuthCarousel from "../../components/AuthCarousel";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../services/AuthService";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

const Login = () => {
  let userEmail = localStorage.getItem("email");
  let userPass = localStorage.getItem("pass");
  let userRemember = localStorage.getItem("remember");
  const [countryName, setCountryName] = useState(
    localStorage.getItem("country") ? localStorage.getItem("country") : ""
  );
  const initialValues = {
    email: userEmail ? userEmail : "",
    password: userPass ? userPass : "",
    remember: userRemember ? true : false,
  };

  const { user, setUser } = useContext(UserContext);
  console.log("setUser", user, setUser);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().max(255).required("Email is required").trim(),
    password: Yup.string()
      .min(2, "Too short")
      .max(50, "Too Long")
      .required("Password is required")
      .trim(),
  });

  const onSubmit = async (values) => {
    console.log(values?.remember);
    const data = {
      email: values?.email,
      password: values?.password,
    };
    try {
      const res = await AuthService.login(data);
      console.log("res", res);
      if (res?.data?.status === 200) {
        setUser(res?.data?.data?.user);
        toast.success(res?.data?.message, { autoClose: 2000 });
        const token = res?.data?.data?.token;
        const userId = res?.data?.data?.user?._id;
        if (values.remember === true) {
          localStorage.setItem("email", values.email);
          localStorage.setItem("pass", values.password);
          localStorage.setItem("remember", true);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("pass");
          localStorage.removeItem("remember");
        }
        localStorage.setItem("userId", userId);
        localStorage.setItem("userToken", token);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  useEffect(() => {
    // Function to fetch IP information and set the country name
    const fetchIPInfo = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io?token=e1ea52d0952a07"
        );
        const data = response.data;
        localStorage.setItem("country", data.country.toLowerCase());
      } catch (error) {
        console.error("Error fetching IP information:", error);
      }
    };
    // Call the function to fetch IP information
    if (countryName == "") {
      fetchIPInfo();
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="login-sec h-100">
        <div className="container-fluid p-0 h-100">
          <div className="login-inner h-100">
            <Row className="m-0 h-100">
              <Col md="6" className="p-0">
                <AuthCarousel />
              </Col>
              <Col md="6" className="p-0 h-100">
                <div className="login-form">
                  <div className="login-form-cont">
                    <img src={logo} alt="" />
                    <h6>The simplest workflow tool for freelancers.</h6>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            placeholder="name@email.com"
                            className="form-control"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          <p className="error-msg">
                            <ErrorMessage className="error-msg" name="email" />
                          </p>
                        </div>
                        <div className="form-group pw-show-cls">
                          <label>Password</label>

                          <input
                            className="form-control"
                            placeholder=". . . . . . ."
                            type={showPass ? "text" : "password"}
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                          />
                          <div className="pw-show">
                            <img
                              src={showPass ? eyeOpen : eyeClose}
                              onClick={() => setShowPass(!showPass)}
                            />
                          </div>
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="password"
                            />
                          </p>
                        </div>
                        <div className="form-group remember">
                          <label htmlFor="remember" className="remember">
                            Remember Me
                          </label>
                          <input
                            id="remember"
                            type="checkbox"
                            name="remember"
                            value={values.remember}
                            onChange={handleChange}
                            checked={values.remember === true}
                          />
                        </div>
                        <div className="login-btn">
                          <button className="btn btn_primary" type="submit">
                            Login
                          </button>
                        </div>
                        <div className="sign-up-free">
                          <NavLink to="/register" className="btn light_btn">
                            Sign Up Free
                          </NavLink>
                        </div>
                        <div className="sign-up-free">
                          <p className="btn light_btn">
                            Forgot your password?{" "}
                            <NavLink to="/forgot-password">Forgot</NavLink>
                          </p>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
