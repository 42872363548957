import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import Launch from "../assets/img/launch.svg";
import { ToastContainer, toast } from "react-toastify";

const FormGenrateModal = ({ open, close, enquiryUrl }) => {
  const paragraphRef = useRef(null);

  const copyText = () => {
    if (paragraphRef.current) {
      const range = document.createRange();
      range.selectNode(paragraphRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      toast.success("Copied", { autoClose: 2000 });
    }
  };
  return (
    <>
      <Modal
        show={open}
        hide={close}
        className="modal fade customModal"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close mt-1 me-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <div className="contract_launch">
              <img src={Launch} alt="photographer-launch-vector" />
              <h3>Response sent !</h3>
              <p>You can continue this conversation by email.</p>
              <button className="btn btn_primary" onClick={close}>
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormGenrateModal;
