import React from "react";
import LogoImg from "../../assets/img/logo-white.png";
import ThankYou from "../../assets/img/launch.svg";
import { ToastContainer } from "react-toastify";

const Transactionthanks = () => {
  return (
    <>
      <ToastContainer position="top-right" />
      <div className="login-sec customer-form-main">
        <div className="container p-0">
          <div className="booking-form-inner">
            <div className="logo-wrap">
              <img src={LogoImg} alt="flomatic-logo" />
            </div>
            <div className="booking-form-card">
              <div className="thank-you">
                <div className="thankyou-img">
                  <img src={ThankYou} alt="" />
                </div>
                <div className="thankyou-cont">
                  <h3>Your Contract Request Sent !</h3>
                  <p>Thank you for your request !</p>
                  <p>
                    Please note that <b>your Booking is not final</b> until it
                    is reviewed and confirmed by Photographer.
                  </p>
                  {/* <NavLink to={"/login"} className="btn_primary">
                    Back to Home
                  </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactionthanks;
