import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/logo-2.png";
import { ToastContainer, toast } from "react-toastify";
import AuthCarousel from "../../components/AuthCarousel";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import loaderGif from "../../assets/img/loading.gif";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const Register_2 = () => {
  const [subscriptionList, setSubscription] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState();
  const [subscriptionAmt, setSubscriptionAmt] = useState();
  const [saveButton, setSaveButton] = useState(false);
  const { id } = useParams();

  const initialValues = {
    subscriptionId: "",
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    subscriptionId: Yup.string()
      .max(255)
      .required("Select any subscription plan for continue!"),
  });

  const onSubmit = async (values) => {
    setSaveButton(true);
    const data = {
      subscriptionId: subscriptionId,
      photographerId: id,
    };
    try {
      const res = await UserService.addSubscription(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          getPaymentLink(res?.data?.data._id);
        }, 100);
      } else {
        setSaveButton(false);
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      setSaveButton(false);
    }
  };

  
  const getPaymentLink = async (subId) => {
    const data = {
      amount: subscriptionAmt,
      subscriptionId: subId,
      photographerId: id,
      type: "web"
    };
    try {
      const res = await UserService.paymentLinkAPI(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        navigate("/login")

        setTimeout(() => {
          window.open(res?.data?.data?.paymentLinkUrl,"_blank");
        }, 100);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  const getSubscription = async () => {
    try {
      const res = await UserService.getSubscription();
      if (res?.data?.status === 200) {
        console.log(res?.data?.data?.list);
        setSubscription(res?.data?.data?.list);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const handleItemClick = (packageId, packagePrice) => {
    setSubscriptionId(packageId);
    setSubscriptionAmt(packagePrice);
  };

  return (
    <>
      <ToastContainer />
      <div className="login-sec">
        <div className="container-fluid p-0">
          <div className="login-inner">
            <Row className="m-0">
              <Col md="6" className="p-0">
                <AuthCarousel />
              </Col>
              <Col md="6" className="p-0">
                <div className="login-form signup-form">
                  <div className="login-form-cont">
                    <img src={logo} alt="Logo" />
                    <h6>The simplest workflow tool for freelancers.</h6>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row selectPackage">
                          {subscriptionList.length > 0
                            ? subscriptionList.map((item, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className={
                                        item._id === subscriptionId
                                          ? "packageItem subscriptionItem mb-3 p-3 rounded-4 bg-white"
                                          : "packageItem subscriptionItem mb-3 p-3 rounded-4 bg-white"
                                      }
                                    >
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center gap-3 mb-3">
                                          <Field
                                            type="radio"
                                            name="subscriptionId"
                                            className="customRadio"
                                            value={item._id}
                                            onClick={() => handleItemClick(
                                              item?._id,
                                              item?.amount
                                            )}
                                          />
                                          <div className="packageHeader d-flex align-items-center justify-content-between w-100 mb-0">
                                            <div className="title">
                                              {item?.subscriptionName}
                                            </div>
                                            <div className="price">
                                              {"$"}
                                              {item?.amount}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="content text-start">
                                            {item?.description}
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="validity text-end mt-2">
                                            Validity : {item?.validity}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="subscriptionId"
                            />
                          </p>
                        </div>
                        <div className="login-btn">
                          <button
                            className="btn btn_primary"
                            type="submit"
                            disabled={saveButton}
                          >
                            Complete Register{" "}
                            {saveButton ? (
                              <>
                                <img height={20} width={20} src={loaderGif} />
                              </>
                            ) : null}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register_2;
