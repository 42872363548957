import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import UserService from "../../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import loaderGif from "../../../assets/img/loading.gif";

const Edit = ({ open, close, bookingId, eventData }) => {
  const [timeData, setTimeData] = useState();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const initialValues = {
    venue_name: eventData ? eventData?.venue : "",
    session_name: eventData ? eventData?.session_name : "",
    date: eventData ? moment(eventData?.date).format("YYYY-MM-DD") : "",
    startTime: eventData ? eventData?.startTime : "",
    endTime: eventData ? eventData?.endTime : "",
    address: eventData ? eventData?.address : "",
    city: eventData ? eventData?.city : "",
    state: eventData ? eventData?.state : "",
    country: eventData ? eventData?.country : "",
  };

  const validationSchema = Yup.object().shape({
    session_name: Yup.string().required("Session name is required"),
    venue_name: Yup.string().required("Venue is required"),
    date: Yup.string().required("Date is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
  });

  const getTimeFormat = async () => {
    try {
      let res = await UserService.getSettings();
      console.log(res?.data?.result);
      setTimeData(res?.data?.result);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const onSubmit = async (values) => {
    setButtonDisabled(true)
    const data = {
      events: [
        {
          venue: `${values?.venue_name}`,
          session_name: `${values?.session_name}`,
          date: `${values?.date}`,
          startTime:
            timeData?.time_format === 12
              ? `${values?.startTime12}`
              : `${values?.startTime}`,
          endTime:
            timeData?.time_format === 12
              ? `${values?.endTime12}`
              : `${values?.endTime}`,
          address: `${values?.address}`,
          city: `${values?.city}`,
          state: `${values?.state}`,
          country: `${values?.country}`,
          event_id: `${eventData?._id}`,
        },
      ],
    };
    try {
      const res = await UserService.updateBooking(bookingId, data);
      console.log("res", res?.data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          close();
        }, 400);
        setButtonDisabled(false)
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
      setButtonDisabled(false)
    }
  };
  useEffect(() => {
    getTimeFormat();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Edit Event Details</h4>
            <div className="checklist-wrap">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form
                    className="add-team-member-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="add-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Session Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="session_name"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.session_name}
                              autoComplete="off"
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="session_name"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Event Date <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              name="date"
                              autoComplete="off"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.date}
                            />
                            <p className="error-msg">
                              <ErrorMessage className="error-msg" name="date" />
                            </p>
                          </div>
                        </div>
                        {timeData?.time_format === "12" ? (
                          <>
                            {" "}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Start Time{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  type="time"
                                  name="startTime"
                                  className="form-control"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="yyyy-MM-dd h:mm aa"
                                  onChange={(time) => {
                                    setFieldValue(
                                      "startTime",
                                      moment(time).format("hh:mm A")
                                    );
                                  }}
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  value={values?.startTime}
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="startTime"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  End Time{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  type="time"
                                  name="endTime"
                                  className="form-control"
                                  onChange={(time) => {
                                    setFieldValue(
                                      "endTime",
                                      moment(time).format("hh:mm A")
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.endTime}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  autoComplete="off"
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="endTime"
                                  />
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Start Time{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="time"
                                  name="startTime"
                                  placeholder="shirazk@outlook.com"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.startTime}
                                  autoComplete="off"
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="startTime"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  End Time{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="time"
                                  name="endTime"
                                  placeholder="Photographer"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.endTime}
                                  autoComplete="off"
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="endTime"
                                  />
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Event Venue
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="venue_name"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.venue_name}
                              autoComplete="off"
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="venue_name"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Address <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="address"
                              placeholder="5th street"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address}
                              autoComplete="off"
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="address"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              City <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="city"
                              placeholder="Photographer"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                              autoComplete="off"
                            />
                            <p className="error-msg">
                              <ErrorMessage className="error-msg" name="city" />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              State/Province{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="state"
                              placeholder="Davinci"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.state}
                              autoComplete="off"
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="state"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Country <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="country"
                              placeholder="France"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                              autoComplete="off"
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="country"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="add-new">
                          <button
                                                      disabled={isButtonDisabled}

                            type="submit"
                            className="btn btn_primary w-100"
                          >
                            Update{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Edit;
