import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import AddContractModal from "../../../components/AddContractModal";

const View = () => {
  const [list, setList] = useState();
  const [addContract, setAddContract] = useState(false);
  const navigate = useNavigate();
  const getContractList = async () => {
    try {
      let res = await UserService.getContractList();
      if (res?.data?.status === 200) {
        console.log(res?.data);
        // toast.success(res?.data?.message, { autoClose: 2000 });
        setList(res?.data?.data?.list);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const handleDelete = async (Id, index) => {
    const confirmed = window.confirm("Do you want to delete ? ");
    if (confirmed) {
      try {
        let res = await UserService.deleteContractTemplate(Id);
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message, { autoClose: 2000 });
          getContractList();
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };
  useEffect(() => {
    getContractList();
  }, [addContract]);
  const openContract = () => {
    // setAddContract(true);
    navigate("/settings/contract-template/add");
  };

  const closeContract = () => {
    setAddContract(false);
  };
  return (
    <>
      {addContract === true && (
        <AddContractModal open={openContract} close={closeContract} />
      )}
    
      <div className="inner-title">
        <h4>Contract Templates</h4>
        <div className="add-new-wrap generate-contract">
          <button className="btn btn_primary" onClick={() => openContract()}>
            <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5001 4.44446C12.7064 4.44446 12.9042 4.5264 13.0501 4.67226C13.196 4.81813 13.2779 5.01596 13.2779 5.22224V12.2222H20.2779C20.4842 12.2222 20.682 12.3042 20.8279 12.45C20.9737 12.5959 21.0557 12.7937 21.0557 13C21.0557 13.2063 20.9737 13.4041 20.8279 13.55C20.682 13.6958 20.4842 13.7778 20.2779 13.7778H13.2779V20.7778C13.2779 20.9841 13.196 21.1819 13.0501 21.3278C12.9042 21.4736 12.7064 21.5556 12.5001 21.5556C12.2939 21.5556 12.096 21.4736 11.9502 21.3278C11.8043 21.1819 11.7224 20.9841 11.7224 20.7778V13.7778H4.72236C4.51608 13.7778 4.31825 13.6958 4.17239 13.55C4.02652 13.4041 3.94458 13.2063 3.94458 13C3.94458 12.7937 4.02652 12.5959 4.17239 12.45C4.31825 12.3042 4.51608 12.2222 4.72236 12.2222H11.7224V5.22224C11.7224 5.01596 11.8043 4.81813 11.9502 4.67226C12.096 4.5264 12.2939 4.44446 12.5001 4.44446Z"
                fill="white"
              />
            </svg>
            New Template
          </button>
        </div>
      </div>
      {list?.length > 0 &&
        list?.map((item, index) => (
          <div key={index}>
            <div className="d-flex booking-card-main-cls">
              <div className="booking-card">
                <div className="row position-relative">
                  <div className="col d-flex">
                    <NavLink
                      to={`/settings/contract-template/edit/${item?._id}`}
                      state={item}
                      className={"stretched-link"}
                    >
                      <div className="booking-left p-0">
                        <div className="msg-ic">
                          <svg
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.848 0.771002C6.21613 0.423402 8.60649 0.249272 11 0.250002C13.43 0.250002 15.817 0.428002 18.152 0.770002C20.13 1.062 21.5 2.794 21.5 4.74V10.76C21.5 12.706 20.13 14.438 18.152 14.73C16.212 15.014 14.236 15.185 12.23 15.235C12.1303 15.2369 12.0351 15.277 11.964 15.347L7.78 19.53C7.67511 19.6348 7.54153 19.7061 7.39614 19.735C7.25074 19.7639 7.10004 19.749 6.96308 19.6923C6.82611 19.6356 6.70903 19.5395 6.62661 19.4163C6.54419 19.2931 6.50013 19.1482 6.5 19V15.045C5.61329 14.9639 4.72895 14.8585 3.848 14.729C1.87 14.439 0.5 12.705 0.5 10.759V4.741C0.5 2.795 1.87 1.061 3.848 0.771002Z"
                              fill="#FE5F55"
                            />
                          </svg>
                        </div>
                      </div>
                    </NavLink>
                    <div className="booking-cont">
                      <h3>{item?.title}</h3>
                    </div>
                  </div>

                  {item?.automate_response === true && (
                    <div className="col-sm-auto order-3 order-sm-2">
                      <div className="booking-notifi-cont">
                        <span className="tag">Auto-responding</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-auto order-2 order-sm-3">
                <div className="booking-notification">
                  <div className="notifi-dot p-0">
                    <Dropdown className="bookingDrop">
                      <Dropdown.Toggle>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                              stroke="#0F172A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                              stroke="#0F172A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                              stroke="#0F172A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={NavLink}
                          onClick={() => handleDelete(item?._id, index)}
                          state={item}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default View;
