import axios from "axios";
import { SERVER_IP, SERVER_IP_IMG } from "../utils/constants";

const API_URL = SERVER_IP + "/";
const API_URL_IMG = SERVER_IP_IMG + "/";

const authHeader = () => {
  const token = localStorage.getItem("userToken");

  if (token) {
    return { Authorization: "Bearer " + token };
  }
  return {};
};

const UserService = {
  getDashboardData() {
    return axios.get(`${API_URL}dashboard`, { headers: authHeader() });
  },
  ///Payment Links
  paymentLinkAPI(val) {
    return axios.post(`${API_URL}create-payment-intant`, val, {
      headers: authHeader(),
    });
  },
  updatePhotographerSubscription(val) {
    return axios.post(`${API_URL}/update-photographer-subscription`, val, {
      headers: authHeader(),
    });
  },
  ///Inquiry Routes
  getInquiryList() {
    return axios.get(`${API_URL}enquiry-list`, { headers: authHeader() });
  },
  deleteInquiry(id) {
    return axios.put(
      `${API_URL}delete-enquiry/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },

  getProjectList(val) {
    return axios.get(`${API_URL}booking-list?type=${val}`, {
      headers: authHeader(),
    });
  },
  getProjectDetail(id) {
    return axios.get(`${API_URL}booking/${id}`, {
      headers: authHeader(),
    });
  },
  getBookingList() {
    return axios.get(`${API_URL}booking-list`, {
      headers: authHeader(),
    });
  },
  getInquiry(id) {
    return axios.get(`${API_URL}enquiry/${id}`, { headers: authHeader() });
  },
  updateInquiryStatus(id, val) {
    return axios.put(`${API_URL}enquiry/statusChange/${id}/is_complete`, val, {
      headers: authHeader(),
    });
  },
  updateBooking(id, val) {
    return axios.put(`${API_URL}update-booking/${id}`, val, {
      headers: authHeader(),
    });
  },
  getBooking(id) {
    return axios.get(`${API_URL}booking/${id}`, { headers: authHeader() });
  },
  getContractDetails(id) {
    return axios.get(`${API_URL}get-contract/${id}`, {
      headers: authHeader(),
    });
  },
  sendContractToCustomer(id) {
    return axios.put(
      `${API_URL}send-mail/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },
  getCategoryList(id) {
    return axios.get(`${API_URL}category-list?id=` + id, {
      headers: authHeader(),
    });
  },
  addJobCategory(val) {
    return axios.post(`${API_URL}add-category`, val, { headers: authHeader() });
  },
  updateJobCategory(id, val) {
    return axios.put(`${API_URL}update-category/${id}`, val, {
      headers: authHeader(),
    });
  },
  deleteJobCategory(id, val) {
    return axios.put(
      `${API_URL}delete-category/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },
  getRoleList(id) {
    return axios.get(`${API_URL}role-list?id=` + id, {
      headers: authHeader(),
    });
  },
  addJobRole(val) {
    return axios.post(`${API_URL}add-role`, val, { headers: authHeader() });
  },
  updateJobRole(id, val) {
    return axios.put(`${API_URL}update-role/${id}`, val, {
      headers: authHeader(),
    });
  },
  deleteJobRole(id, val) {
    return axios.put(
      `${API_URL}delete-role/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },
  ///Response Template's
  getTemplateList() {
    return axios.get(`${API_URL}template/list`, { headers: authHeader() });
  },
  deleteResponse(id) {
    return axios.put(
      `${API_URL}deleteResponse/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },
  getTemplate(id) {
    return axios.get(`${API_URL}template/${id}`, { headers: authHeader() });
  },
  addTemplate(val) {
    return axios.post(`${API_URL}add-template`, val, { headers: authHeader() });
  },
  editTemplate(id, val) {
    return axios.put(`${API_URL}edit-template/${id}`, val, {
      headers: authHeader(),
    });
  },
  addForm(val) {
    return axios.post(`${API_URL}add-form`, val, { headers: authHeader() });
  },

  //Image Upload
  imageUpload(val) {
    return axios.post(`${API_URL_IMG}image-upload`, val);
  },
  ///Subscription
  getSubscription() {
    return axios.get(`${API_URL}subscriptions`);
  },
  getPhotographerSubscription(){
    return axios.get(`${API_URL}photographer-subscription`, { headers: authHeader() });

  },
  cancelPhotographerSubscription(val){
    return axios.post(`${API_URL}cancel-subscription-plan`,val , { headers: authHeader() });
  },

  addSubscription(val) {
    return axios.post(`${API_URL}add-subscription`, val, {
      headers: authHeader(),
    });
  },

  ///Customer Panel Services
  getPhotographerDetails(id) {
    return axios.get(`${API_URL}details/` + id);
  },
  getPhotographer(id) {
    return axios.get(`${API_URL}profile/`, {
      headers: authHeader(),
    });
  },
  updatePhotographer(val) {
    return axios.put(`${API_URL}update-profile`, val, {
      headers: authHeader(),
    });
  },
  updatePhotographerPassword(val) {
    return axios.post(`${API_URL}auth/change-password`, val, {
      headers: authHeader(),
    });
  },
  packageList(id) {
    return axios.get(`${API_URL}package-list?id=` + id);
  },
  itemList(id) {
    return axios.get(`${API_URL}item-list?id=` + id);
  },
  getCustomerForm(id) {
    return axios.get(`${API_URL}customer-form/${id}`);
  },
  eventTypes(id) {
    return axios.get(`${API_URL}category-customer?id=${id}`, {
      headers: authHeader(),
    });
  },
  editEventTeam(id, val) {
    return axios.put(`${API_URL}editEventTeam/${id}`, val, {
      headers: authHeader(),
    });
  },
  getEventTeam(id) {
    return axios.get(`${API_URL}eventTeam/${id}`, {
      headers: authHeader(),
    });
  },
  sendEnquiry(val, id) {
    return axios.post(`${API_URL}add-enquiry?id=` + id, val);
  },
  sendBooking(val, id) {
    return axios.post(`${API_URL}add-booking?id=${id}`, val);
  },
  sendTransaction(val) {
    return axios.post(`${API_URL}add-transaction`, val);
  },
  updateTransaction(id, val) {
    return axios.put(`${API_URL}update-transaction/${id}`, val, {
      headers: authHeader(),
    });
  },
  getbookingData(id) {
    return axios.get(`${API_URL}bookingCustomer/${id}`);
  },
  deleteBooking(id, val) {
    return axios.put(
      `${API_URL}delete-booking/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },

  //contract/list
  getContractList() {
    return axios.get(`${API_URL}contract/list`, { headers: authHeader() });
  },
  deleteContractTemplate(id) {
    return axios.put(
      `${API_URL}deleteContract/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },
  getContract(id) {
    return axios.get(`${API_URL}contract/${id}`, { headers: authHeader() });
  },
  addContract(val) {
    return axios.post(`${API_URL}add-contract`, val, { headers: authHeader() });
  },
  generateContract(val, id) {
    return axios.post(`${API_URL}add-contract-details/${id}`, val, {
      headers: authHeader(),
    });
  },
  editgenerateContract(val, id) {
    return axios.post(`${API_URL}edit-contract-details/${id}`, val, {
      headers: authHeader(),
    });
  },
  editContract(id, val) {
    return axios.put(`${API_URL}edit-contract/${id}`, val, {
      headers: authHeader(),
    });
  },

  // Pricing Services..
  pricingList(id) {
    return axios.get(`${API_URL}contract/${id}`, { headers: authHeader() });
  },
  getPackageList(id) {
    return axios.get(`${API_URL}package-list?id=${id}`, {
      headers: authHeader(),
    });
  },
  getAddOnList(id) {
    return axios.get(`${API_URL}item-list?id=${id}`, {
      headers: authHeader(),
    });
  },
  getOnePackage(id) {
    return axios.get(`${API_URL}package/${id}`, {
      headers: authHeader(),
    });
  },

  addPackage(val) {
    return axios.post(`${API_URL}add-package`, val, {
      headers: authHeader(),
    });
  },
  editPackage(val, id) {
    return axios.put(`${API_URL}update-package/${id}`, val, {
      headers: authHeader(),
    });
  },
  addAddOn(val) {
    return axios.post(`${API_URL}add-item`, val, {
      headers: authHeader(),
    });
  },
  editAddOn(id, val) {
    return axios.put(`${API_URL}update-item/${id}`, val, {
      headers: authHeader(),
    });
  }, //is_active
  changePackageStatus(id, val) {
    return axios.post(`${API_URL}item/statusChange/${id}/:type=${val}`, {
      headers: authHeader(),
    });
  },
  changeAddOnStatus(id, val) {
    return axios.put(
      `${API_URL}item/statusChange/${id}/${val}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },

  // Team List.. team-list
  getTeamList() {
    return axios.get(`${API_URL}team-list`, {
      headers: authHeader(),
    });
  },
  getActiveTeam() {
    return axios.get(`${API_URL}teams`, {
      headers: authHeader(),
    });
  },
  addTeam(val) {
    return axios.post(`${API_URL}add-team`, val, {
      headers: authHeader(),
    });
  },
  getActiveRoles() {
    return axios.get(`${API_URL}roles`, {
      headers: authHeader(),
    });
  },
  updateTeam(id, val) {
    return axios.put(`${API_URL}update-team/${id}`, val, {
      headers: authHeader(),
    });
  },
  deleteTeam(id, val) {
    return axios.put(
      `${API_URL}delete-team/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },
  updateTeamStatus(id, val) {
    return axios.put(`${API_URL}team/statusChange/${id}/${val}`, {
      headers: authHeader(),
    });
  },
  getOneTeam(id) {
    return axios.get(`${API_URL}team/${id}`, {
      headers: authHeader(),
    });
  },

  // Setting Data List.. Setting Data-list
  getSettings() {
    return axios.get(`${API_URL}setting-list`, {
      headers: authHeader(),
    });
  },

  getRoles() {
    return axios.get(`${API_URL}role-list`, {
      headers: authHeader(),
    });
  },

  updateSettings(val) {
    return axios.put(`${API_URL}update-setting`, val, {
      headers: authHeader(),
    });
  },

  updateSubscription(val) {
    return axios.put(`${API_URL}update-subscription-plan`, val, {
      headers: authHeader(),
    });
  },


  /////Project links
  addEventTeam(val) {
    return axios.post(`${API_URL}add-eventTeam`, val, {
      headers: authHeader(),
    });
  },
  addChecklist(val) {
    return axios.post(`${API_URL}add-checklist`, val, {
      headers: authHeader(),
    });
  },
  deleteEventTeam(id, val) {
    return axios.put(`${API_URL}statusChange-eventTeam/` + id, val, {
      headers: authHeader(),
    });
  },
  updateProjectStatus(id, val) {
    return axios.put(`${API_URL}booking/statusChange/` + id, val, {
      headers: authHeader(),
    });
  },
  // Notifications

  getNotifications() {
    return axios.get(`${API_URL}notifications`, {
      headers: authHeader(),
    });
  },

  readAllNotifications() {
    return axios.put(
      `${API_URL}notifications/all`,
      {},
      {
        headers: authHeader(),
      }
    );
  },

  readOneNotification(id) {
    return axios.put(
      `${API_URL}notifications/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },

  deleteAllNotifications() {
    return axios.put(
      `${API_URL}notifications/delete/all`,
      {},
      {
        headers: authHeader(),
      }
    );
  },

  deleteOneNotification(id) {
    return axios.put(
      `${API_URL}notifications/delete/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },

  // Checklists

  getCheckList(id) {
    return axios.get(`${API_URL}checklist/${id}`, {
      headers: authHeader(),
    });
  },

  addCheckList(val) {
    return axios.post(`${API_URL}add-checklist`, val, {
      headers: authHeader(),
    });
  },
  changeCheckList(id,val){
    return axios.put(`${API_URL}edit-checklist/${id}`, val, {
      headers: authHeader(),
    });
  },

  editCheckList(id, val) {
    return axios.put(`${API_URL}statusChange-checklist/${id}`, val, {
      headers: authHeader(),
    });
  },
};

export default UserService;
