import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import loaderGif from "../../../assets/img/loading.gif";

const Edit = ({ open, close }) => {
  const [category, setCategory] = useState();
  const [customerForm, setCustomerForm] = useState([]);
  const [formData, setformData] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [message ,setMessgae]= useState()
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [saveButton, setSaveButton] = useState(false);

  const getCategory = async () => {
    try {
      let res = await UserService.getCategoryList(`${userId}`);
      if (res?.data?.success === true) {
        // toast.success(res?.data?.message, { autoClose: 2000 });
        // console.log(res?.data);
        setCategory(res?.data?.result);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getCategory();
    getCutomerForm();
  }, []);
  
  useEffect(() => {
   console.log(state?.message)
   setMessgae(`${state?.message}`)
   setServiceType(state?.service_type)
  }, [state]);

  

  // console.log("templateData==", templateData?.subject);
  console.log("stateData==", state);

  const initialValues = {
    subject: state?.subject ? state?.subject : "",
    message: state?.message ? state?.message : "",
    autoResponder: state?.automate_response ? state?.automate_response : false,
    category: state?.key ? state?.key : "",
    service_type: state?.service_type ? state?.service_type : "",
    bookingLink: state?.booking_link ? state?.booking_link : false,
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("Template name is required"),
    message: Yup.string().required("Message is required"),
  });

  const getCutomerForm = async () => {
    try {
      const res = await UserService.getCustomerForm(userId);
      if (res?.data?.status === 200) {
        setCustomerForm(res?.data?.data?.list);
        let formArrr = [];
        for (let formData of res?.data?.data?.list) {
          let key = formData._id;
          let label = formData.label;
          formArrr.push({ id: key, value: label });
        }
        setformData(formArrr);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  const handleSelectChange = async (e) => {
if(e.target.value != ""){

      setMessgae(`${message} {${e.target.value}}`)
    }       
  }
  const handleChangeCategory = async(e)=>{
   setServiceType(e.target.value)
  }

 
  const onSubmit = async (values) => {
    setSaveButton(true);
    const data = {
      subject: values?.subject,
      message: message,
      automate_response: values?.autoResponder? true : false,
      service_type: values?.autoResponder?serviceType : "",
      booking_link: values?.bookingLink ? values?.bookingLink : false,
    };
    try {
      let res = await UserService.editTemplate(id, data);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/settings", { state: { settingTab: "response" } });
        }, 1000);
      } else {
        setSaveButton(false);
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      setSaveButton(false);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Edit Response Template</h3>
            <div className="space-sec mt-0">
              <div className="inner-title">
                <div className="back-wrap">
                  <NavLink to="/settings" state={{ settingTab: "response" }}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                        stroke="#2E1885"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Response Templates
                  </NavLink>
                </div>
              </div>
              <div className="booking-dtl-cls space-sec">
                <div className="client-dtl">
                  <h4></h4>
                  <div className="card">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Template Name</label>
                            <input
                              type="text"
                              name="subject"
                              onChange={handleChange}
                              value={values?.subject}
                              className="form-control"
                              autoComplete="off"
                            />
                            <p className="error-msg mt-2">
                              <ErrorMessage
                                className="error-msg"
                                name="subject"
                              />
                            </p>
                          </div>
                          <div className="form-group">
                            <div className="message-label d-flex  justify-content-between">
                              <label>Message</label>
                              {/* <NavLink
                                // to="javascript:void(0)"
                                onClick={() => {
                                  setinsertModel(true);
                                }}
                              >
                                Insert Placeholder
                              </NavLink>
                               */}
                               <select defaultValue="Insert Placeholder" style={{ width: 200 }} onChange={(e) => handleSelectChange(e)}>
                            <option value = "">Insert Placeholder</option>
                               {formData.map((inquiry) => 
                               <option value={inquiry.value}>{inquiry.value}</option>
                               )}
                              
                             </select>                             

                            </div>
                            <ReactQuill
                              name="message"
                              value={message }
                              onChange={(e) =>setMessgae( e)}
                                // setFieldValue("message", e)}
                              style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="message"
                              />
                            </p>
                          </div>
                          <div className="booking-link-main d-flex justify-content-between mt-50">
                            <div className="booking-link">
                              <h5>Booking Link</h5>
                              <p>
                                Include a link to the booking form after the
                                message.{" "}
                              </p>
                            </div>
                            <div className="form-check form-switch form-switch-sm">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="bookingLink"
                                id="flexSwitchCheckChecked1"
                                value={values.bookingLink}
                                checked={values.bookingLink === true}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="autoresponder-wrap">
                            <div className="autoresponder-title d-flex justify-content-between">
                              <h5>Autoresponder</h5>
                              <div
                                className="form-check form-switch form-switch-sm"
                                htmlFor="autoResponder"
                              >
                                <input
                                  id="autoResponder"
                                  name="autoResponder"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={values.autoResponder? true: false}
                                  checked={values.autoResponder === true}
                                  onChange={handleChange}
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="autoResponder"
                                  />
                                </p>
                              </div>
                            </div>
                            <p>
                              Automatically send this response when someone
                              inquires about:
                            </p>
                            {values.autoResponder === true ? (
                              <>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        id="category"
                                        name="category"
                                        onChange={handleChangeCategory}
                                        value={serviceType}
                                        required="required"
                                      >
                                        {/* values?.service_type */}
                                        <option value={""}>
                                          Select Any Type
                                        </option>
                                        {category?.map((val, index) => (
                                          <option value={val?.category_name} key={index}>
                                            {val?.category_name}
                                          </option>
                                        ))}
                                      </select>
                                      <p className="error-msg mt-2">
                                        <ErrorMessage
                                          className="error-msg"
                                          name="category"
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="add-new">
                            <button
                              type="submit"
                              className="btn btn_primary w-100"
                              disabled={saveButton}
                            >
                              Save Template{" "}
                              {saveButton ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
