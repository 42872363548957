import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import LogoImg from "../../assets/img/logo.png";
import EditIcon from "../../assets/img/edit.svg";
import DeleteIcon from "../../assets/img/deleteIcon.svg";
import UserImg from "../../assets/img/team-img.png";
import ArrowUp from "../../assets/img/arrow-up.svg";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";
import calenderImg from "../../assets/img/calendar.svg";
import watchImg from "../../assets/img/watch.svg";
import mapImg from "../../assets/img/map.svg";

import moment, { parseTwoDigitYear } from "moment";

const GenrateContract = () => {
  useEffect(() => {}, []);
  const { photographerid, contractId } = useParams();
  const navigate = useNavigate();

  const [photographerData, setPhotographerData] = useState({});
  const [contractDatas, setcontractDatas] = useState();
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [btndisabled, setButtonDisabled] = useState(false);

  const getPhotographer = async () => {
    try {
      const res = await UserService.getPhotographerDetails(photographerid);
      if (res?.data?.success === true) {
        setPhotographerData(res?.data?.result);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const getContractDetails = async () => {
    try {
      const res = await UserService.getContractDetails(contractId);
      if (res?.data?.status === 200) {
        setcontractDatas(res?.data?.data);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const handleChange = (e) => {
    if (e.target.checked) {
      setCheckBoxVal(true);
    } else {
      setCheckBoxVal(false);
    }
  };
  const handleSubmit = async () => {
    if (checkBoxVal == false) {
      toast.error("Please select accept terms and conditions.", {
        autoClose: 2000,
      });
      return false;
    }
    let jsonArr = {
      photographer_id: photographerid,
      booking_id: contractDatas?.booking_id,
    };
    try {
      setButtonDisabled(true);
      const res = await UserService.sendTransaction(jsonArr);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/transaction-thanks");
        }, 1000);
      } else {
        setButtonDisabled(false);
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      setButtonDisabled(false);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  let totalAddOns = 0;
  contractDatas?.detail?.itemData?.map(
    (item) => (totalAddOns += parseFloat(item?.item_price))
  );
  useEffect(() => {
    getPhotographer();
    getContractDetails();
  }, []);
  console.log("contractDatas", contractDatas);
  return (
    <>
      <ToastContainer position="top-right" />
      <div className="pageMainWraper">
        <div className="container">
          <div className="pageHeader text-center">
            <div className="photoGrapherOuter">
              <div className="proImgOuter rounded-circle overflow-hphotographeridden mx-auto d-flex align-items-center justify-content-center">
                {photographerData?.photographerData?.companyLogo == null ? (
                  <>
                    <img
                      className="w-100 h-100"
                      src={LogoImg}
                      alt="photographer-logo"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="w-100 h-100"
                      src={photographerData?.photographerData?.companyLogo}
                      alt="photographer-logo"
                    />
                  </>
                )}
              </div>
              <div className="photographerName">
                {photographerData?.photographerData?.companyName}
              </div>
              <NavLink
                to={photographerData?.photographerData?.companySite}
                className="btn btnVisitBtn border-0 shadow-none"
                target="_blank"
              >
                Visit Website
              </NavLink>
            </div>
          </div>
          <div className="formMainOuter contractOuter">
            <div className="formInner text-start">
              <div className="bookingDetailMain">
                <div className="bookingDeatilHeader text-center bg-transparent mt-2">
                  <div className="pdfTitle fw-bold">Contract Agreement</div>
                  <div className="pera mt-1">
                    Please review and confirm the details of your agreement
                    below.
                  </div>
                </div>
                <div className="bookingDetailInner mt-sm-3 mt-2">
                  <div className="bookingDetailBody bg-white p-sm-5 p-4">
                    <div
                      className="details"
                      dangerouslySetInnerHTML={{
                        __html: contractDatas?.detail,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="bookingDetailFooter mt-5">
                  <div className="form-check d-flex align-items-center gap-3 p-0 m-0 justifyc-ntent-center">
                    <input
                      className="form-check-input shadow-none m-0"
                      type="checkbox"
                      id="checkedBox_11"
                      name={"checkedBox_11"}
                      checked={checkBoxVal}
                      onClick={(event) => handleChange(event)}
                      photographerid="flexCheckDefault"
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor="checkedBox_11"
                    >
                      I accept the terms of this contract.
                    </label>
                  </div>
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btnSubmit border-0 shadow-none px-lg-5 px-sm-4 px-3 rounded-pill"
                      onClick={handleSubmit}
                      disabled={btndisabled}
                    >
                      Accept Contract
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pageFooter text-center">
            <div className="footerOuter">
              <div className="title">Powered by</div>
              <div className="companyLogo mx-auto">
                <img className="w-100" src={LogoImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GenrateContract;
