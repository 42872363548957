import React, { useEffect, useState } from "react";
import { Modal, NavLink } from "react-bootstrap";
import RequestSentModal from "./RequestSentModal";
import UserService from "../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ContractModal = ({ open, close, bookingId }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [list, setList] = useState();
  const navigate = useNavigate();

  const getContractList = async () => {
    try {
      let res = await UserService.getContractList();
      if (res?.data?.status === 200) {
        console.log(res?.data);
        // toast.success(res?.data?.message, { autoClose: 2000 });
        setList(res?.data?.data?.list);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getContractList();
  }, []);

  const openRequestModal = () => {
    setRequestSent(true);
  };
  const closeRequestModal = () => {
    setRequestSent(false);
    close();
    navigate("/bookings");
  };

  const generateContract = async (id) => {
    let data = { contractId: `${id}` };
    try {
      let res = await UserService.generateContract(data, bookingId);
      if (res?.data?.status === 200) {
        navigate(
          "/bookings/booking-detail/contract-preview/" + res?.data?.data?._id
        );
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade customModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close mt-1 me-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Select Contract</h4>
            <div className="contract_list">
              {list?.length > 0
                ? list?.map((item, index) => (
                    <div className="single-contract" key={index}>
                      <NavLink
                        to="#"
                        onClick={() => generateContract(item?._id)}
                      >
                        <div className="contract_ic">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.625 1.5C4.58947 1.5 3.75 2.33947 3.75 3.375V20.625C3.75 21.6605 4.58947 22.5 5.625 22.5H18.375C19.4105 22.5 20.25 21.6605 20.25 20.625V12.75C20.25 10.6789 18.5711 9 16.5 9H14.625C13.5895 9 12.75 8.16053 12.75 7.125V5.25C12.75 3.17893 11.0711 1.5 9 1.5H5.625ZM7.5 15C7.5 14.5858 7.83579 14.25 8.25 14.25H15.75C16.1642 14.25 16.5 14.5858 16.5 15C16.5 15.4142 16.1642 15.75 15.75 15.75H8.25C7.83579 15.75 7.5 15.4142 7.5 15ZM8.25 17.25C7.83579 17.25 7.5 17.5858 7.5 18C7.5 18.4142 7.83579 18.75 8.25 18.75H12C12.4142 18.75 12.75 18.4142 12.75 18C12.75 17.5858 12.4142 17.25 12 17.25H8.25Z"
                              fill="#FE5F55"
                            />
                            <path
                              d="M12.9712 1.8159C13.768 2.73648 14.25 3.93695 14.25 5.25V7.125C14.25 7.33211 14.4179 7.5 14.625 7.5H16.5C17.8131 7.5 19.0135 7.98204 19.9341 8.77881C19.0462 5.37988 16.3701 2.70377 12.9712 1.8159Z"
                              fill="#FE5F55"
                            />
                          </svg>
                        </div>
                        <div className="contact_cont">
                          <h5>{item?.title}</h5>
                        </div>
                      </NavLink>
                    </div>
                  ))
                : "No Contract Found!"}
            </div>
          </div>
        </div>
      </Modal>
      {requestSent && (
        <RequestSentModal open={openRequestModal} close={closeRequestModal} />
      )}
    </>
  );
};

export default ContractModal;
