import React, { useEffect, useState } from "react";
import UserService from "../services/UserService";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";

const TemplateModal = ({ open, close }) => {
  const [category, setCategory] = useState();
  const userId = localStorage.getItem("userId");
  const getCategory = async () => {
    try {
      let res = await UserService.getCategoryList(`${userId}`);
      if (res?.data?.success === true) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        // console.log(res?.data);
        setCategory(res?.data?.result);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const initialValues = {
    subject: "",
    message: "",
    autoResponder: false,
    category: "",
    key: "",
    bookingLink: false,
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("Template name is required"),
    message: Yup.string().required("Message is required"),
    category: Yup.string().required("Category is required"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const onSubmit = async (values) => {
    const data = {
      subject: values?.subject,
      message: values?.message,
      automate_response: values?.autoResponder,
      key: values?.key,
      booking_link: values?.bookingLink ? values?.bookingLink : false,
    };
    try {
      let res = await UserService.addTemplate(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        close();
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        onHide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close mt-1 me-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <div className="booking-dtl-cls space-sec">
              <div className="client-dtl">
                <h4>Add Response Template</h4>
                <div className="card">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Template Name</label>
                          <input
                            type="text"
                            name="subject"
                            onChange={handleChange}
                            value={values?.subject}
                            className="form-control"
                            autoComplete="off"
                          />
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="subject"
                            />
                          </p>
                        </div>

                        <div className="form-group">
                          <div className="message-label d-flex  justify-content-between">
                            <label>Message</label>
                            {/* <NavLink to="#">Insert Placeholder</NavLink> */}
                          </div>
                          <ReactQuill
                            name="message"
                            value={values?.message}
                            onChange={(e) => setFieldValue("message", e)}
                          />
                          <p className="error-msg">
                            <ErrorMessage
                              className="error-msg"
                              name="message"
                            />
                          </p>
                        </div>
                        <div className="booking-link-main d-flex justify-content-between">
                          <div className="booking-link">
                            <h5>Booking Link</h5>
                            <p>
                              Include a link to the booking form after the
                              message.{" "}
                            </p>
                          </div>
                          <div className="form-check form-switch form-switch-sm">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="bookingLink"
                              id="flexSwitchCheckChecked1"
                              value={values.bookingLink}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="autoresponder-wrap">
                          <div className="autoresponder-title d-flex justify-content-between">
                            <h5>Autoresponder</h5>
                            <div
                              className="form-check form-switch form-switch-sm"
                              htmlFor="autoResponder"
                            >
                              <input
                                id="autoResponder"
                                name="autoResponder"
                                className="form-check-input"
                                type="checkbox"
                                value={values.autoResponder}
                                onChange={handleChange}
                              />
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="autoResponder"
                                />
                              </p>
                            </div>
                          </div>
                          <p>
                            Automatically send this response when someone
                            inquires about:
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  id="category"
                                  name="category"
                                  onChange={handleChange}
                                >
                                  <option>Selet Any Type</option>
                                  {category?.map((val, index) => (
                                    <option value={val?._id} key={index}>
                                      {val?.category_name}
                                    </option>
                                  ))}
                                </select>
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="category"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="add-new">
                          <button
                            type="submit"
                            className="btn btn_primary w-100"
                          >
                            Save Template
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TemplateModal;
