import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Active from "./Active";
import Completed from "./Completed";
import ContentLoader from 'react-content-loader';
import UserService from "../../services/UserService";
import moment from "moment";

const Projects = () => {
  const [projectData, setProjectData] = useState([]);
  const [listType, setListType] = useState("active");
  const [loader, setLoader] = useState(false);
  const [selectFromDate, setSelectFromDate] = useState();
  const [selectToDate, setSelectToDate] = useState();
  const getProjectList = async () => {
    setProjectData([]);
    try {
      let res = await UserService.getProjectList(listType);
      if (res?.data?.status === 200) {
        setProjectData(res?.data?.data?.list?.docs);
        setLoader(false)
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1500 });
    }
  };

  useEffect(() => {
    getProjectList();
    setLoader(true)

  }, [listType]);

  console.log(">>>>>>>>>>>>>>>>>>projectData>>>>>>>>>>>>>>>>>>>", projectData);

  return (
    <>
     {/* {loader? <ContentLoader/>: */}
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Projects</h3>
            <div className="space-sec mt-0">
              <div className="inner-title">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-active-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-active"
                      type="button"
                      role="tab"
                      aria-controls="nav-active"
                      aria-selected="true"
                      onClick={() => setListType("active")}
                    >
                      Active
                    </button>
                    <button
                      className="nav-link"
                      id="nav-completed-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-completed"
                      type="button"
                      role="tab"
                      aria-controls="nav-completed"
                      aria-selected="false"
                      onClick={() => setListType("completed")}
                    >
                      Completed
                    </button>
                  </div>
                </nav>
                {/* <div className="filter-wrap">
                  <div className="filter-btn">
                    <input
                      type="date"
                      id="start"
                      name="trip-start"
                      value={selectFromDate}
                      onChange={(e) => setSelectFromDate(e.target.value)}
                      min={new Date()}
                    />
                    <input
                      type="date"
                      id="start"
                      name="trip-start"
                      value={selectToDate}
                      onChange={(e) => setSelectToDate(e.target.value)}
                      min={new Date()}
                    />
                  </div>
                </div> */}
              </div>
              <div className="tab-inner-cont">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="nav-active"
                    role="tabpanel"
                    aria-labelledby="nav-active-tab"
                  >
                   {loader? <ContentLoader/>: <Active data={projectData} />}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-completed"
                    role="tabpanel"
                    aria-labelledby="nav-completed-tab"
                  >
                    {loader? <ContentLoader/>: <Completed data={projectData} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
