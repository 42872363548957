import * as Yup from "yup";
import { Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { Formik, ErrorMessage, Field, FieldArray, getIn } from "formik";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DeleteIcon from "../../assets/img/deleteIcon.svg";
import PlusIcon from "../../assets/img/plus.svg";
import SelectField from "../project/CustomSelect";
import ReactQuill from "react-quill";
import loaderGif from "../../assets/img/loading.gif";

const AddEventTeamModel = ({
  id,
  open,
  close,
  currentEvent,
  teamList,
  settingData,
  index,
  roles,
}) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const teamData = currentEvent?.eventTeamDetails
    ? currentEvent?.eventTeamDetails[index]
    : null;
  console.log(
    ">>>>>>>>>>>>>>>>>>>>currentEvent>>>>>>>>>>>>>>>>>>>>",
    currentEvent
  );

  let editRoles = [];
  teamData?.role?.map((role) =>
    editRoles.push({ label: role.role_name, value: role._id })
  );

  let checkArr = [];
  teamData?.checklistData?.map((checklist) =>
    checkArr.push({ _id: checklist._id, name: checklist.name })
  );

  const initialValues = {
    _id: teamData ? teamData._id : null,
    team_id: teamData ? teamData?.TeamDetails[0]._id : null,
    role: teamData ? editRoles : [],
    checklist: teamData ? checkArr : [],
    additional_fees: teamData ? teamData.additional_fees : 0,
    start_time: teamData ? teamData.start_time : "",
    end_time: teamData ? teamData.end_time : "",
    notes: teamData ? teamData.notes : "",
  };
  const [teamDeatail, setTeamDeatail] = useState(
    teamData ? teamData?.TeamDetails[0] : null
  );
  let options = [];
  roles?.map((role) =>
    options.push({ label: role.role_name, value: role._id })
  );
  const validationSchema = Yup.object().shape({
    team_id: Yup.string().max(255).required("Team member is required!"),
    start_time: Yup.string().max(255).required("Start time is required!"),
    end_time: Yup.string().max(255).required("End time is required!"),
    notes: Yup.string().max(255).required("Notes is required!"),
    role: Yup.array().min(1, "Select at least one role"),
    checklist: Yup.array().of(
      Yup.object().shape({
        checkname: Yup.string()
          .max(255, "Enter up to 255 characters")
          .required("Checklist is required"),
      })
    ),
    additional_fees: Yup.number()
      .max(10000, "Additional fees must be less than 10000")
      .required("Additional fees is required!"),
  });
  const onSubmit = async (values) => {
    setButtonDisabled(true)
    let data_11;
    if (index == null) {
      let checkArr = [];
      let roleArr = [];

      values?.role?.map((role) => roleArr.push(role.value));
      values?.checklist?.map((check) => checkArr.push(check.checkname));

      data_11 = {
        event_id: currentEvent?._id,
        team_id: values?.team_id,
        start_time: values?.start_time,
        end_time: values?.end_time,
        role: roleArr,
        //checklists: checkArr,
        notes: values?.notes,
        additional_fees: values?.additional_fees.toString(),
      };
      try {
        const res = await UserService.addEventTeam(data_11);
        console.log("res", res?.data);
        if (res?.data?.status === 201) {
          toast.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            close();
          }, 400);
          setButtonDisabled(false)
        } else {
          setButtonDisabled(false)
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        setButtonDisabled(false)
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
        console.log("error", error);
      }
    } else {
      let roleArr = [];
      values?.role?.map((role) => roleArr.push(role.value));
      data_11 = {
        team_id: values?.team_id,
        start_time: values?.start_time,
        end_time: values?.end_time,
        notes: values?.notes,
        role: roleArr,
        //checklists: values?.checklist,
        additional_fees: values?.additional_fees.toString(),
      };
      try {
        console.log("Shree ram", data_11);

        const res = await UserService.editEventTeam(values?._id, data_11);
        console.log("res", res?.data);
        if (res?.data?.status === 201) {
          toast.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            close();
          }, 400);
          setButtonDisabled(false)
        } else {
          setButtonDisabled(false)
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        setButtonDisabled(false)
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
        console.log("error", error);
      }
    }
  };
  const handelDelete = async (e) => {
    try {
      const confirmed = window.confirm("Are you sure you want to delete ?");
      if (confirmed) {
        let data = { is_delete: true };
        const res = await UserService.deleteEventTeam(teamData?._id, data);
        console.log("res", res?.data);
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            close();
          }, 400);
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close shadow-none mt-2 me-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Assign Team Member For Event: {currentEvent?.session_name} </h4>
            <div className="checklist-wrap">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  errors,
                }) => (
                  <form
                    className="add-team-member-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="add-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Team Member<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              id="team_id"
                              onChange={(e) => {
                                handleChange(e);
                                //getTeamDetails(e);
                              }}
                              onBlur={handleBlur}
                              value={values?.team_id}
                              name="team_id"
                            >
                              <option value={""}>Select Member</option>
                              {teamList?.data?.map((team, idx) => (
                                <option value={team._id} key={idx}>
                                  {team.firstName} {team.lastName}
                                </option>
                              ))}{" "}
                            </select>
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="team_id"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Role<span className="text-danger">*</span>
                            </label>
                            <Field
                              component={SelectField}
                              name="role"
                              options={options}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="error-msg">
                              <ErrorMessage className="error-msg" name="role" />
                            </p>
                          </div>
                        </div>
                        {settingData?.time_format == 24 ? (
                          <>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Start Time
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="time"
                                  name="start_time"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.start_time}
                                  autoComplete="off"
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="start_time"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  End Time<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="time"
                                  name="end_time"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.end_time}
                                  autoComplete="off"
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="end_time"
                                  />
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Start Time
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  name="start_time"
                                  className="form-control"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="hh:mm A"
                                  onChange={(time) =>
                                    setFieldValue(
                                      "start_time",
                                      moment(time).format("hh:mm A")
                                    )
                                  }
                                  value={values.start_time}
                                  autoComplete="off"
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="start_time"
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  End Time<span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  name="end_time"
                                  className="form-control"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="hh:mm A"
                                  onChange={(time) =>
                                    setFieldValue(
                                      "end_time",
                                      moment(time).format("hh:mm A")
                                    )
                                  }
                                  value={values.end_time}
                                  autoComplete="off"
                                />
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="end_time"
                                  />
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Additional Fee </label>
                            <input
                              type="number"
                              name="additional_fees"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.additional_fees}
                              min={0}
                              max={10000}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="additional_fees"
                              />
                            </p>
                          </div>
                        </div>{" "}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Notes<span className="text-danger">*</span>
                            </label>
                            <ReactQuill
                              name="notes"
                              value={values?.notes}
                              onChange={(e) => setFieldValue("notes", e)}
                              style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            />
                            <p className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="notes"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 d-none">
                          <div className="checklist-wrap">
                            <div className="checklist-tit">
                              <h5>Checklist Manage </h5>
                            </div>
                            <FieldArray name="checklist">
                              {({ remove, push }) => (
                                <div>
                                  <div className="add-new mb-3">
                                    <button
                                      onClick={() =>
                                        push({
                                          checkname: "",
                                        })
                                      }
                                      type="button"
                                      className="btn btn_primary"
                                    >
                                      Add New
                                    </button>
                                  </div>
                                  {values?.checklist.map((p, index_1) => (
                                    <div key={index_1}>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Checklist {index_1 + 1}</label>
                                          <div className="d-flex gap-2 align-items-center">
                                            <Field
                                              type="text"
                                              as={Form.Control}
                                              name={`checklist[${index_1}].name`}
                                              className="form-control"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <div
                                              onClick={() => remove(index_1)}
                                            >
                                              <img
                                                src={DeleteIcon}
                                                alt="Delete"
                                              />
                                            </div>
                                          </div>{" "}
                                          <ErrorMessage
                                            name={`checklist[${index_1}].name`}
                                            component={Form.Text}
                                            className="error-msg"
                                          />
                                        </div>
                                      </div>{" "}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                        <div className="add-new">
                          <button type="submit" disabled={isButtonDisabled} className="btn btn_primary">
                            {index == null ? (
                              <>Add Team Member{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}</>
                            ) : (
                              <>Update Team Member{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}</>
                            )}
                          </button>{" "}
                          {index == null ? null : (
                            <>
                              <button
                                onClick={(e) => handelDelete(e)}
                                type="button"
                                className="btn btn_primary "
                              >
                                Delete Member
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEventTeamModel;
