import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import { toast } from "react-toastify";
import ContentLoader from 'react-content-loader';
import { NavLink, useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import userImg from "../../assets/img/team-img.png";
import arrowLeft from "../../assets/img/arrow-right.svg";
import arrowRight from "../../assets/img/arrow-right.svg";
// saliderArrowLeft
import moment from "moment";
const { DateTime } = require('luxon');

const options = {
  rtl: "true",
  margin: 16,
  nav: true,
  dots: false,
  loop: false,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  navText: [`<img src=${arrowLeft}>`, `<img src=${arrowRight}>`],
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 1,
    },
    992: {
      items: 1,
    },
    1366: {
      items: 1,
    },
  },
};

const TeamDetail = () => {
  const [teamDetail, setTeamDetail] = useState();
  const [events, setEvents] = useState();
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const getOneTeam = async () => {
    try {
      let res = await UserService.getOneTeam(id);
      if (res?.data?.success === true) {
        setTeamDetail(res?.data?.result?.member_detail);
        setEvents(res?.data?.result?.event);
        setLoader(false)

      } else {
        setLoader(false)

        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getOneTeam();
    setLoader(true)

  }, []);
  const timeTaken = (time_1, time_2) => {
    const startDate = DateTime.fromFormat(time_1, 'hh:mm a');
    const endDate = DateTime.fromFormat(time_2, 'hh:mm a');
    let hoursDifference;
    if(startDate< endDate){
      hoursDifference = endDate.diff(startDate, 'hours').hours
    }else if(endDate <= startDate){
      const nextDayEndDate = endDate.plus({ days: 1 }); 
      hoursDifference = nextDayEndDate.diff(startDate, 'hours').hours;         
   }
    // let hoursDifference = endDate.diff(startDate, 'hours').hours;
    
    if(hoursDifference<1){
      hoursDifference = `${(hoursDifference *60).toFixed(2)} mins`
    }else{
      hoursDifference = `${hoursDifference.toFixed(2)} hours`

    }
    return hoursDifference;
};

  return (
    <>
      {loader? <ContentLoader/>:<div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Team</h3>
            <div className="space-sec mt-0">
              <div className="inner-title">
                <div className="back-wrap">
                  <NavLink to="/teams">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                        stroke="#2E1885"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Team
                  </NavLink>
                </div>
              </div>
              <div className="booking-dtl-cls">
                <div className="row">
                  <div className="col-md-12">
                    <div className="team-dtl">
                      <h4>Account Details</h4>
                      <div className="card">
                        <div className="client-info d-flex align-items-center">
                          <div className="user-img-wrap">
                            <div className="user-team-img">
                              <img
                                src={
                                  (teamDetail?.image !== null ||
                                    teamDetail?.image !== undefined ||
                                    teamDetail?.image !== " ") &&
                                  teamDetail?.image?.length > 3
                                    ? teamDetail?.image
                                    : userImg
                                }
                                alt="Image"
                              />
                            </div>
                          </div>
                          <div className="team-user-dtl">
                            <h5>
                              {teamDetail?.firstName} {teamDetail?.lastName}
                            </h5>
                            <p>
                              {teamDetail?.role?.map((role_1) => (
                                <>
                                  <span>{role_1.role_name}</span>
                                  {", "}
                                </>
                              ))}
                            </p>
                            <p className="price">
                              ${teamDetail?.hourly_rate}/hour
                            </p>
                          </div>
                          {/* <div className="user-dot">
                                <img src="assets/img/dot.svg" alt="Image" />
                              </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {events?.length > 0 ? (
                    <>
                      <div className="col-12 assigned-event-sec space-sec">
                        <h4>Assigned Events</h4>
                        <div className="row">
                          {events?.map((event, index) => (
                            <>
                              <div className="col-md-6">
                                <div className="client-dtl event-cls">
                                  <div className="card">
                                    <div className="user-event-header">
                                      <h6>
                                        {event?.client_data?.firstName}{" "}
                                        {event?.client_data?.lastName}
                                      </h6>
                                      <p className="fees">
                                        Fee: <span>${event?.client_data?.totalCharges }
</span>
                                      </p>
                                    </div>
                                    <OwlCarousel
                                      className="team-user-slider owl-carousel"
                                      {...options}
                                    >
                                      {event?.events?.map((event_11, index) => (
                                        <>
                                          <div className="item">
                                            <div className="event-card-body">
                                              <h3>
                                                Event:
                                                {
                                                  event_11?.event_id
                                                    ?.session_name
                                                }
                                              </h3>
                                              <ul className="event-dtl">
                                                <li>
                                                  <h5>Date</h5>
                                                  <h6>
                                                    {moment(
                                                      event_11?.event_id?.date
                                                    ).format(
                                                      "dddd, YYYY-MM-DD"
                                                    )}
                                                  </h6>
                                                </li>
                                                <li>
                                                  <h5>Time</h5>
                                                  <h6>
                                                    {event_11?.start_time} -{" "}
                                                    {event_11?.end_time}(
                                                    {timeTaken(event_11?.start_time, event_11?.end_time)})
                                                  </h6>
                                                </li>
                                                <li>
                                                  <h5>Rate Fee</h5>
                                                  <h6>${teamDetail?.hourly_rate}</h6>
                                                </li>
                                                <li>
                                                  <h5>Additional Fee</h5>
                                                  <h6>
                                                    ${event_11?.additional_fees}
                                                  </h6>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </OwlCarousel>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default TeamDetail;
