import React, { useEffect, useState } from "react";
import UserService from "../services/UserService";
import { toast } from "react-toastify";
import ContentLoader from 'react-content-loader';
import NotificationModal from "./NotificationModal";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Button, Dropdown } from "react-bootstrap";
import ConfirmModal from "./ConfirmModal";
import dataNotFound from "../assets/img/jobs_not_found.svg";

const Notification = () => {
  const [notifications, setNotifications] = useState();
  const [notificationId, setNotificationId] = useState();
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [notificationType, setnotificationType] = useState("One");
  const [notificationDetail, setNotificationDetail] = useState();
  const [confirm, setConfirm] = useState(false);
  const [viewNotification, setViewNotification] = useState(false);

  const getNotifications = async () => {
    // console.log(getPhotographer)
    try {
      let res = await UserService.getNotifications();
      if (res?.data?.status === 200) {
        setLoader(false)
        setNotifications(res?.data?.data?.list);
        
      } else {
        toast?.error(res?.data?.message, { autoClose: 2000 });
        setLoader(false)
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const readNotification = async (id) => {
    try {
      let res = await UserService.readOneNotification(id);
      if (res?.data?.status === 200) {

        toast?.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          setRefresh((prev) => !prev);
          // getPhotographer()
          getNotifications();
        }, 400);
      } else {
        toast?.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const readAllNotifications = async () => {
    try {
      let res = await UserService.readAllNotifications();
      if (res?.data?.status === 200) {
        toast?.success(res?.data?.message, { autoClose: 2000 });
        setRefresh((prev) => !prev);

        setTimeout(() => {
          getNotifications();
          // getPhotographer()
        }, 400);
      } else {
        toast?.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  // const getPhotographer = async () => {
  //   try {
  //     const res = await UserService.getPhotographer(id);
  //     if (res?.data?.success === true) {
  //       setPhotographerData(res?.data?.result);
  //       if(res?.data?.result.is_delete) {
  //         localStorage.removeItem("userToken");
  //         setTimeout(() => {
  //           navigate("/login");
  //         }, 800);
  //       }
  //     } 
  //   } catch (error) {
  //     // if (error?.response?.data?.status === 500) {
  //       toast.info("Logged Out", { autoClose: 1000 });
  //       localStorage.removeItem("userToken");
  //       setTimeout(() => {
  //         navigate("/login");
  //       }, 800);
  //     // }
  //     logOut()
  //     toast.error(error?.response?.data?.message, { autoClose: 2000 });
  //   }
  // };

  useEffect(() => {
    getNotifications();
    setLoader(true)

  }, [!confirm, refresh]);

  const openModal = (list, id) => {
    setNotificationDetail(list);
    readNotification(id);
    setViewNotification(true);
  };

  const closeModal = () => {
    setViewNotification(false);
  };

  const openConfirmModal = (id, type) => {
    setnotificationType(type);
    setNotificationId(id);
    setConfirm(true);
  };

  const closeConfirmModal = () => {
    setConfirm(false);
  };

  return (
    <>
      {confirm && (
        <ConfirmModal
          open={openConfirmModal}
          close={closeConfirmModal}
          id={notificationId}
          type={notificationType}
        />
      )}
      {loader? <ContentLoader/>:<div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <h3 className="breadcurm-title">Notifications</h3>
            {notifications?.length > 0 ? (
              <>
                <div className="d-flex align-items-end justify-content-end mb-4 mx-2">
                  <div className="notifi-dot">
                    <Dropdown className="bookingDrop">
                      <Dropdown.Toggle>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                              stroke="#0F172A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                              stroke="#0F172A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                              stroke="#0F172A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={Button}
                          onClick={() => readAllNotifications()}
                        >
                          Read All
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={Button}
                          onClick={() => openConfirmModal(null, "All")}
                        >
                          Delete All
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </>
            ) : null}
            <div className="space-sec mt-0">
              <div className="notificationMainOuter">
                {notifications?.length > 0 ? (
                  <>
                    <div className="notificationInner d-flex flex-column gap-3">
                      {notifications?.map((list, idx) => (
                        <div
                          className={`notificationItem ${
                            list?.isRead === false ? "unread" : ""
                          } rounded-3 border position-relative`}
                          key={idx}
                        >
                          <div className="row mx-0">
                            <div className="col-auto px-0">
                              <div className="imgOuter rounded-3 overflow-hidden">
                                <img
                                  className="w-100 h-100"
                                  src="https://www.w3schools.com/howto/img_avatar2.png"
                                  alt="Icon Image"
                                />
                              </div>
                            </div>
                            <div className="col px-0 ps-2">
                              <div className="d-flex align-items-center justify-content-between gap-3">
                                {list?.type == "Booking" ? (
                                  <>
                                    <NavLink
                                      to={`/bookings/booking-detail/${list?.table_id}`}
                                      className="text-decoration-none stretched-link title"
                                      onClick={() =>
                                        readNotification(list?._id)
                                      }
                                    >
                                      {list?.title}
                                    </NavLink>
                                  </>
                                ) : null}
                                {list?.type == "Transaction" ? (
                                  <>
                                    <NavLink
                                      to={"#"}
                                      className="text-decoration-none stretched-link title"
                                      onClick={() =>
                                        readNotification(list?._id)
                                      }
                                    >
                                      {list?.title}
                                    </NavLink>
                                  </>
                                ) : null}
                                {list?.type == "Enquiry" ? (
                                  <>
                                    <NavLink
                                      to={`/inquiries/inquiry-detail/${list?.table_id}`}
                                      className="text-decoration-none stretched-link title"
                                      onClick={() =>
                                        readNotification(list?._id)
                                      }
                                    >
                                      {list?.title}
                                    </NavLink>
                                  </>
                                ) : null}
                                {list?.type == "Account" ? (
                                  <>
                                    <NavLink
                                      // to={`/inquiries/inquiry-detail/${list?.table_id}`}
                                      className="text-decoration-none stretched-link title"
                                      // onClick={() =>
                                      //   readNotification(list?._id)
                                      // }
                                    >
                                      {list?.title}
                                    </NavLink>
                                  </>
                                ) : null}
                                {list?.type == "Welcome" ? (
                                  <>
                                    <NavLink
                                      // to={`/inquiries/inquiry-detail/${list?.table_id}`}
                                      className="text-decoration-none stretched-link title"
                                      // onClick={() =>
                                      //   readNotification(list?._id)
                                      // }
                                    >
                                      {list?.title}
                                    </NavLink>
                                  </>
                                ) : null}
                                <div className="d-flex align-items-center gap-3">
                                  <div className="notificationTime">
                                    {moment(list?.created_at).format("MMMM DD, YYYY h:mm A")}
                                  </div>
                                  <button
                                    type="button"
                                    className="btn-close bg-transparent border-0 shadow-none p-0 z-3"
                                    onClick={() =>
                                      openConfirmModal(list?._id, "One")
                                    }
                                  ></button>
                                </div>
                              </div>
                              <div className="pera">{list?.description}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center mt-5">
                      <img src={dataNotFound} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Notification;
