import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo-2.png";
import eyeOpen from "../../assets/img/eye.svg";
import eyeClose from "../../assets/img/eye-slash.svg";
import AuthCarousel from "../../components/AuthCarousel";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../services/AuthService";

const ResetPassword = () => {
  console.log("login");
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const [showPass, setShowPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Password must contain at least 8 characters, one digit, one special character, one uppercase letter, and one lowercase letter"
      )
      .required("Password is required")
      .trim(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Confirm Password is required!"),
  });

  const onSubmit = async (values) => {
    const data = {
      email: state,
      password: values?.password,
    };
    try {
      const res = await AuthService.resetPassword(data);
      console.log("res", res);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="login-sec">
        <div className="container-fluid p-0">
          <div className="login-inner">
            <Row className="m-0">
              <Col md="6" className="p-0">
                <AuthCarousel />
              </Col>
              <Col md="6" className="p-0">
                <div className="login-form">
                  <div className="login-form-cont">
                    <img src={logo} alt="" />
                    <h6>The simplest workflow tool for freelancers.</h6>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <div className="form-group pw-show-cls">
                              <label>Password</label>
                              <input
                                className="form-control"
                                placeholder=". . . . . . ."
                                type={showPass ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                autoComplete="off"
                              />
                              <div className="pw-show">
                                <img
                                  src={showPass ? eyeOpen : eyeClose}
                                  onClick={() => setShowPass(!showPass)}
                                />
                              </div>
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="password"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12">
                            <div className="form-group pw-show-cls">
                              <label>Confirm Password</label>
                              <input
                                className="form-control"
                                placeholder=". . . . . . ."
                                type={showConPass ? "text" : "password"}
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                autoComplete="off"
                              />
                              <div className="pw-show">
                                <img
                                  src={showConPass ? eyeOpen : eyeClose}
                                  onClick={() => setShowConPass(!showConPass)}
                                />
                              </div>
                              <p className="error-msg">
                                <ErrorMessage
                                  className="error-msg"
                                  name="confirmPassword"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="login-btn">
                          <button className="btn btn_primary" type="submit">
                            Reset
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
