import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import moment from "moment";
import ContentLoader from 'react-content-loader';

import ContractModal from "../../../components/ContractModal";
import OwlCarousel from "react-owl-carousel3";
import editIcon from "../../../assets/img/edit.svg";
import addIcon from "../../../assets/img/plus.svg";
import Edit from "./EditClientDetails";
import EditEventDetail from "./EditEventDetails";
import Add from "../add-booking/AddEvent";
import EditPackage from "./EditPackage";
import EditAttachment from "./EditAttachment";
import calenderImg from "../../../assets/img/calendar.svg";
import watchImg from "../../../assets/img/watch.svg";
import mapImg from "../../../assets/img/map.svg";
import AdditionalNotesModal from "../../../components/AdditionalNotesModal";
import PackageRequestedModal from "../../../components/PackageRequestedModal";

const EditBookinDetail = () => {
  const [openModal, setOpenModal] = useState();
  const [customerBooking, setCustomerBooking] = useState();
  const [clientData, setClientData] = useState();
  const [txnId, setTxnId] = useState();
  const [loader, setLoader] = useState(false);
  const [paid, setPaid] = useState(false);

  const [editAttachment, setEditAttachment] = useState(false);
  const [editPackage, setEditPackage] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [addEvent, setAddEvent] = useState(false);
  const [eventData, setEventData] = useState();
  const [editEventData, setEditEventData] = useState();
  const [packageData, setPackageData] = useState();
  const [attachmentData, setAttachmentData] = useState();
  const [linkData, setLinkData] = useState();
  const [itemData, setItemData] = useState();
  const { id } = useParams();
  const [viewAdditionalModal, setViewAdditionalModal] = useState(false);
  const [viewPackageRequestedModal, setViewPackageRequestedModal] =
    useState(false);
  const navigate = useNavigate();

  const getBookingDetail = async () => {
    try {
      let res = await UserService.getBooking(id);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.msg, { autoClose: 2000 });
        console.log("11", res?.data?.data?.details?.docs[0]);

        setPackageData(res?.data?.data?.details?.docs[0]?.packageData[0]);
        setEventData(res?.data?.data?.details?.docs[0]?.eventData);
        setPaid(res?.data?.data?.details?.docs[0]?.bookingStatus)
        setClientData(res?.data?.data?.details?.docs[0]?.clientData[0]);
        setAttachmentData(res?.data?.data?.details?.docs[0]?.attachment);
        setLinkData(res?.data?.data?.details?.docs[0]?.link);

        setItemData(res?.data?.data?.details?.docs[0]?.itemData);
        setCustomerBooking(res?.data?.data?.details?.docs[0]);
        setTxnId(res?.data?.data?.details?.docs[0]?.transactionId);
        setLoader(false)
      } 
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const markPaid = async () => {
    // setButtonDisabled(true)
    let value;
    if( paid == "unpaid"){
      value = "paid"
    }else if(paid == "paid"){
      value = "unpaid"
    }

    const data = {
      bookingStatus: value
    };
    // console.log(data);
    setPaid(value)

    try {
      const res = await UserService.updateBooking(id, data);
      if (res?.data?.status === 200) {
        toast.success(`Booking marked as ${res?.data?.data.bookingStatus}`, { autoClose: 500 });
        // setTimeout(() => {
        //   close();
        // }, 400);
        // setButtonDisabled(false)

      } else {
        // setButtonDisabled(false)
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      // setButtonDisabled(false)
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error", error);
    }
  };

  const payNow = async () => {
    if (window.confirm("Do you want to complete this booking ?")) {
      const data = {
        photographer_status: true,
        booking_id: `${id}`,
        // paymentStatus: paid? "paid": "unPaid"
      };
      console.log(paid, 82222)
      try {
        let res = await UserService.updateTransaction(txnId, data);
        console.log("res", res);
        if (res?.data?.status === 201) {
          toast.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            navigate("/inbox", { state: { booking: true } });
          }, 1000);
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.response?.message, { autoClose: 2000 });
      }
    }
  };

  const pkgBkgDtl = {
    hour_rate: customerBooking?.hours_rate,
    add_hours: customerBooking?.additionalHours,
  };

  useEffect(() => {
    getBookingDetail();
    setLoader(true);
  }, [!editClient, !editEvent, !editPackage, !addEvent, !editAttachment]);

  const openContract = () => {
   if(customerBooking.contractGenerate_date){ if (
      window.confirm(
        "Are you sure ? Any previous edits made to the contract will be lost."
      )
    ) {
      setOpenModal(true);
    }}else{
      setOpenModal(true);

    }
  };

  const closeContract = () => {
    setOpenModal(false);
  };

  const openEditClient = () => {
    setEditClient(true);
  };

  const closeEditClient = () => {
    setEditClient(false);
  };

  const openEditEvent = (data) => {
    setEditEventData(data);
    setEditEvent(true);
  };

  const closeEditEvent = () => {
    setEditEvent(false);
  };

  const openAddEvent = () => {
    setAddEvent(true);
  };

  const closeAddEvent = () => {
    setAddEvent(false);
  };

  const openEditPackage = () => {
    setEditPackage(true);
  };

  const closeEditPackage = () => {
    setEditPackage(false);
  };
  //Attachment
  const openEditAttachment = () => {
    setEditAttachment(true);
  };
  const closeEditAttachment = () => {
    setEditAttachment(false);
  };

  const eData = eventData?.sort((a, b) => {
    a.date.localeCompare(b.date);
    const nameComparison = a.date.localeCompare(b.date);
    if (nameComparison !== 0) {
      return nameComparison;
    }
    const timeComparison = a.startTime.localeCompare(b.startTime);
    if (timeComparison !== 0) {
      return timeComparison;
    }
  });

  const openAdditionalModal = () => {
    setViewAdditionalModal(true);
  };

  const closeAdditionalModal = () => {
    setViewAdditionalModal(false);
  };
  const openRequestedModal = () => {
    setViewPackageRequestedModal(true);
  };

  const closeRequestedModal = () => {
    setViewPackageRequestedModal(false);
  };
  const itemsTotal = () => {
    let itemsTotal_11 = 0;
    if (itemData?.length > 0) {
      itemData?.map((item, index) => {
        itemsTotal_11 += parseFloat(item.item_price);
      });
    }
    return itemsTotal_11;
  };
  // {
  //   customerBooking?.contractSigned_date
  //     ? " signed "
  //       ? customerBooking?.contractSent_date
  //       : " sent "
  //     : " generated ";
  // }
  const contractedStatus = () => {
    let a;
    if (customerBooking?.contractSigned_date) {
      a = " signed ";
    } else if (customerBooking?.contractSent_date) {
      a = " sent ";
    } else {
      a = " generated ";
    }
    return a;
  };
  return (
    <>
      {viewAdditionalModal && (
        <AdditionalNotesModal
          open={openAdditionalModal}
          close={closeAdditionalModal}
          data={customerBooking?.message}
        />
      )}
      {viewPackageRequestedModal && (
        <PackageRequestedModal
          open={openRequestedModal}
          close={closeRequestedModal}
          data={customerBooking?.package_requested}
        />
      )}
      {openModal && (
        <ContractModal
          open={openContract}
          close={closeContract}
          bookingId={id}
        />
      )}
      {addEvent && (
        <Add
          open={openAddEvent}
          close={closeAddEvent}
          bookingId={id}
          //teamId={teamId}
        />
      )}
      {editClient && (
        <Edit
          open={openEditClient}
          close={closeEditClient}
          bookingId={id}
          clientData={clientData}
        />
      )}
      {editEvent && (
        <EditEventDetail
          open={openEditEvent}
          close={closeEditEvent}
          bookingId={id}
          eventData={editEventData}
        />
      )}
      {editPackage && (
        <EditPackage
          open={openEditPackage}
          close={closeEditPackage}
          bookingId={id}
          packageData={packageData}
          addOns={itemData}
          pkgBkgDtl={pkgBkgDtl}
        />
      )}
      {editAttachment && (
        <EditAttachment
          open={openEditAttachment}
          close={closeEditAttachment}
          bookingId={id}
          attachmentData={attachmentData}
          linkData={linkData}
        />
      )}
      {loader ? <ContentLoader/>:<div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <div className="projectDetail">
              <div className="row">
                <div className="col-12">
                  <div className="inner-title mb-1">
                    <div className="back-wrap">
                      <NavLink to="/bookings">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                            stroke="#2E1885"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Bookings
                      </NavLink>
                    </div>
                  </div>
                  <div className="breadcurm-title">
                    <div className="breadcrum-left">
                      <h3 className="breadcurm-title my-2">
                        {clientData?.first_name + " " + clientData?.last_name}
                      </h3>
                      <div className="eventName p-1 px-3 rounded-pill d-inline-flex mb-4">
                        {customerBooking?.eventType[0]?.category_name}
                      </div>
                    </div>
                    <div className="breadcrum-right">
                      {customerBooking?.paymentStatus === "contract signed" ? (
                        <>
                          <div className="mark-complate mt-2">
                            <button
                              className="btn btn_primary rounded-pill"
                              onClick={() => payNow()}
                            >
                              Complete Booking
                            </button>
                          </div>
                        </>
                      ) : null}
                      {customerBooking?.contractData?.length == 0 ? (
                        <button
                          className="btn btn_primary"
                          onClick={() => openContract()}
                        >
                          Generate Contract
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="booking-dtl-cls">
                    <div className="row">
                      <div className="col-8">
                        <div className="row">
                          <div className="col-md-12 space-sec mt-0">
                            <div className="client-dtl event-cls">
                              <h4>
                                Event/Session
                                <img
                                  className="ml-12"
                                  src={addIcon}
                                  onClick={() => openAddEvent()}
                                />
                              </h4>
                              {eventData?.length > 0 ? (
                                <div className="row gap-3">
                                  {/* {eventData.sort((a, b) => new Date(a.date) - new Date(b.date))} */}
                                  {eData?.map((event, index) => (
                                    <div className="col-12" key={index}>
                                      <div className="card">
                                        <div className="session-header align-items-center">
                                          <h5 className="mb-0">
                                            {event?.session_name
                                              ? event?.session_name
                                              : "-"}
                                          </h5>
                                          <div className="mb-2 d-flex justify-content-start gap-4">
                                            <img
                                              src={editIcon}
                                              onClick={() =>
                                                openEditEvent(event)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="session-dtl">
                                          <div className="date-time-wrap">
                                            <div className="date-left">
                                              <span className="calendar-ic d-flex">
                                                <img
                                                  src={calenderImg}
                                                  alt="Calender Image"
                                                />
                                              </span>
                                              <div className="date">
                                                <p className="m-0 ms-2">
                                                  {event?.date
                                                    ? moment(
                                                        event?.date
                                                      ).format("MMMM DD, YYYY")
                                                    : "-"}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="time-right">
                                              <span className="wath-ic">
                                                <img
                                                  src={watchImg}
                                                  alt="Watch Image"
                                                />
                                              </span>
                                              <div className="time">
                                                <p className="m-0 ms-2">
                                                  {event?.startTime
                                                    ? event?.startTime
                                                    : "-"}{" "}
                                                  -{" "}
                                                  {event?.endTime
                                                    ? event?.endTime
                                                    : "-"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="location-wrap mt-2">
                                            <span className="map-ic">
                                              <img src={mapImg} alt="" />
                                            </span>
                                            <p className="m-0 ms-2">
                                              <NavLink
                                                target="_blank"
                                                to={
                                                  "https://www.google.com/maps/search/?api=1&query=" +
                                                  event?.venue +
                                                  "," +
                                                  event?.address +
                                                  "," +
                                                  event?.city +
                                                  "," +
                                                  event?.country
                                                }
                                              >
                                                {event?.venue},
                                                {event?.address},
                                               
                                                {event?.city}, {event?.state},
                                                {event?.country}
                                              </NavLink>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                            {customerBooking?.contractData?.length > 0 ? (
                              <>
                                <div className="contract-box">
                                  <h4>Contract</h4>
                                  <div className="file_wrap">
                                    <NavLink
                                      to={
                                        `/bookings/booking-detail/contract-preview/` +
                                        customerBooking?.contractData[0]._id
                                      }
                                    >
                                      {
                                        customerBooking?.clientData[0]
                                          ?.first_name
                                      }{" "}
                                      {
                                        customerBooking?.clientData[0]
                                          ?.last_name
                                      }{" "}
                                      -{" "}
                                      {
                                        customerBooking?.eventType[0]
                                          ?.category_name
                                      }{" "}
                                      Contract
                                      <p class="fst-italic m-0">
                                        Contract
                                        {contractedStatus()}
                                        on{" "}
                                        {customerBooking?.contractGenerate_date
                                          ? moment(
                                              customerBooking?.contractGenerate_date
                                            ).format("MMMM DD, YYYY")
                                          : null}
                                      </p>
                                    </NavLink>
                                    <button
                                      onClick={() => openContract()}
                                      className="btn btn_default ms-auto"
                                    >
                                      Regenerate Contract
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : null}
                             <>
                                <div className="contract-box">
                                  <h4>Deposit</h4>
                                  <div className="file_wrap">
                                   <p>{paid}</p>
                                    <button
                                      onClick={markPaid}
                                      className="btn btn_default ms-auto"
                                    >{
                                      paid == "paid" ? "Mark as Unpaid": "Mark as Paid"
                                    }
                                       
                                    </button>
                                  </div>
                                </div>
                              </>
                            {/* {customerBooking?.paymentStatus === "contract signed"?
                            <>
                            
                            <div className="booking-link">
                              <h5>Deposit</h5>
                                <div className="form-check p-0 mb-0">
                                  <div className="d-flex align-items-center justify-content-between gap-2">
                                    <input
                                      className="customCheck"
                                      type="checkbox"
                                      name="bookingLink"
                                      id="flexSwitchCheckChecked1"
                                      value={paid}
                                      onChange={() => setPaid(!paid)}
                                    />
                                    <h5>Mark as Paid.</h5>
                                  </div>
                                </div>
                              </div>
                            </> :null} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="client-dtl project-client-wrap">
                              <div className="card shadow-none bg-transparent p-0">
                                <div className="client-info flex-column gap-2">
                                  <div className="signle-client-info p-0 w-100 mt-1">
                                    <div className="clent-inner-dtl">
                                      <h4 className="d-flex justify-content-between">
                                        Client Details{" "}
                                        <span>
                                          <img
                                            src={editIcon}
                                            onClick={() => openEditClient()}
                                            alt="package-update"
                                          />
                                        </span>
                                      </h4>
                                      <div className="name-wrap">
                                        <h5 Name>Full Name</h5>
                                        <p>
                                          {clientData?.first_name}{" "}
                                          {clientData?.last_name}
                                        </p>
                                      </div>
                                      <div className="email-wrap">
                                        <h5>Email</h5>
                                        <a
                                          target="_blank"
                                          href={"mailto:" + clientData?.email}
                                        >
                                          {clientData?.email}
                                        </a>
                                      </div>
                                      <div className="phone-wrap">
                                        <h5>Mobile No.</h5>
                                        <a
                                          target="_blank"
                                          href={
                                            "tel:" + clientData?.mobileNumber
                                          }
                                        >
                                          {clientData?.mobileNumber}
                                        </a>
                                      </div>
                                      <div className="address-wrap">
                                        <h5>Address</h5>
                                        <p>
                                          {clientData?.address} <br />
                                          {clientData?.city},{" "}
                                          {clientData?.state} <br />
                                          {clientData?.country},
                                          {clientData?.postalCode} <br />
                                        </p>
                                      </div>
                                    </div>
                                    {customerBooking?.package_requested ==
                                      "" && (
                                      <>
                                        <div className="requested-service">
                                          <h4 className="d-flex justify-content-between">
                                            Requested Services
                                            <span>
                                              <img
                                                src={editIcon}
                                                onClick={() =>
                                                  openEditPackage()
                                                }
                                                alt="package-update"
                                              />
                                            </span>
                                          </h4>
                                          <div className="price-box">
                                            <ul>
                                              {packageData?.package_price >
                                              0 ? (
                                                <>
                                                  <li className="d-flex justify-content-between">
                                                    <span>
                                                      Essentials Package
                                                    </span>
                                                    <span className="price-font">
                                                      $
                                                      {
                                                        packageData?.package_price
                                                      }
                                                    </span>
                                                  </li>
                                                </>
                                              ) : null}
                                              <li className="d-flex justify-content-between">
                                                <span>Additional Options</span>
                                                <span className="price-font">
                                                  ${itemsTotal()}
                                                </span>
                                              </li>
                                              <li className="d-flex justify-content-between">
                                                <span>Addon Hours</span>
                                                <span className="price-font">
                                                  $
                                                  {parseInt(
                                                    customerBooking?.hours_rate
                                                  ) *
                                                    customerBooking?.additionalHours}
                                                </span>
                                              </li>
                                              <li className="total-price">
                                                <span>Total</span>
                                                <span className="price-font">
                                                  $
                                                  {parseInt(
                                                    customerBooking?.totalCharges
                                                  )}
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {customerBooking?.package_requested ? (
                                      <>
                                        <div className="col-md-12 mt-4">
                                          <div className="client-dtl additional-note">
                                            <h4 className="d-flex justify-content-between">
                                              Requested Services
                                            </h4>
                                            <div className="card shadow-none bg-transparent p-0">
                                              <div className="file_wrap">
                                                {
                                                  customerBooking?.package_requested
                                                }
                                              </div>
                                              {customerBooking
                                                ?.package_requested?.length >
                                              200 ? (
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={openRequestedModal}
                                                >
                                                  Read More
                                                </a>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div className="client-dtl attachment-wrap">
                              <h4>
                                Attachments
                                <span>
                                  <img
                                    src={editIcon}
                                    onClick={() => openEditAttachment()}
                                    alt="attachment-update"
                                  />
                                </span>
                              </h4>
                              <div className="card shadow-none bg-transparent p-0">
                                {customerBooking?.attachment?.length
                                  ? customerBooking?.attachment?.map(
                                      (attach, index_1) => (
                                        <div key={index_1}>
                                          <div className="file_wrap">
                                            <NavLink
                                              target="_blank"
                                              to={attach.attachment}
                                            >
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M4.6875 1.25C3.82456 1.25 3.125 1.94955 3.125 2.8125V17.1875C3.125 18.0504 3.82455 18.75 4.6875 18.75H15.3125C16.1754 18.75 16.875 18.0504 16.875 17.1875V10.625C16.875 8.89911 15.4759 7.5 13.75 7.5H12.1875C11.3246 7.5 10.625 6.80044 10.625 5.9375V4.375C10.625 2.64911 9.22589 1.25 7.5 1.25H4.6875Z"
                                                  fill="#0F172A"
                                                />
                                                <path
                                                  d="M10.8093 1.51325C11.4733 2.2804 11.875 3.28079 11.875 4.375V5.9375C11.875 6.11009 12.0149 6.25 12.1875 6.25H13.75C14.8442 6.25 15.8446 6.6517 16.6117 7.31567C15.8719 4.48323 13.6418 2.25315 10.8093 1.51325Z"
                                                  fill="#0F172A"
                                                />
                                              </svg>
                                              {attach.name}
                                            </NavLink>
                                          </div>
                                        </div>
                                      )
                                    )
                                  : null}

                                {customerBooking?.link?.length
                                  ? customerBooking?.link?.map(
                                      (links, index_2) => (
                                        <div key={index_2}>
                                          <div className="file_wrap gap-3">
                                            <NavLink
                                              target="_blank"
                                              to={links.link}
                                            >
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M16.5847 3.41529C15.3643 2.1949 13.3857 2.1949 12.1653 3.41529L8.41529 7.16529C7.1949 8.38568 7.1949 10.3643 8.41529 11.5847C8.67435 11.8438 8.96648 12.0471 9.27752 12.1957C9.58899 12.3445 9.72086 12.7176 9.57207 13.0291C9.42328 13.3405 9.05018 13.4724 8.73871 13.3236C8.30141 13.1147 7.89219 12.8294 7.53141 12.4686C5.82286 10.76 5.82286 7.98995 7.53141 6.28141L11.2814 2.53141C12.99 0.822864 15.76 0.822864 17.4686 2.53141C19.1771 4.23995 19.1771 7.01005 17.4686 8.71859L16.0045 10.1827C15.7604 10.4268 15.3646 10.4268 15.1206 10.1827C14.8765 9.93866 14.8765 9.54293 15.1206 9.29885L16.5847 7.83471C17.8051 6.61432 17.8051 4.63568 16.5847 3.41529ZM10.4279 6.97095C10.5767 6.65948 10.9498 6.52761 11.2613 6.67639C11.6986 6.8853 12.1078 7.17062 12.4686 7.53141C14.1771 9.23995 14.1771 12.01 12.4686 13.7186L8.71859 17.4686C7.01005 19.1771 4.23995 19.1771 2.53141 17.4686C0.822864 15.76 0.822864 12.99 2.53141 11.2814L3.99555 9.81727C4.23963 9.57319 4.63536 9.57319 4.87943 9.81727C5.12351 10.0613 5.12351 10.4571 4.87943 10.7011L3.41529 12.1653C2.1949 13.3857 2.1949 15.3643 3.41529 16.5847C4.63568 17.8051 6.61432 17.8051 7.83471 16.5847L11.5847 12.8347C12.8051 11.6143 12.8051 9.63568 11.5847 8.41529C11.3257 8.15623 11.0335 7.95289 10.7225 7.80431C10.411 7.65552 10.2791 7.28241 10.4279 6.97095Z"
                                                  fill="#0F172A"
                                                />
                                              </svg>
                                              {links.name}
                                            </NavLink>
                                              <p className="fst-italic m-0">
                                                {links.detail}
                                              </p>
                                          </div>
                                        </div>
                                      )
                                    )
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div className="client-dtl additional-note">
                              <h4>Additional Notes</h4>
                              <div className="card shadow-none bg-transparent p-0">
                                <div className="file_wrap">
                                  {customerBooking?.message}
                                </div>
                                {customerBooking?.message?.length > 200 ? (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={openAdditionalModal}
                                  >
                                    Read More
                                  </a>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};
export default EditBookinDetail;
