import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserService from "../../../services/UserService";
import loaderGif from "../../../assets/img/loading.gif";
import { useNavigate } from "react-router-dom";

const ContractPreview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [contractPreview, setcontractPreview] = useState();
  const getContractDetails = async () => {
    try {
      let res = await UserService.getContractDetails(id);
      if (res?.data?.status === 200) {
        console.log(res?.data?.data, 1888)
        setcontractPreview(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const sendContractToCustomer = async () => {
    setButtonDisabled(true)
    const confirmed = window.confirm(
      "Do you want to share contract with " +
        contractPreview?.booking_id.client_id.first_name +
        " " +
        contractPreview?.booking_id.client_id.last_name +
        " ?"
    );
    if (confirmed) {
      try {
        let res = await UserService.sendContractToCustomer(id);
        if (res?.data?.status === 200) {

          toast.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            navigate(
              "/bookings/booking-detail/" + contractPreview?.booking_id._id
            );
          }, 1000);
          setButtonDisabled(false)
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
          setButtonDisabled(false)

        }
      } catch (error) {
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)

      }
    }
  };
  useEffect(() => {
    getContractDetails();
  }, []);
  const itemsTotal = () => {
    let itemsTotal_11 = 0;
    if (contractPreview?.detail?.itemData?.length > 0) {
      contractPreview?.detail?.itemData?.map((item, index) => {
        console.log("sdfsdf", item?.item_price);
        itemsTotal_11 += parseFloat(item?.item_price);
      });
    }
    return itemsTotal_11;
  };
  const completeUrl = window.location.pathname;
  let redirectUrl = "/bookings/booking-detail/";
  if (completeUrl.includes("projects/project-detail")) {
    redirectUrl = "";
  }
  return (
    <>
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <div className="projectDetail project-contract-inner">
              <div className="row">
                <div className="col-12">
                  <div className="inner-title mb-1">
                    <div className="back-wrap">
                      {/* {redirectUrl} */}
                      <NavLink
                        to={`${redirectUrl}${contractPreview?.booking_id._id}`}
                        state={{ booking: true }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                            stroke="#2E1885"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {contractPreview?.booking_id.client_id.first_name}{" "}
                        {contractPreview?.booking_id.client_id.last_name}
                      </NavLink>
                    </div>
                  </div>
                  <div className="inner-title">
                    <h3 className="breadcurm-title my-2">
                    Contract Details

                    </h3>
                    <div className="contract_btn">
                      <NavLink
                        to={`${
                          "/bookings/booking-detail/contract-preview/edit-contract/" +
                          id
                        }`}
                        className="btn btn_default"
                      >
                        Edit Contract
                      </NavLink>
                      <button
                        className="btn btn_primary"
                        disabled={isButtonDisabled}
                        onClick={(e) => sendContractToCustomer()}
                      >
                        Send to Customer{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="bookingDetailBody bg-white p-sm-5 p-4">
                    <div className="details">
                      <div
                        className="content"
                        width = {20}
                        dangerouslySetInnerHTML={{
                          __html: contractPreview?.detail,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractPreview;
