import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import ContentLoader from 'react-content-loader'
import Mobile from "../../assets/img/mobile.png";
import Message from "../../assets/img/msg.png";
import ResponseModal from "../../components/ResponseModal";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import moment from "moment";

const InquiryDetail = () => {
  // const converter = Converter();
  const [openModal, setOpenModal] = useState();
  const [customerInquiry, setCustomerInquiry] = useState();
  const [loader, setLoader] = useState(false);
  const [responseList, setList] = useState([])

  const [customerInquiryResponse, setCustomerInquiryResponse] = useState();
  const { id } = useParams();
  const getInquiryDetail = async () => {
    try {
      let res = await UserService.getInquiry(id);
      if (res?.data?.success === true) {
        setCustomerInquiry(res?.data?.result?.data);
        setCustomerInquiryResponse(res?.data?.result?.equiryResponse);
        setLoader(false)
      } else {
        toast.error(res?.data?.msg, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };


  const getTemplateList = async () => {
    try {
      let res = await UserService.getTemplateList();
      if (res?.data?.status === 200) {
        setList(res?.data?.data?.list);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getInquiryDetail();
    getTemplateList()
    setLoader(true)
  }, []);


  const getDetail = (res, key) => {
    const foundObject = res?.find((item) => item?.label === key);
    console.log("foundObject", foundObject);
    if (foundObject) {
      return foundObject?.value;
    } else {
      return "-";
    }
  };
 
  const open = () => {
    setOpenModal(true);
  };

  const close = () => {
    setOpenModal(false);
  };

  function createMarkup(value) {
    return {__html: value};
  }

  return (
    <>
      {openModal && <ResponseModal open={open} close={close} inquiryId={id} type={customerInquiry} />}

      { loader ? <ContentLoader viewBox="0 0 380 70">
    {/* Only SVG shapes */}    
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  </ContentLoader>:
        <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <div className="space-sec mt-0">
              <div className="inner-title">
                <div className="back-wrap">
                  <NavLink to="/inquiries">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
                        stroke="#2E1885"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Inquiries
                  </NavLink>
                </div>
              </div>
              <h3 className="breadcurm-title my-2">
                {getDetail(customerInquiry, "Full Name")}
              </h3>{" "}
              { customerInquiry?.map((item, index) =>
                item.label == "created_at" ? (
                  <>
                    <div className="client-info-main">
                      <div className="client-info">
                        {customerInquiryResponse?.length > 0 ? (
                          <>
                            <p>
                              Last replied{" "}
                              {moment(
                                customerInquiryResponse[
                                  customerInquiryResponse?.length - 1
                                ]?.created_at
                              ).format("MMMM DD, YYYY")}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              Received on{" "}
                              {moment(item?.value).format("MMMM DD, YYYY")}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : item.label == "Service Type"?
                <>
                  {console.log(item.label, 1199)}
                  <div className="client-info-main">
                  <div className="client-info">
                    {responseList?.map((list) => 
                    // {console.log(item.label, "label++++++++++++++")
                    // console.log(list.service_type, "response++++++++++++")}
                      list.service_type == item.value?
                      <p>Auto Responded on {moment(customerInquiryResponse[0].created_at).format("MMMM DD, YYYY")}</p>: ""
                    )
                  }
                  </div>
                </div>
              </> : (
                  ""
                )
              )


              }
              <div className="booking-dtl-cls">
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="client-dtl">
                        <h4>Inquiry Details</h4>
                        <div className="card">
                          {customerInquiry?.map((item, index) =>
                            item.label == "created_at" ? (
                              <>
                                <div className="client-info-main">
                                  <div className="client-info">
                                    <h5>Created At</h5>
                                    <p>
                                      {moment(item?.value).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : item.label == "Is Completed?" ? (
                              <></>
                            ) : (
                              <>
                                <div className="client-info-main">
                                  <div className="client-info">
                                    <h5>{item.label}</h5>
                                    <p dangerouslySetInnerHTML={createMarkup(item.value)}/>
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mark-complate">
                  <button className="btn btn_primary" onClick={() => open()}>
                    Select Response
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> }
    </>
  );
};

export default InquiryDetail;
