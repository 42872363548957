import axios from "axios";
import { SERVER_IP } from "../utils/constants";

const API_URL = SERVER_IP + "/auth/";

const AuthService = {
  login(credentials) {
    return axios.post(`${API_URL}login`, credentials);
  },
  register(credentials) {
    return axios.post(`${API_URL}sign-up`, credentials);
  },

  forgotPassword(credentials) {
    return axios.post(`${API_URL}forget-password`, credentials);
  },
  verifyOtp(value) {
    return axios.post(`${API_URL}verify-otp`, value);
  },
  resetPassword(value, token) {
    return axios.post(`${API_URL}reset-password`, value, {
      headers: { Authorization: "Bearer " + token },
    });
  },
};

export default AuthService;
