import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { ErrorMessage, Field, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../../services/UserService";
import loaderGif from "../../../assets/img/loading.gif";
import ReactQuill from "react-quill";

const AddPackage = ({ open, close }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const userId = localStorage.getItem("userId");
  const initialValues = {
    package_name: "",
    package_price: "0",
    package_type: "",
    details: "",
    total_hours: "",
  };

  const validationSchema = Yup.object().shape({
    package_name: Yup.string().required("Package Name is required"),
    package_price: Yup.number().required("Package Price is required"),
    package_type: Yup.string().required("Service Type is required"),
    details: Yup.string().required("Details is required"),
    total_hours: Yup.number()
      .typeError("Included hours must be a number")
      .min(1, "Included hours must be at least 1")
      .max(100, "Included hours cannot exceed 100")
      .required("Included hours is required"),
  });
  const onSubmit = async (values) => {
    setButtonDisabled(true)

    const data = {
      package_name: values?.package_name,
      package_price: values?.package_price.toString(),
      package_type: values?.package_type,
      details: values?.details,
      total_hours: values?.total_hours.toString(),
    };
    try {
      let res = await UserService.addPackage(data);
      if (res?.data?.status === 201) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        setTimeout(() => {
          close();
        }, 700);
        setButtonDisabled(false)

      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)

      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
      setButtonDisabled(false)

    }
  };

  const getCategoryList = async () => {
    try {
      let res = await UserService.getCategoryList(userId);
      if (res?.data?.success === true) {
        setCategoryList(res?.data?.result);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal CustomModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Add Package</h4>
            <div className="checklist-wrap">
              <div className="add-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                  }) => (
                    <form
                      className="add-team-member-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="package_name">
                              Package Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="package_name"
                              className="form-control"
                              name="package_name"
                              value={values?.package_name}
                              onChange={handleChange}
                            />
                            <span className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="package_name"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="package_price">
                              Package Price
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              id="package_price"
                              type="number"
                              name="package_price"
                              className="form-control"
                              value={values?.package_price}
                              onChange={handleChange}
                            />
                            <span className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="package_price"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Service Type
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              id="team_id"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.package_type}
                              name="package_type"
                            >
                              <option value={""}>Select Service</option>
                              {categoryList?.map((list) => (
                                <option value={list._id}>
                                  {list.category_name}
                                </option>
                              ))}{" "}
                            </select>
                            <span className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="package_type"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Description <span className="text-danger">*</span>
                            </label>
                            <ReactQuill
                              name="details"
                              value={values?.details}
                              onChange={(e) => setFieldValue("details", e)}
                              style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            />
                            <span className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="details"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="total_hours">
                              Included Hours
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              name="total_hours"
                              id="total_hours"
                              className="form-control"
                              value={values?.total_hours}
                              onChange={handleChange}
                            />
                            <span className="error-msg">
                              <ErrorMessage
                                className="error-msg"
                                name="total_hours"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="add-new">
                          <button
                            type="submit"
                            disabled={isButtonDisabled}
                            className="btn btn_primary w-100"
                          >
                            Save Package{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddPackage;
