import "./index.css";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import React, { useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Provider from "./context/Provider";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider>
      <Router>
        <Wrapper>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </Wrapper>
      </Router>
    </Provider>
  </>
);
