import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import UserService from "../services/UserService";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Minus from "../assets/img/minus.svg";
import loaderGif from "../assets/img/loading.gif";


const CheckListModal = ({ open, close, bookingId, clientData }) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [addList, setAddList] = useState([]);
  const [isList, setIsList] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [checkList, setCheckList] = useState([]); // Your initial checkList state

  const onDragEnd = (result) => {
    if (!result.destination) return; // Drop outside the list

    const updatedCheckList = Array.from(checkList);
    const [movedItem] = updatedCheckList.splice(result.source.index, 1);
    console.log()
    updatedCheckList.splice(result.destination.index, 0, movedItem);
    changeCheckList(movedItem._id,result.destination.index)
    setCheckList(updatedCheckList);
  };

  const getCheckList = async () => {
    try {
      let res = await UserService.getCheckList(bookingId);
      if (res?.data?.status === 201) {
        setCheckList(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const changeCheckList = async (iD, sort_key) => {
    const data = {
      sort_key: sort_key,
    };
    try {
      let res = await UserService.changeCheckList(iD, data);
      if (res?.data?.status === 200) {
        setIsList(!isList);
        toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };


  const editCheckList = async (iD, isStatus) => {
    const data = {
      is_status: isStatus,
    };
    try {
      let res = await UserService.editCheckList(iD, data);
      if (res?.data?.status === 200) {
        setIsList(!isList);
        // toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const deleteCheckList = async (iD, isDeleted) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (confirmed) {
      const data = {
        is_delete: isDeleted,
      };
      try {
        let res = await UserService.editCheckList(iD, data);
        if (res?.data?.status === 200) {
          setIsList(!isList);
          toast.success(res?.data?.message, { autoClose: 2000 });
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };

  useEffect(() => {
    getCheckList();
  }, [isList]);

  const handleSubmit = async (e) => {
    setButtonDisabled(true)
    e.preventDefault();
    if (addList === "" || addList === null || addList === undefined) {
      setErrorMsg(true);
    } else {
      let data = {
        booking_id: bookingId,
        title: addList,
        sort_key : checkList.length
      };
      try {
        let res = await UserService.addCheckList(data);
        if (res?.data?.status === 201) {
          setErrorMsg(false);
          setIsList(!isList);
          setButtonDisabled(false)
          toast.success(res?.data?.message, { autoClose: 2000 });
        } else {
          setButtonDisabled(false)

          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {   
              setButtonDisabled(false)
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };

  const handleListChange = (e, itemId) => {
    if (e.target.checked === true) {
      editCheckList(itemId, true);
    } else {
      editCheckList(itemId, false);
    }
  };

  return (
    <>
      <Modal
        show={open}
        hide={close}
        className="modal fade checklist-modal customModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L1 1M13 1L1 13"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="contract_modal">
            <h4>Checklist</h4>
            <div className="checklist-wrap">
              {/* <div className="checklist-tit">
                <h5>{clientData?.first_name + " " + clientData?.last_name}</h5>
                <span>2/7 completed</span>
              </div> */}
              {/* <ul>
                {checkList?.length > 0
                  ? checkList?.map((list, idx) => (
                      <li className="d-flex" key={idx}>
                        <span className="drag-op">
                          <svg
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="16" height="2" rx="1" fill="#828C97" />
                            <rect
                              y="6"
                              width="16"
                              height="2"
                              rx="1"
                              fill="#828C97"
                            />
                          </svg>
                        </span>
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            checked={list?.is_status}
                            onChange={(e) => handleListChange(e, list?._id)}
                          />
                          <span className="checkmark"></span> {list?.name}
                        </label>
                        <img
                          style={{ marginLeft: "auto", cursor: "pointer" }}
                          src={Minus}
                          alt="Remove"
                          onClick={() => deleteCheckList(list?._id, true)}
                        />
                      </li>
                    ))
                  : null}
              </ul> */}
                <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="checklist">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef}>
            {checkList?.map((list, idx) => (
              <Draggable key={list._id} draggableId={list._id} index={idx}>
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="d-flex"
                  >
                    <span className="drag-op">
                      <svg
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="16" height="2" rx="1" fill="#828C97" />
                        <rect y="6" width="16" height="2" rx="1" fill="#828C97" />
                      </svg>
                    </span>
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        checked={list.is_status}
                        onChange={(e) => handleListChange(e, list._id)}
                      />
                      <span className="checkmark"></span> {list.name}
                    </label>
                    <img
                      style={{ marginLeft: "auto", cursor: "pointer" }}
                      src={Minus}
                      alt="Remove"
                      onClick={() => deleteCheckList(list._id, true)}
                    />
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>

              <form className="add-team-member-form" onSubmit={handleSubmit}>
                <div className="add-form">
                  <div className="add-form">
                    <div className="row">
                      <div className="AddnewTeam">
                        <div className="form-group">
                          <label>
                            Add Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setAddList(e.target.value)}
                            value={addList}
                            placeholder="New List Item"
                            autoComplete="off"
                          />
                          {errorMsg && (
                            <p className="error-msg">
                              {"CheckList is required!"}
                            </p>
                          )}
                        </div>
                        </div>
                        <div className="addNewBtn">
                          <button type="submit" 
                            disabled={isButtonDisabled}
                            className="btn btn_primary">
                            Add New{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                          </button>
                        </div>
                    </div>
                  </div>
                  <div className="add-new">
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CheckListModal;
