import React, { useEffect, useState, useRef, useMemo } from "react";
import calenderImg from "../../../assets/img/calendar.svg";
import watchImg from "../../../assets/img/watch.svg";
import mapImg from "../../../assets/img/map.svg";
import moment, { parseTwoDigitYear } from "moment";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import loaderGif from "../../../assets/img/loading.gif";


const EditBookingContract = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const editor = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);


  const [contractPreview, setcontractPreview] = useState();
  useEffect(() => {
    getContractDetails();
  }, []);
  const getContractDetails = async () => {
    try {
      let res = await UserService.getContractDetails(id);
      if (res?.data?.status === 200) {
        setcontractPreview(res?.data?.data);
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };
  const initialValues = {
    message: contractPreview?.detail ? contractPreview?.detail : null,
  };
  const validationSchema = Yup.object({
    message: Yup.string().required("Contract details is required"),
  });
  const onSubmit = async (values) => {
    setButtonDisabled(true)
    let confirm = window.confirm("Are you sure want to save changes ??");
    if (confirm) {
      const data = {
        message: values?.message,
      };
      try {
        let res = await UserService.editgenerateContract(data, id);
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message, { autoClose: 2000 });
          setTimeout(() => {
            navigate("/bookings/booking-detail/contract-preview/" + id);
          }, 1000);
          setButtonDisabled(false)
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
          setButtonDisabled(false)
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
        setButtonDisabled(false)
      }
    }
  };
  return (
    <>
      <div className="panel-wrapper">
        <div className="container">
          <div className="overview-cont">
            <div className="projectDetail project-contract-inner">
              <div className="row">
                <div className="col-12">
                  <h3 className="breadcurm-title my-2">Edit Contract</h3>
                </div>
                <div className="col-12">
                  <div className="bookingDetailBody bg-white p-sm-5 p-4">
                    <div className="details">
                      <div className="contractDec">
                        <p>
                          Please note that the customer will be notified of any
                          changes to the contract and will be requested to
                          accept the agreement again.
                        </p>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="form-group mb-0">
                                <div className="message-label d-flex  justify-content-between">
                                  <label> </label>
                                </div>
                                <div>
                                  <JoditEditor
                                    ref={editor}
                                    value={
                                      values?.message
                                        ? values?.message
                                        : contractPreview?.detail
                                    }
                                    //tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) =>
                                      setFieldValue("message", newContent)
                                    } // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => {
                                      ///setFieldValue("message", newContent);
                                    }}
                                    onFocus={true}
                                  />
                                </div>
                                <p className="error-msg">
                                  <ErrorMessage
                                    className="error-msg"
                                    name="message"
                                  />
                                </p>
                              </div>
                              <div className="edit-contract-action d-flex mt-5 gap-2">
                                <button
                                  type="submit"
                                  disabled={isButtonDisabled}
                                  className="btn btn_primary "
                                >
                                  Save Changes{" "}
                              {isButtonDisabled ? (
                                <>
                                  <img height={20} width={20} src={loaderGif} />
                                </>
                              ) : null}
                                </button>
                                <NavLink
                                  to={`${
                                    "/bookings/booking-detail/contract-preview/" +
                                    id
                                  }`}
                                  className="btn btn_default"
                                >
                                  Cancel
                                </NavLink>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBookingContract;
