import moment from "moment";
import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import ContentLoader from 'react-content-loader';
import { Button, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import dataNotFound from "../../assets/img/jobs_not_found.svg";
const BookingList = ({ data }) => {
  const navigate = useNavigate();
  console.log("data", data);
  const [loader, setLoader] = useState(false);
  const [bookigList, setList] = useState();

  const handleDelete = async (Id, index) => {
    const confirmed = window.confirm("Do you want to delete?");
    if (confirmed) {
      try {
        let res = await UserService.deleteBooking(Id);
        if (res?.data?.status === 200) {
          const updatedList = [...bookigList];
          updatedList.splice(index, 1);
          setList(updatedList);
          toast.success(res?.data?.message, { autoClose: 2000 });
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 2000 });
      }
    }
  };
  useEffect(() => {
    if (data) {
      setList(data);
      setLoader(true)
    } else {
      setList([]);
    }
    setLoader(false)
  }, [data]);
  console.log("bookigList", bookigList);
  return (
    <>
     {loader? <ContentLoader/>:
     <> <ToastContainer />
      {bookigList?.length > 0 ? (
        bookigList?.map((list, index) => (
          <div className="d-flex booking-card-main-cls">
            <div
              className="booking-main-card booking-card position-relative"
              key={index}
            >
              <div className="booking-left">
                <div className="msg-ic">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.875 7.09253C17.6117 7.32957 18.125 8.03267 18.125 8.84018V12.4115C18.125 13.3587 17.4188 14.1619 16.4748 14.2389C16.1923 14.262 15.9091 14.2821 15.625 14.2994V16.875L13.125 14.375C11.9969 14.375 10.8797 14.329 9.77519 14.2388C9.5266 14.2186 9.2945 14.1479 9.08745 14.0375M16.875 7.09253C16.7488 7.05193 16.6161 7.025 16.4783 7.01356C15.3727 6.9218 14.2543 6.875 13.125 6.875C11.9957 6.875 10.8773 6.9218 9.77174 7.01356C8.82916 7.09179 8.125 7.89436 8.125 8.84018V12.4114C8.125 13.1092 8.50822 13.7288 9.08745 14.0375M16.875 7.09253V5.53109C16.875 4.17991 15.9152 3.00887 14.5753 2.83492C12.8732 2.61396 11.1375 2.5 9.37524 2.5C7.61278 2.5 5.87694 2.61399 4.1747 2.83499C2.83477 3.00895 1.875 4.17998 1.875 5.53115V10.7189C1.875 12.07 2.83478 13.2411 4.1747 13.415C4.65551 13.4774 5.13899 13.5313 5.625 13.5765V17.5L9.08745 14.0375"
                      stroke="#FE5F55"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="booking-cont">
                  {list?.clientData?.map((name, idx) => (
                    <h3 key={idx}>
                      {name?.first_name + " " + name?.last_name}
                    </h3>
                  ))}
                  <span>
                    {list?.eventType?.map((name, idx) =>
                      list?.eventData?.length <= 1 ? (
                        <>
                          {name?.category_name}{" "}
                          <span>- {list?.eventData?.length} Event</span>
                        </>
                      ) : (
                        <>
                          {name?.category_name}{" "}
                          <span>- {list?.eventData?.length} Events</span>
                        </>
                      )
                    )}
                  </span>
                  {/* <p>{list?.message}</p> */}
                </div>
              </div>
              <div className="booking-notification">
                <div className="booking-notifi-cont">
                  <h5>{moment(list?.created_at).format("MMMM DD, YYYY")}</h5>
                  <span className="tag">
                    {list.paymentStatus }
                  </span>
                  <NavLink
                    className={"stretched-link"}
                    to={`/bookings/booking-detail/${list?._id}`}
                  ></NavLink>
                </div>
              </div>
            </div>
            <div className="notifi-dot">
              <Dropdown className="bookingDrop">
                <Dropdown.Toggle>
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                        stroke="#0F172A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                        stroke="#0F172A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                        stroke="#0F172A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Button}
                    onClick={() => handleDelete(list?._id, index)}
                  >
                    Delete Booking
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="not-found-img">
            <img src={dataNotFound} />
          </div>
        </>
      )}</>}
    </>
  );
};

export default BookingList;
