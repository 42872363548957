import React from "react";
import OwlCarousel from "react-owl-carousel3";
import img1 from "../assets/img/tour-img1.png";
import img2 from "../assets/img/tour-img2.png";
import img3 from "../assets/img/tour-img3.png";
import img4 from "../assets/img/tour-img4.png";

const options = {
  rtl: "true",
  margin: 0,
  nav: false,
  dots: true,
  loop: false,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 1,
    },
    992: {
      items: 1,
    },
    1366: {
      items: 1,
    },
  },
};

const AuthCarousel = () => {
  return (
    <>
      <div className="login-cont">
        <OwlCarousel className="login-carousel owl-theme" {...options}>
          <div className="item">
            <div className="login-tour-img">
              <img src={img1} alt="Tour" />
            </div>
            <div className="login-inner-cont">
              <h3>Inquiries & bookings right in your inbox.</h3>
              <p>
                Manage your projects more easily with all your project info in
                one place.{" "}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="login-tour-img">
              <img src={img2} alt="Tour" />
            </div>
            <div className="login-inner-cont">
              <h3>Inquiries & bookings right in your inbox.</h3>
              <p>
                Manage your projects more easily with all your project info in
                one place.{" "}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="login-tour-img">
              <img src={img3} alt="Tour" />
            </div>
            <div className="login-inner-cont">
              <h3>Inquiries & bookings right in your inbox.</h3>
              <p>
                Manage your projects more easily with all your project info in
                one place.{" "}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="login-tour-img">
              <img src={img4} alt="Tour" />
            </div>
            <div className="login-inner-cont">
              <h3>Inquiries & bookings right in your inbox.</h3>
              <p>
                Manage your projects more easily with all your project info in
                one place.{" "}
              </p>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default AuthCarousel;
