import React from "react";
import calenderImg from "../../assets/img/calendar.svg";
import moment from "moment";
import jobNotFound from "../../assets/img/jobs_not_found.svg";

import { NavLink } from "react-router-dom";
const Completed = ({ bookingId, data, event }) => {
  console.log("listData", data.length);
  return (
    <>
      <div className="row">
        {data?.length > 0
          ? data?.map((list, idx) => (
              <div className="" key={idx}>
                <div className="pro-card card">
                  <NavLink to={`/projects/project-detail/${list?._id}`}>
                    <div className="pro-tit">
                      <h5>
                        {list?.clientData[0]?.first_name +
                          " " +
                          list?.clientData[0]?.last_name}
                      </h5>
                    </div>

                    {/* <div className="pro-address">
                      <p>{list?.clientData[0]?.address}</p>
                      <p>
                        {list?.clientData[0]?.city},{list?.clientData[0]?.state}
                      </p>
                      <p>{list?.clientData[0]?.country}</p>
                    </div> */}

                    <div className="date-session">
                      <div className="date-wrap">
                        <div className="calendar">
                        <p>Due Date: </p>

                          {/* <img src={calenderImg} alt="Calender Image" /> */}
                        </div>
                        <p>
                          {moment(list?.due_date).format("MMMM DD, YYYY")}
                        </p>
                      </div>
                      <div className="session-wrap">
                        <p>{list?.eventData?.length} Sessions</p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            ))
          :  <>
          <div className="not-found-img">
            <img src={jobNotFound} />
          </div>
        </>}
      </div>
    </>
  );
};

export default Completed;
